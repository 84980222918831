import React, { useEffect, useState } from 'react'
import { useNavigate, NavLink } from 'react-router-dom';
import './SideNavbar.scss';
import { RiLogoutCircleLine, RiMoneyEuroBoxFill } from 'react-icons/ri';
import { AiFillHome, AiFillProfile, AiOutlineUsergroupAdd, AiOutlineSchedule } from 'react-icons/ai';
import { FaUserMd } from 'react-icons/fa'
import { BiCalendar } from 'react-icons/bi'
import { GiMoneyStack } from 'react-icons/gi'
import { getTokenPayload } from '../../../helpers/auth';

const SideNavbar = () => {
  const navigate = useNavigate();
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    const currentUser = getTokenPayload();
    setUserRoles(currentUser.role.split(','))
  }, []);


    let linkStyle = {
    textDecoration: "none",
    fontSize: "16px",
    color: "#ddd",
    lineHeight: "45px",
    display: "block",
    width: '100%',
    padding: "10px",
    paddingLeft: "20px",
    cursor: "pointer"
  }

  let activeStyle = {
    ...linkStyle,
    backgroundColor: "#7c7c7c",
    color: '#fff'
  };

  let logoutStyle = {
    ...linkStyle,
    marginTop: 'auto',
    marginBottom: '70px'
  };

  const logout = () => {
    localStorage.removeItem('token');
    navigate('/signin')
  }

  return (
    <nav style={{width: '231px', minHeight: '100vh', display: 'flex', flexDirection: 'column'}} className='bg-dark position-fixed side-nav'>
      <NavLink to='/' style={({isActive}) => isActive ? activeStyle : linkStyle} >
        <AiFillHome style={{margin: '0 10px 5px 0', fontSize: '18px'}} />
          Почетна
      </NavLink>
      <NavLink to='/appointments' style={({isActive}) => isActive ? activeStyle: linkStyle} >
        <BiCalendar style={{margin: '0 10px 5px 0', fontSize: '18px'}} />
          Термини
      </NavLink>
      <NavLink to='/patients' style={({isActive}) => isActive ? activeStyle: linkStyle} >
        <AiFillProfile style={{margin: '0 10px 5px 0', fontSize: '18px'}} />
          Пациенти
      </NavLink>
      <NavLink to='/schedule' style={({isActive}) => isActive ? activeStyle: linkStyle} >
        <AiOutlineSchedule style={{margin: '0 10px 5px 0', fontSize: '18px'}} />
          Распоред
      </NavLink>
      
      {userRoles.includes('Администратор') || userRoles.includes('Сопственик') ? 
        <NavLink to='/doctors' style={({isActive}) => isActive ? activeStyle: linkStyle} >
          <FaUserMd style={{margin: '0 10px 5px 0', fontSize: '18px'}} />
            Доктори
        </NavLink> : null
      }
      {userRoles.includes('Администратор') || userRoles.includes('Сопственик') ? 
        <NavLink to='/users' style={({isActive}) => isActive ? activeStyle: linkStyle} >
          <AiOutlineUsergroupAdd style={{margin: '0 10px 5px 0', fontSize: '18px'}} />
            Корисници
        </NavLink> : null
      }
      <NavLink to='/prices' style={({isActive}) => isActive ? activeStyle: linkStyle} >
        <RiMoneyEuroBoxFill style={{margin: '0 10px 5px 0', fontSize: '18px'}} />
          Ценовник
      </NavLink>
      
     
      <NavLink to='/finance/payments' style={({isActive}) => isActive ? activeStyle: linkStyle} >
        <GiMoneyStack style={{margin: '0 10px 5px 0', fontSize: '18px'}} />
          Финансии
      </NavLink>
      
      <hr style={{borderTop: '2px solid #fff'}}></hr>
      <p onClick={() => logout()} style={logoutStyle} >
        <RiLogoutCircleLine style={{color: 'red', margin: '0 10px 5px 0', fontSize: '18px'}} />
          Излез
      </p>
    </nav>
  )
}

export default SideNavbar
