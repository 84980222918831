import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';
import { NotificationManager} from 'react-notifications';
import axios from 'axios';
import config from '../../config.json'

export default function PatientProfile() {
    const [key, setKey] = useState('');
    const [patient, setPatient] = useState({});
    const { id } = useParams();
    let location = useLocation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getPatient(id)
       if (location.pathname.includes('exams')) {
            setKey('exams')
        } else if (location.pathname.includes('payments')) {
            setKey('payments')
        } else {
            setKey('profile')
        }
    }, [])

    const getPatient = (id) => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/patients/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            const patient = res.data;
            patient.date_of_birth = res.data.date_of_birth ? new Date(res.data.date_of_birth) : null;
            setPatient({...patient});
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
            navigate('..')
        })
        .finally(() => setLoading(false));
    }

    const handleKeyChange = (key) => {
        
        setKey(key);
        if (key === 'profile') {
            navigate('', {state: {patient}})
        } else {
            navigate(key);
        }
        
    }

    

  return (
    <>
        <div className='d-flex justify-content-between'>
            <h4 className='mb-0'>
                CV{id} - {patient && patient.name_cyrilic ? patient.name_cyrilic : ''}
                {patient.date_of_birth ? 
                    <span>{` - ${patient.date_of_birth.getDate() < 10 ? '0' + patient.date_of_birth.getDate() : patient.date_of_birth.getDate()}/${patient.date_of_birth.getMonth() + 1 < 10 ? '0' + (patient.date_of_birth.getMonth() + 1) : (patient.date_of_birth.getMonth() + 1) }/${patient.date_of_birth.getFullYear()}`}</span> : ''
                }
            </h4>
            {
            location.pathname.split('/').length <= 4 ? 
                <Link to='/patients' type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}}>Назад</Link> :
                <Link to={location.pathname.split('/')[3]} type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}}>Назад</Link>
            }
            
        </div>
        <div className='tabs_link_style' style={{marginTop: '16px'}}>
            <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => handleKeyChange(k)}
            className="mb-0"
            justify
            >
                <Tab eventKey="profile" title="Лични податоци">
                    {key === 'profile' ? <Outlet /> : null}
                </Tab>
                <Tab eventKey="exams" title="Прегледи">
                    {key === 'exams' ? <Outlet /> : null}
                </Tab>
                <Tab eventKey="payments" title="Плаќања">
                    
                        {key === 'payments' ? <Outlet /> : null}
                    
                    
                </Tab>
            </Tabs>
        </div>
    </>
  )
}
