import React, { useState, useEffect } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getTokenPayload } from '../../helpers/auth';


export default function FinancePaymentList() {
    const [key, setKey] = useState('payments');
    const navigate = useNavigate();
    let location = useLocation();
    const [userRoles, setUserRoles] = useState([]);

  

    useEffect(() => {
       if (location.pathname.includes('services')) {
            setKey('services')
        } else if (location.pathname.includes('payments')) {
            setKey('payments')
        } else if (location.pathname.includes('reports')) {
            setKey('reports')
        } else {
            setKey('input-output')
        }

        const currentUser = getTokenPayload();
        setUserRoles(currentUser.role.split(','))
    }, [])

    const handleKeyChange = (key) => {
        setKey(key);
        navigate(key)
    }



return (
<>
    <div className='d-flex justify-content-between'>
        <h4 className='mb-0'>
            Финансиски извештаи
        </h4>
    </div>
    <div className='tabs_link_style' style={{marginTop: '16px'}}>
    {userRoles.includes('Администратор') || userRoles.includes('Сопственик') ?
        <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => handleKeyChange(k)}
        className="mb-0"
        justify
        >
            <Tab eventKey="payments" title="Финансии">
                {key === 'payments' ? <Outlet /> : null}
            </Tab>
            <Tab eventKey="services" title="Статистика на услуги">
                {key === 'services' ? <Outlet /> : null}
            </Tab>
            <Tab eventKey="reports" title="Месечни извештаи">
                {key === 'reports' ? <Outlet /> : null}
            </Tab>
            <Tab eventKey="input-output" title="Влез / Излез">
                {key === 'input-output' ? <Outlet /> : null}
            </Tab>
        </Tabs>
    :
        <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => handleKeyChange(k)}
        className="mb-0"
        justify
        >
            <Tab eventKey="payments" title="Финансии">
                {key === 'payments' ? <Outlet /> : null}
            </Tab>
        </Tabs>
    }
        
    </div>
</>
)
}
