
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager} from 'react-notifications';
import config from '../../../config.json';
import Spinner from '../../SharedComponents/Spinner';
import CustomDatePicker from '../../SharedComponents/CustomDatePicker';
import {AiFillPlusCircle, AiFillCloseCircle} from 'react-icons/ai'


const AddPayment = () => {
  const location = useLocation();
    const navigate = useNavigate();
    const [payment, setPayment] = useState(location.state? location.state.payment : {});
    const [date, setDate] = useState(new Date()); 
    const [loading, setLoading] = useState(false);
    const [doctors, setDoctors] = useState();
    const [prices, setPrices] = useState();
    const [selectedServices, setSelectedServices] = useState(location.state ? location.state.selectedServices : [])
    const {id} = useParams();
    const isRevertPayment = location.state ? true : false;

    useEffect(() => {
        getDoctors();
        if (!isRevertPayment) {
          getInvoiceNumber();
        }
    }, [])

    const getDoctors = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/doctors`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            setDoctors(res.data); 
            getPrices();
        })
        .catch(err => {
          if (err.response.status === 401) {
              navigate('/signin')
          }
          NotificationManager.error(err.response.data)
      })
        .finally(() => setLoading(false));

    }

    const getPrices = () => {
      setLoading(true);
      axios({
          method: 'get',
          url: `${config.SERVER_URL}/prices`,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('token')
          }
      })
      .then(res => {   
          if(typeof res.data === 'string') {
              NotificationManager.info(res.data)
          } else {
              setPrices(res.data); 
          }
      })
      .catch(err => {
        if (err.response.status === 401) {
            navigate('/signin')
        }
        NotificationManager.error(err.response.data)
    })
      .finally(() => setLoading(false));

  }

  const getInvoiceNumber = () => {
    setLoading(true);
    axios({
        method: 'get',
        url: `${config.SERVER_URL}/invoice-number`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
        }
    })
    .then(res => {   
      setPayment({
        invoice_number: res.data,
        patient_id: id,
        doctor_id: 0,
        date_of_service: new Date(),
        payment_cash: 0,
        payment_card: 0,
        bank_transfer: 0,
        payment_rest: 0,
        payment_amount: 0,
        is_secondary: ''
      })
      setSelectedServices([{
        price_id: 0,
        discount: '',
        volume: '',
      }])
    })
    .catch(err => {
      if (err.response.status === 401) {
          navigate('/signin')
      }
      NotificationManager.error(err.response.data)
  })
    .finally(() => setLoading(false));

}

    const createPayment = (e) => {
        e.preventDefault();
        if (payment.doctor_id == '0') {
            NotificationManager.error('Изберете доктор')
            return;
        }
        if (payment.is_secondary === '') {
          NotificationManager.error('Изберете систем')
          return;
      }
        if (selectedServices[0].price_id <= 0) {
          NotificationManager.error('Изберете услуга')
          return
        }
        setLoading(true)
        axios({
            method: 'post',
            url: `${config.SERVER_URL}/patients/${id}/payments/add`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                ...payment,
                services: selectedServices.map(service => {
                  delete service.price;
                  return service;
                })
            }
        }).then(res => {
            NotificationManager.success('Плаќањето е успешно')
            navigate(`/patients/${id}/payments/${res.data}`);
        })
        .catch(err => {
          if (err.response.status === 401) {
              navigate('/signin')
          }
          NotificationManager.error(err.response.data)
      })
        .finally(() => setLoading(false));
    }

    const handleInputChange = (e) => {
      if (isRevertPayment) {
        return;
      }
        let { name, value} = e.target;
        if (name === 'is_secondary' && value !== '') {
          value = parseInt(value);
        }
        setPayment({
          ...payment,
          [name]: value,
        });
        
    }

    const handleDiscountChange = (index, e) => {
      if (selectedServices[index].price_id === 0 || isRevertPayment) {
        return;
      }
      const {value} = e.target;
      const newSelectedServices = [...selectedServices];
      newSelectedServices[index] = {
        ...newSelectedServices[index],
        discount: parseInt(value, 10),
        amount: (newSelectedServices[index].price.value - (newSelectedServices[index].price.value * Number(value)/100)) * newSelectedServices[index].volume
      }
      setPaymentAmount(newSelectedServices)
      setSelectedServices([...newSelectedServices])
    }

    const handleServiceChange = (index, e) => {
      if (isRevertPayment) {
        return;
      }
      const price = prices.find(p => p.id == e.target.value)
      const newSelectedServices = [...selectedServices];
      newSelectedServices[index] = {
        ...newSelectedServices[index],
        price_id: price.id,
        price: price,
        volume: 1,
        discount: 0,
        amount: price.value
      }
      setPaymentAmount(newSelectedServices)
      setSelectedServices([...newSelectedServices])
    }

    const handleVolumeChange = (index, e) => {
      if (isRevertPayment) {
        return;
      }
      if (selectedServices[index].price_id === 0) {
        return;
      }
      let {value} = e.target;
      if (Number(value) < 1) {
        value = 1;
      }
      const newSelectedServices = [...selectedServices];
      newSelectedServices[index] = {
        ...newSelectedServices[index],
        volume: Number(value),
        amount: (newSelectedServices[index].price.value - (newSelectedServices[index].price.value * newSelectedServices[index].discount/100)) * Number(value)
      }
      setPaymentAmount(newSelectedServices)
      setSelectedServices([...newSelectedServices])
    }

    const setPaymentAmount = (services) => {
      const amount = services.reduce((total, current) => total + current.amount, 0);
      setPayment({
        ...payment,
        payment_amount: amount,
        payment_cash: 0,
        payment_card: 0,
        bank_transfer: 0,
        payment_rest: amount
      });
    }

    const cancelRegister = (e) => {
      e.preventDefault();
        navigate('../payments');
    }

    const setPaymentDate = (e) => {
      if (isRevertPayment) {
        return;
      }
        setPayment({
            ...payment,
            'date_of_service': e,
          });
          setDate(e);
    }

    const changePaidValue = (e) => {
      if (isRevertPayment) {
        return;
      }
      const { name, value} = e.target;
      let cash = Number(payment.payment_cash)
      let card = Number(payment.payment_card)
      let bank = Number(payment.bank_transfer)
      if (name === 'payment_cash') {
        cash = Number(value);
        setPayment({
          ...payment,
          'payment_cash': cash,
          'payment_rest': payment.payment_amount - (cash + card + bank)
        });
      }
      if (name === 'payment_card') {
        card = Number(value);
        setPayment({
          ...payment,
          'payment_card': card,
          'payment_rest': payment.payment_amount - (cash + card + bank)
        });
      }
      if (name === 'bank_transfer') {
        bank = Number(value);
        setPayment({
          ...payment,
          'bank_transfer': bank,
          'payment_rest': payment.payment_amount - (cash + card + bank)
        });
      }
      

    }

    const handlePlus = () => {
      if (isEmptyService() || isRevertPayment) {
        return;
      }
      const newSelectedServices = [...selectedServices]
      newSelectedServices.push({
          price_id: 0,
          discount: '',
          volume: '',
      })
      setSelectedServices([...newSelectedServices])
  }

  const handleMinus = (index) => {
      if (selectedServices[index]) {
          const newSelectedServices = [...selectedServices]
          newSelectedServices.splice(index, 1);
          setSelectedServices([...newSelectedServices])
          setPaymentAmount(newSelectedServices)
      }
      
  }

  const isEmptyService = () => {
    return selectedServices.findIndex(s => s.price_id === 0) !== -1;
  }

    return (
        <>
        <div className='background-wrapper_patient' style={{width: 'auto', height: 'auto',  backgroundColor: 'inherit'}}>
            {loading ? <Spinner /> : null}
                <form style={{width: '100%'}} onSubmit={createPayment}>
                    <div className='background-form-wrapper_patient justify-content-between'>
                        <h1 className='mt-3'>Креирај документ за наплата</h1>
                        <div className='input-wrapper me-0' style={{width: '15%'}}>
                            <label htmlFor="invoice_number">Број на фактура</label>
                            <input type='number' name='invoice_number' value={payment.invoice_number} onChange={handleInputChange} style={{width: '100%'}}/>
                        </div>
                        <div className='input-wrapper me-0' style={{width: '15%'}}>
                            <label htmlFor="date_of_service">Датум на услуга</label>
                            <CustomDatePicker name='date_of_service' startDate={date} setStartDate={setPaymentDate}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper me-0' style={{width: '25%'}}>
                            <label htmlFor="doctor_id">Реализација на услуга</label>
                            <select name="doctor_id" style={{width: '100%'}} onChange={handleInputChange} disabled={isRevertPayment}>
                                <option value='0'></option>
                                {doctors && doctors.map(doctor => <option key={doctor.id} value={doctor.id}>{doctor.name_cyrilic}</option>)}
                            </select>
                        </div>

                        <div className='w-100 d-flex justify-content-between mt-5'>
                              <div style={{width: '50%', marginTop: '10px', marginRight: '0'}}>
                                  Услуга
                              </div>
                              <div style={{width: '6%', marginTop: '10px', marginRight: '0'}}>
                                Цена
                              </div>
                              <div style={{width: '6%', marginTop: '10px', marginRight: '0'}}>
                                Количина
                              </div>
                              <div style={{width: '6%', marginTop: '10px', marginRight: '0'}}>
                                  Попуст %
                              </div>
                              <div style={{width: '6%', marginTop: '10px', marginRight: '0'}}>
                                 Износ
                              </div>
                              <div style={{width: '10%'}}></div>
                            </div>


                        {selectedServices.map((service, index) => {
                          return (
                            <div key={`price_${index}`} className='w-100 d-flex justify-content-between'>
                              <div className='input-wrapper' style={{width: '50%', marginTop: '5px', marginRight: '0'}}>
                                  <select disabled={isRevertPayment} name={`service_id_${index}`} value={service.price_id} onChange={(e) => handleServiceChange(index, e)} style={{width: '100%'}}>
                                    <option value='0'></option>
                                      {prices && prices.map(price => <option key={price.id} value={price.id}>{price.code} - {price.service}</option>)}
                                  </select>
                              </div>
                              <div className='input-wrapper' style={{width: '6%', marginTop: '5px', marginRight: '0'}}>
                                <p className='view-form-field m-0' style={{width: '100%'}}>{service.price ? service.price.value + ' МКД' : ''}</p>
                                {/* <span className='error text-danger'>{cyrNameError}</span> */}
                              </div>
                              <div className='input-wrapper' style={{width: '6%', marginTop: '5px', marginRight: '0'}}>
                                <input type='number' className='text-center m-0' name={`volume_${index}`} value={service.volume} onChange={(e) => handleVolumeChange(index, e)} style={{width: '100%'}} />
                                {/* <span className='error text-danger'>{cyrNameError}</span> */}
                              </div>
                              <div className='input-wrapper' style={{width: '6%', marginTop: '5px', marginRight: '0'}}>
                                <input type='number' className='text-center m-0' disabled={isRevertPayment} name={`discount_${index}`} value={service.discount} onChange={(e) => handleDiscountChange(index, e)} style={{width: '100%', fontWeight: 'bold', textAlign: 'center'}} />
                                {/* <span className='error text-danger'>{cyrNameError}</span> */}
                              </div>
                              
                              <div className='input-wrapper' style={{width: '6%', marginTop: '5px', marginRight: '0'}}>
                                  <p className='view-form-field m-0' style={{width: '100%'}}>{service.amount || service.amount === 0 ? service.amount + ' МКД' : ''}</p>
                                  {/* <span className='error text-danger'>{cyrNameError}</span> */}
                              </div>
                              <div className='plus-minus-btn text-center d-flex align-items-center' style={{width: '10%'}}>
                                  <button type='button' onClick={handlePlus} className='btn plus-btn'>
                                      <AiFillPlusCircle />
                                  </button>
                                  <button type='button' disabled={selectedServices.length < 2} onClick={() => handleMinus(index)} className='btn minus-btn'>
                                      <AiFillCloseCircle />
                                  </button>
                              </div>
                            </div>
                          )
                        })}


                        <div className='input-wrapper' style={{width: '100%', marginTop: '30px', marginRight: '0'}}>
                            <label htmlFor="payment_cash">Вкупно за наплата</label>
                            <p className='view-form-field m-0' style={{width: '10%'}}>{payment.payment_amount || payment.payment_amount === 0 ? payment.payment_amount + ' МКД' : ''}</p>
                        </div>

                        <div className='input-wrapper me-0' style={{width: '100%'}}>
                            <label htmlFor="is_secondary">Избери систем:</label>
                            <select name="is_secondary" style={{width: '10%'}} onChange={handleInputChange} disabled={isRevertPayment}>
                                <option value=''></option>
                                <option value='0'>Ситем 1</option>
                                <option value='1'>Систем 2</option>
                            </select>
                        </div>

                        {payment.is_secondary === 0 ? 
                        
                          <>
                            <div className='input-wrapper' style={{width: '12%', marginTop: '30px', marginRight: '10%'}}>
                                <label htmlFor="payment_cash">Наплатен износ во готово</label>
                                <input type='text' name='payment_cash' value={payment.payment_cash} onChange={changePaidValue} style={{width: '100%'}}/>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className='input-wrapper' style={{width: '12%', marginTop: '30px', marginRight: '10%'}}>
                                <label htmlFor="payment_card">Наплатен износ со картичка</label>
                                <input type='text' name='payment_card' value={payment.payment_card} onChange={changePaidValue} style={{width: '100%'}}/>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className='input-wrapper' style={{width: '12%', marginTop: '30px', marginRight: '40%'}}>
                                <label htmlFor="bank_transfer">Наплатен износ преку банка</label>
                                <input type='text' name='bank_transfer' value={payment.bank_transfer} onChange={changePaidValue} style={{width: '100%'}}/>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className='input-wrapper' style={{width: '100%', marginTop: '30px', marginRight: '10%'}}>
                                <label htmlFor="payment_rest">Остаток</label>
                                <input type='text' name='payment_rest' value={payment.payment_rest} onChange={handleInputChange} style={{width: '15%'}} disabled/>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                          </>
                          : payment.is_secondary === 1 ?
                          <>
                            <div className='input-wrapper' style={{width: '12%', marginTop: '30px', marginRight: '10%'}}>
                                <label htmlFor="payment_cash">Наплатен износ во готово</label>
                                <input type='text' name='payment_cash' value={payment.payment_cash} onChange={changePaidValue} style={{width: '100%'}}/>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className='input-wrapper' style={{width: '100%', marginTop: '30px', marginRight: '10%'}}>
                                <label htmlFor="payment_rest">Остаток</label>
                                <input type='text' name='payment_rest' value={payment.payment_rest} onChange={handleInputChange} style={{width: '15%'}} disabled/>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                          </>
                          : null
                      }

                        
                                              
                        
                        <div className='button-wrapper' style={{width: '100%'}}>
                        <div className='d-flex justify-content-between' style={{width: '270px'}}>
                          <button className='submit' type='submit p-0'>Зачувај</button>
                          <button className='cancel' type='button p-0' onClick={cancelRegister}>Откажи</button>
                          </div>
                    </div>
                    </div>
                </form>
           
        </div>
        </>
    );
}

export default AddPayment;