import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../SharedComponents/Spinner';
import Card from '../SharedComponents/Card';
import CustomDatePicker from '../SharedComponents/CustomDatePicker';
import WorkingHoursTable from './WorkingHoursTable';
import DoctorScheduleModal from './DoctorScheduleModal';
import AppointmentsModal from './AppointmentsModal';
import axios from 'axios';
import { NotificationManager} from 'react-notifications';
import config from '../../config.json';
import HolterAppointmentsTable from './HolterAppointmentsTable';

export default function AppointmentsLayout() {
    const {date} = useParams();
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(date ? new Date(date) : new Date());
    const [loading, setLoading] = useState(false)
    const [workingHours, setWorkingHours] = useState([]);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState({})
    const [selectedAppointments, setSelectedAppointments] = useState([])
    const [show, setShow] = useState(false);
    const [selectedWH, setSelectedWH] = useState();
    const [roomNumber, setRoomNumber] = useState()
    const days = ['Недела', 'Понеделник', 'Вторник', 'Среда', 'Четврток', 'Петок', 'Сабота'];

    useEffect(() => {
        const appointmentsDate = date ? new Date(date) : new Date()
        // getAppointments(appointmentsDate);
        getWorkingHours(appointmentsDate)
    }, [])

    const getWorkingHours = (date) => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/working-hours/${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
        })
        .then(res => {   
            setWorkingHours(res.data);
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
            
        })
        .finally(() => setLoading(false));
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
        navigateToDate(date);
        getWorkingHours(date);
    }
    
    const navigateToDate = (date) => {
        navigate(`../${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
    }

    const goToNextDate = (e) => {
        e.preventDefault();
        const date = new Date(selectedDate);
        date.setDate(date.getDate() + 1);
        setSelectedDate(date);
        getWorkingHours(date);
        navigate(`../${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
    }

    const goToPrevDate = (e) => {
        e.preventDefault();
        const date = new Date(selectedDate);
        date.setDate(date.getDate() - 1);
        setSelectedDate(date);
        getWorkingHours(date);
        navigate(`../${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
    }

    const handleCloseScheduleModal = () => {
        setShowScheduleModal(false)
        setSelectedWH({})
      };
    
    const handleShowScheduleModal = (room_number, workTime) => {
        setRoomNumber(room_number);
        if (workTime) {
            setSelectedWH(workTime)
        }
        setShowScheduleModal(true);
    };

    const handleClose = () => {
        setShow(false)
    };
    const handleShow = (id, appointment, appointments, workTime) => {
        // console.log(id)
        if (!id) {
            console.log('id')
            appointment.room_number = workTime.room_number;
            appointment.status = '#FEDC80'
        } 
        appointment.start_date_time = new Date(appointment.start_date_time);
        setSelectedAppointment(appointment);
        setSelectedAppointments(appointments)
        setSelectedWH(workTime)
        setShow(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // if (!isFormValid()) {
        //     return;
        // }
        setLoading(true)
        const objToSave = {...selectedAppointment};
        objToSave.patient_id = selectedAppointment.patient_id;
       
        axios({
            method: 'post',
            url: `${config.SERVER_URL}/appointments/add`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                ...objToSave
            }
        }).then(res => {
            NotificationManager.success('Успешно зачуван термин')
            getWorkingHours(selectedDate)
            // resetForm();
            // getDoctors();
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => {
            setSelectedWH({})
            setSelectedAppointment({});
            setLoading(false)
        });
    }

    const handleInputChange = (e) => {
        console.log(e.target)
        if (!e.target) {
            setSelectedAppointment({
                ...selectedAppointment,
                start_date_time: e
            })
            return;
        }
        const { name, value} = e.target;
        switch (name) {
            case 'patient_name':
                setSelectedAppointment({
                    ...selectedAppointment,
                    patient_name: value
                    
                }) 
                break;
            case 'patient_phone':
                setSelectedAppointment({
                    ...selectedAppointment,
                    patient_phone: value
                })
                break;
            case 'time':
                const dateTime = new Date(selectedAppointment.start_date_time)
                dateTime.setHours(Number(value.split(':')[0]))
                dateTime.setMinutes(Number(value.split(':')[1]))
                setSelectedAppointment({
                    ...selectedAppointment,
                    start_date_time: dateTime
                })
                break;
            case 'duration':
                setSelectedAppointment({
                    ...selectedAppointment,
                    duration: Number(value)
                })
                break;
            case 'status':
                setSelectedAppointment({
                    ...selectedAppointment,
                    status: value
                })
                break;
            default:
                setSelectedAppointment({
                    ...selectedAppointment,
                    [name]: value
                })
                break;
        }
    }

    const getAvailableTimes = (duration) => {
        const availableTimes = [];
        
        
        const appointmentsCopy = selectedAppointments;
        
        const modifiedAppointments = appointmentsCopy.map(a => {
            return {...a}
        });
    
        if (selectedAppointment.id) {
            const selectedIndex = appointmentsCopy.findIndex(a => a.id === selectedAppointment.id);
            if (!appointmentsCopy[selectedIndex === 0 ?  0 : (selectedIndex - 1)].id) {
                modifiedAppointments[selectedIndex].duration += modifiedAppointments[selectedIndex - 1].duration;
                modifiedAppointments[selectedIndex].start_date_time = new Date(modifiedAppointments[selectedIndex - 1].start_date_time)
                modifiedAppointments[selectedIndex - 1].id = -1;
            }
            if (!appointmentsCopy[selectedIndex === appointmentsCopy.length - 1 ? selectedIndex : selectedIndex + 1].id) {
                modifiedAppointments[selectedIndex].duration += appointmentsCopy[selectedIndex + 1].duration;
                modifiedAppointments[selectedIndex + 1].id = -1;
            }
            
        }
        
        
        const emptyAppointments = modifiedAppointments.filter(a => !a.id || a.id === selectedAppointment.id)
        
        emptyAppointments.map(a => {
            if (a.duration >= duration) {
                const endEmptyTime = new Date(a.start_date_time)
                endEmptyTime.setMinutes(endEmptyTime.getMinutes() + a.duration);
                const startEmptyTime = new Date(a.start_date_time);
                let endAppointmentTime = new Date(startEmptyTime);
                do {
                    availableTimes.push(new Date(startEmptyTime));
                    startEmptyTime.setMinutes(startEmptyTime.getMinutes() + 5)
                    endAppointmentTime = new Date(startEmptyTime);
                    endAppointmentTime.setMinutes(endAppointmentTime.getMinutes() + duration);
                } while(endAppointmentTime.getTime() <= endEmptyTime.getTime())
                
            }
        })
        return availableTimes;
    }
    
    const getAllTimes = (workTime) => {
        const startTime = new Date(workTime.start_date_time);
        
        const endTime = new Date(workTime.end_date_time);
        
        const allTimes = [];
        while (startTime.getTime() < endTime.getTime()) {
            allTimes.push(new Date(startTime));
            startTime.setMinutes(startTime.getMinutes() + 5);
        }
        return allTimes;
    }

    const getBusyDoctorTimes = (roomNumber) => {
        
        const ordinationScheduleData = workingHours.filter(wh => wh.room_number === roomNumber);

        const startTime = new Date();
        startTime.setHours(8);
        startTime.setMinutes(0);
        startTime.setSeconds(0);
        startTime.setMilliseconds(0);

        const endTime = new Date();
        endTime.setHours(21);
        endTime.setMinutes(0);
        endTime.setSeconds(0);
        endTime.setMilliseconds(0);

        const busyTimes = [];

        for (const item of ordinationScheduleData) {
            const itemStartTime = new Date(item.start_date_time)
            const itemEndTime = new Date(item.end_date_time);

            while(itemEndTime.getTime() > itemStartTime.getTime()) {
                busyTimes.push(new Date(itemStartTime));
                itemStartTime.setMinutes(itemStartTime.getMinutes() + 30);
            }
        }
        return busyTimes;
    }

    const handleRemoveAppointment = () => {
        setLoading(true);
        axios({
            method: 'delete',
            url: `${config.SERVER_URL}/appointments/${selectedAppointment.id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
        })
        .then(res => {   
            NotificationManager.success('Успешно избришан термин')
            getWorkingHours(selectedDate)
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
            
        })
        .finally(() => {
            setShow(false)
            setSelectedWH({})
            setSelectedAppointment({});
            setLoading(false)
        });
    }
    

  return (
    <>
        {loading ? <Spinner /> : null}
        <div className='d-flex justify-content-between'>
            <button type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}} onClick={goToPrevDate}>{'<<< Претходен ден'}</button>
            <div className="input-group d-flex" style={{width: 'auto', flexWrap: 'nowrap', gap: '10px'}}>
                <CustomDatePicker type="text" startDate={selectedDate} setStartDate={handleDateChange}/>
                <b style={{color: '#39828f'}}>{days[selectedDate.getDay()]}</b>
            </div>
            
            <button type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}} onClick={goToNextDate}>{'Нареден ден >>>'}</button>
        </div>
        
        {workingHours ? (
        <>
        <div className='d-flex justify-content-between'>
            
            <div style={{width: '33%'}}>
                <Card className='pt-0 pb-0' >
                    <WorkingHoursTable workingHours={workingHours.filter(wh => wh.room_number === 1)} 
                    handleShowScheduleModal={handleShowScheduleModal} 
                    room={1} 
                    handleShow={handleShow}  
                    header={'Прегледи'}/>
                </Card>
            </div>
            <div style={{width: '33%'}}>
                <Card className='pt-0 pb-0' >
                    <WorkingHoursTable workingHours={workingHours.filter(wh => wh.room_number === 2)} 
                    handleShowScheduleModal={handleShowScheduleModal} 
                    room={2} handleShow={handleShow} 
                    header={'Дијагностички услуги'}/>
                </Card>
            </div>
            <div style={{width: '33%'}}>
                <Card className='pt-0 pb-0' >
                    <HolterAppointmentsTable  header={'Холтери'} date={selectedDate} />
                </Card>
            </div>
            
            
        </div>

        {showScheduleModal ? 
        <DoctorScheduleModal 
            show={showScheduleModal} 
            handleClose={handleCloseScheduleModal} 
            room_number={roomNumber}
            date={selectedDate}
            getWorkingHours={getWorkingHours}
            busyStartTimes={getBusyDoctorTimes(roomNumber)}
            busyArray={workingHours.filter(wh => wh.room_number === roomNumber)}
            selectedWH={selectedWH}
            workingHoursByDoctor={selectedWH ? workingHours.filter(wh => wh.doctor_id === selectedWH.doctor_id) : []}
        /> : null}

        <AppointmentsModal 
            show={show} 
            room_number={roomNumber}
            handleClose={handleClose} 
            handleSubmit={handleSubmit} 
            appointment={selectedAppointment} 
            setSelectedAppointment={setSelectedAppointment}
            handleInputChange={handleInputChange} 
            handleRemoveAppointment={handleRemoveAppointment}
            allTimes={selectedWH ? getAllTimes(selectedWH) : []}
            availableTimes={getAvailableTimes(selectedAppointment.duration)}
            />

       
        </>
        
        ) : null}

        </>
  )
}
