import React, { useEffect, useState } from 'react'
import Spinner from '../SharedComponents/Spinner';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'
import config from '../../config.json';
import { NotificationManager} from 'react-notifications';
import CustomMonthPicker from '../SharedComponents/CustomMonthPicker';
import {FaEdit, FaSave, FaRegWindowClose} from 'react-icons/fa'
import InOutModal from './InOutModal';

export default function InOutReportsTab() {
    const {fromDate, toDate} = useParams();
    const [loading, setLoading] = useState(false)
    const [payments, setPayments] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState({})
    const [dateToEdit, setDateToEdit] = useState('');
    const [selectedDoctorId, setSelectedDoctorId] = useState(0);
    const [startDate, setStartDate] = useState(fromDate ? new Date(fromDate) : new Date());
    const [endDate, setEndDate] = useState(toDate ? new Date(toDate) : new Date());

    const [show, setShow] = useState(false);
    const [selectedInOutItem, setSelectedInOutItem] = useState({})
    const [inOutItems, setInOutItems] = useState([])
    const [totalAmount, setTotalAmount] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        
      getIOItems(startDate);
        
    }, [])

    const getIOItems = (startDate) => {
      setLoading(true);
      const start = new Date(startDate);
      const date = `${start.getFullYear()}-${start.getMonth()+1}-1`;
      axios({
          method: 'get',
          url: `${config.SERVER_URL}/finance/input-output/${date}`,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('token')
          },
      })
      .then(res => {   
          if (typeof(res.data) === "string") {
              NotificationManager.info(res.data)
          } else {
              setInOutItems(res.data)
          }
          
      })
      .catch(err => {
          if (err.response.status === 401) {
              navigate('/signin')
          }
          NotificationManager.error(err.response.data)
      })
      .finally(() => setLoading(false));
  }

    

    const handleStartDateChange = (date) => {
        setStartDate(date);
        getIOItems(date);
    }
    // //////////////////////////////////////////////////////////////////////////////////
    const handleSubmit = (e) => {
        e.preventDefault();
        
        setLoading(true)
        let url = `${config.SERVER_URL}/finance/input-output/add`;
        let method = 'post';
        
        if (selectedInOutItem.id) {
            url = `${config.SERVER_URL}/finance/input-output/${selectedInOutItem.id}`;
            method = 'put';
        }
        const objToSave = {...selectedInOutItem};
       
        axios({
            method: method,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                ...objToSave
            }
        }).then(res => {
            NotificationManager.success('Успешно зачуван термин')
            getIOItems(startDate)
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => {
            setSelectedInOutItem({});
            setLoading(false)
        });
    }

    const handleInputChange = (e) => {
        if (!e.target) {
            setSelectedInOutItem({
                ...selectedInOutItem,
                date: e
            })
            return;
        }
        const { name, value} = e.target;
        if (name === 'amount') {
            setSelectedInOutItem({
                ...selectedInOutItem,
                amount: Number(value)
            })
        } else {
            setSelectedInOutItem({
                ...selectedInOutItem,
                [name]: value
            })
        }
        
        
    }

    const handleRemove = () => {
        setLoading(true);
        axios({
            method: 'delete',
            url: `${config.SERVER_URL}/finance/input-output/${selectedInOutItem.id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
        })
        .then(res => {   
            NotificationManager.success('Успешно избришан термин')
            getIOItems(startDate)
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
            
        })
        .finally(() => {
            setShow(false)
            setLoading(false)
        });
    }

    const handleClose = () => {
        setShow(false)
    };

    const handleShow = (item) => {
       
        if (item.id) {
            item.date = new Date(item.date);
            setSelectedInOutItem(item)
        } else {
            setSelectedInOutItem({
                date: new Date(),
                type: true
            })
        }
        
        setShow(true);
    };

    const currencyFormat = (num) => {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' МКД'
     }

    const getTotal = (type, amount, index) => {
        const inOutItemsCopy = [...inOutItems]
        const newIOarray = inOutItemsCopy.splice(0, index)
        const prevAmount = newIOarray.reduce((acc, curr) => {
            const item = curr.amount !== null ? curr.amount : 0
            return curr.type ? acc + item : acc - item;;
        }, 0)
        return type ? prevAmount + amount : prevAmount - amount;
    }

    return (
        <>
        
        <div className='background-wrapper_patient' style={{width: 'auto', height: 'auto',  backgroundColor: '#fff'}}>
            {loading ? <Spinner /> : null}
            {inOutItems && 
            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: '50%', flexWrap: 'wrap'}}>
                    <p style={{width: '100%', margin: '0px 10px'}}>Извештај за месец:</p>
                    
                </div>
                <div className='d-flex justify-content-between ps-2 pe-2'>
                    <div className="input-group d-flex" style={{width: 'auto', minWidth: '250px' }}>
                        <CustomMonthPicker type="text" startDate={startDate} setStartDate={handleStartDateChange}/>
                    </div>
                    
                    <button onClick={() => handleShow({})} type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}} >{'Додади Влез/Излез'}</button>
                </div>
                
                <table className="table" style={{width: '100%'}}>
                    <thead>
                        <tr>
                            <th scope="col">Датум</th>
                            <th scope="col">Опис</th>
                            <th scope="col" className='text-end'>Влез</th>
                            <th scope="col" className='text-end'>Излез</th>
                            <th scope="col" className='text-end' style={{paddingRight: '25px'}}>Вкупно</th>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {inOutItems.map((item, index) => {
                           
                           const date = new Date(item.date);
                            return (
                                <tr key={item.date + "_" + index}>
                                    <td>{`${date.getDate() < 10 ? '0' + (date.getDate()) : (date.getDate())}/${date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1) }/${date.getFullYear()}`}</td>
                                    <td style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden'}}>{item.description}</td>
                                    <td className='text-end'>{item.type ? currencyFormat(item.amount) : null}</td>
                                    <td className='text-end'>{!item.type ? currencyFormat(item.amount) : null}</td>
                                    <td className='text-end' style={{paddingRight: '25px'}}>
                                        <b>{currencyFormat(getTotal(item.type, item.amount, index))}</b>
                                    </td>
                                    <td className='text-end' style={{width: '100px', paddingTop: '0', fontSize: '21px', cursor: 'pointer'}}>
                                        <FaEdit style={{color: '#39828f'}} onClick={() => handleShow(item)}/>
                                        
                                  </td>
                                </tr>
                            )
                        })}
                        <tr style={{borderTop: '2px solid black'}}>
                            <td><b>Вкупно</b></td>
                            <td className='text-end' style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden',  fontWeight: 'bold'}}></td>
                            <td className='text-end'>
                            {currencyFormat(inOutItems.reduce((acc, curr) => {
                                const item = curr.type && curr.amount !== null ? curr.amount : 0
                                return acc + item;
                            }, 0))}
                            </td>
                            <td className='text-end'>
                            {currencyFormat(inOutItems.reduce((acc, curr) => {
                                const item = !curr.type && curr.amount !== null ? curr.amount : 0
                                return acc + item;
                            }, 0))}
                            </td>
                            <td className='text-end' style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden', paddingRight: '25px',  fontWeight: 'bold'}}>
                            
                            </td>
                            <td></td>
                        </tr>
                        
                    </tbody>
                </table>
                
                

                
                
            </div>}
                
        </div>

        <InOutModal 
            show={show} 
            handleClose={handleClose} 
            handleSubmit={handleSubmit} 
            inOutItem={selectedInOutItem} 
            setInOutItem={setSelectedInOutItem}
            handleInputChange={handleInputChange} 
            handleRemove={handleRemove}
            />
        
        
        </>
    );
}
