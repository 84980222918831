import React, { useEffect, useState } from 'react'
import Card from '../SharedComponents/Card';
import { Link, useNavigate } from 'react-router-dom';
import {FaUserEdit} from 'react-icons/fa'
import axios from 'axios';
import config from '../../config.json';
import { NotificationManager} from 'react-notifications';
import Spinner from "../../components/SharedComponents/Spinner";

export default function UserList() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/users`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            setUsers(res.data); 
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));

    }

    return (
        <>
        {loading ? <Spinner /> : null}
        <Link to='add' type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}}>Додади корисник</Link>
        <Card>
            <table className="table">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Име и презиме</th>
                    <th scope="col">Корисничко име</th>
                    <th scope="col">Улога</th>
                    <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => {
                        return (
                            <tr key={user.id}>
                                <th>{index + 1}</th>
                                <td>{user.name}</td>
                                <td>{user.username}</td>
                                <td>{user.roles}</td>
                                <td style={{width: '50px', paddingTop: '0' }}>
                                    <Link to={`${user.id}`} type="button" className="btn">
                                    <FaUserEdit style={{color: '#39828f'}} />
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                    
                </tbody>
            </table>
        </Card>
        </>
    )
}
