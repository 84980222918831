import React from 'react'
import { registerLocale } from  "react-datepicker";
import mk from 'date-fns/locale/mk';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

export default function CustomMonthPicker({startDate, setStartDate, disabled = false}) {
  registerLocale('mk', mk);

  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  return (
    <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        locale="mk" 
        renderMonthContent={renderMonthContent}
        showMonthYearPicker
        dateFormat="MM/yyyy" 
        autoComplete="off"
        className="date-picker"
        disabled={disabled}
    />
  )
}
