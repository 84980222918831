import React from 'react'
import { Link } from 'react-router-dom'
import { FaUserEdit } from 'react-icons/fa'

export default function PriceList({prices, handleEditPrice}) {
   
  return (
    <table className="table">
        <thead>
            <tr>
            <th scope="col">Шифра</th>
            <th scope="col">Услуга</th>
            <th scope="col">Цена</th>
            <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            {prices.map((price, index) => {
                return (
                    <tr key={price.id}>
                        <td>{price.code}</td>
                        <td>{price.service}</td>
                        <td>{price.value}</td>
                        <td style={{width: '50px', paddingTop: '0'}}>
                            <button type="button" className="btn" onClick={() => handleEditPrice(price.id)}>
                                <FaUserEdit style={{color: '#39828f'}} />
                            </button>
                        </td>
                    </tr>
                )
            })}
            
        </tbody>
    </table>
  )
}
