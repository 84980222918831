import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import logo from "../../../../assets/images/cardiovita_logo.jpg";
import Spinner from "../../../SharedComponents/Spinner";
import axios from "axios";
import config from "../../../../config.json";
import { useNavigate } from "react-router-dom";

export default function SpecReviewPdf({ consultation, doctor, lang, isMultiLang }) {
    const [loading, setLoading] = useState(false);
    const [patient, setPatient] = useState({});
    const [fontSize1, setFontSize1] = useState(14);
    const [fontSize2, setFontSize2] = useState(14);
    const examDate = new Date(consultation.exam_date);
    const navigate = useNavigate();

    useEffect(() => {
        getPatient();
    }, []);

    const getPatient = () => {
        setLoading(true);
        axios({
            method: "get",
            url: `${config.SERVER_URL}/patients/${consultation.patient_id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        })
            .then((res) => {
                const patient = res.data;
                patient.date_of_birth = new Date(res.data.date_of_birth);
                setPatient(patient);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate("/signin");
                }
                NotificationManager.error(err.response.data);
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
            <div className="background-wrapper_print justify-content-center" style={{ width: "950px", height: "auto", backgroundColor: "inherit", marginBottom: "30px" }}>
                <form style={{ width: "100%" }}>
                    <div className="background-form-wrapper_print">
                        <div className="d-flex flex-column align-items-center w-100">
                            <h1 className="text-center border-0 pt-3">ЛЕКАРСКА ПРЕПОРАКА</h1>
                            {isMultiLang ? <h1 className="m-0 text-center border-0">Rekomandim Mjekësor</h1> : null}
                        </div>

                        <div className="w-100 d-flex mt-4 justify-content-between">
                            <div className="d-flex" style={{ width: "48%" }}>
                                <div style={{ width: "40%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">ИМЕ И ПРЕЗИМЕ </p>
                                    {isMultiLang ? <p className="m-0">Emri dhe Mbiemri</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "60%" }} className="d-flex align-items-end justify-content-start ps-5 fw-bold">
                                    {lang === "al" ? patient.name_latin : patient.name_cyrilic}
                                </div>
                            </div>
                            <div className="d-flex" style={{ width: "48%" }}>
                                <div style={{ width: "42%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">БРОЈ НА ПАЦИЕНТ</p>
                                    {isMultiLang ? <p className="m-0">Numri i pacientit</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "58%" }} className="d-flex align-items-end justify-content-start ps-5 fw-bold">
                                    {`CV${patient.id}`}
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex mt-2 justify-content-between">
                            <div className="d-flex" style={{ width: "48%" }}>
                                <div style={{ width: "40%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">ДАТУМ НА РАЃАЊЕ</p>
                                    {isMultiLang ? <p className="m-0">Datëlindja</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "60%" }} className="d-flex align-items-end justify-content-start ps-5 fw-bold">
                                    {patient.date_of_birth
                                        ? `${patient.date_of_birth.getDate()}/${patient.date_of_birth.getMonth() + 1 < 10 ? "0" + (patient.date_of_birth.getMonth() + 1) : patient.date_of_birth.getMonth() + 1}/${patient.date_of_birth.getFullYear()}`
                                        : ""}
                                </div>
                            </div>
                            <div className="d-flex" style={{ width: "48%" }}>
                                <div style={{ width: "42%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">ДАТУМ НА ПРЕПОРАКА</p>
                                    {isMultiLang ? <p className="m-0">Data e Rekomandimit</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "58%" }} className="d-flex align-items-end justify-content-start ps-5 fw-bold">
                                    {`${examDate.getDate()}/${examDate.getMonth() + 1 < 10 ? "0" + (examDate.getMonth() + 1) : examDate.getMonth() + 1}/${examDate.getFullYear()}`}
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex mt-2 justify-content-between">
                            <div className="d-flex" style={{ width: "48%" }}>
                                <div style={{ width: "40%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">МЕСТО НА ЖИВЕЕЊЕ</p>
                                    {isMultiLang ? <p className="m-0">Vendbanimi</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "60%" }} className="d-flex align-items-end justify-content-start ps-5 fw-bold">
                                    {lang === "al" ? patient.city_latin : patient.city_cyrilic}
                                </div>
                            </div>
                            <div className="d-flex" style={{ width: "48%" }}>
                                <div style={{ width: "42%", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <p className="m-0">ПРЕПОРАКА од</p>
                                    {isMultiLang ? <p className="m-0">Rekomandim prej</p> : null}
                                </div>

                                <div style={{ borderBottom: "1px solid #212529", width: "58%" }} className="d-flex align-items-end justify-content-start ps-5 fw-bold">
                                    {lang === "al" ? "Dr. " + doctor.name_latin : "Др. " + doctor.name_cyrilic}
                                </div>
                            </div>
                        </div>

                        <div className="w-100 d-flex mt-5" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">МОТИВ ЗА КОНСУЛТАЦИЈА</span> <br />
                                {isMultiLang ? <span>Motivet e konsultës</span> : null}
                            </div>
                            <div style={{ border: "1px solid #212529", width: "78%", borderRadius: "5px", height: "225px", overflow: "hidden", whiteSpace: "pre-wrap", lineHeight: fontSize1 + 4 + "px", fontSize: fontSize1 + "px" }} className="px-1">
                                {lang === "al" ? consultation.consult_motive_al : consultation.consult_motive}
                            </div>
                        </div>
                        <div className="font-size w-100 d-flex justify-content-end">
                            <select value={fontSize1} onChange={(e) => setFontSize1(Number(e.target.value))}>
                                <option value="14">14px</option>
                                <option value="13">13px</option>
                                <option value="12">12px</option>
                            </select>
                        </div>
                        <div className="w-100 d-flex mt-3" style={{ fontSize: "13px" }}>
                            <div style={{ border: "1px solid #212529", width: "21%", borderRadius: "5px", overflow: "hidden", marginRight: "2%", textAlign: "center" }} className="p-1">
                                <span className="uppercase">Препорака </span> <br />
                                {isMultiLang ? <span>Rekomandim</span> : null}
                            </div>
                            <div style={{ border: "1px solid #212529", width: "78%", borderRadius: "5px", height: "225px", overflow: "hidden", whiteSpace: "pre-wrap", lineHeight: fontSize2 + 4 + "px", fontSize: fontSize2 + "px" }} className="px-1">
                                {lang === "al" ? consultation.recommendation_al : consultation.recommendation}
                            </div>
                        </div>
                        <div className="font-size w-100 d-flex justify-content-end">
                            <select value={fontSize2} onChange={(e) => setFontSize2(Number(e.target.value))}>
                                <option value="14">14px</option>
                                <option value="13">13px</option>
                                <option value="12">12px</option>
                            </select>
                        </div>

                        <div className="w-100 d-flex justify-content-end doctor-sign" style={{ bottom: "90px" }}>
                            <div style={{ borderBottom: "1px solid #212529", width: "200px", paddingBottom: "50px" }}>
                                <div className="m-0 fw-bold">{lang === "al" ? "Dr." + doctor.name_latin : "Др." + doctor.name_cyrilic}</div>
                                <div className="m-0">{isMultiLang ? doctor.speciality_latin : doctor.speciality_cyrilic}</div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
