import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager} from 'react-notifications';
import config from '../../config.json';
import { getNameError, getCyrilicNameError } from '../../helpers/validators';
import Spinner from '../SharedComponents/Spinner';


const EditDoctor = () => { 
    const navigate = useNavigate();
    const [cyrName, setCyrName] = useState('');
    const [cyrNameError, setCyrNameError] = useState('');
    const [latName, setLatName] = useState('');
    const [latNameError, setLatNameError] = useState('');
    const [specialityCyrilic, setSpecialityCyrilic] = useState('');
    const [specialityLatin, setSpecialityLatin] = useState('');
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        getDoctor(id);
    },[id])

    const getDoctor = (id) => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/doctors/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            setCyrName(res.data.name_cyrilic); 
            setLatName(res.data.name_latin); 
            setSpecialityCyrilic(res.data.speciality_cyrilic)
            setSpecialityLatin(res.data.speciality_latin)
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
            navigate('..')
        })
        .finally(() => setLoading(false));
    }

    const editDoctor = (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            return;
        }
        setLoading(true)
        axios({
            method: 'put',
            url: `${config.SERVER_URL}/doctors/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                name_cyrilic: cyrName,
                name_latin: latName,
                speciality_cyrilic: specialityCyrilic,
                speciality_latin: specialityLatin
            }
        }).then(res => {
            NotificationManager.success('Успешна промена')
            navigate('..');
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const deleteDoctor = (e) => {
        e.preventDefault();
        setLoading(true);
        axios({
            method: 'delete',
            url: `${config.SERVER_URL}/doctors/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        }).then(res => {
            NotificationManager.success('Успешнo бришење');
            navigate('/doctors');
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));


        
    }

    const handleInputChange = (e) => {
        resetErrors();
        const { name, value} = e.target;
        switch (name) {
            case 'cyrName':
                setCyrName(value); 
                break;
            case 'latName':
                setLatName(value);
                break;
            case 'specialityCyrilic':
                setSpecialityCyrilic(value);
                break;
            case 'specialityLatin':
                setSpecialityLatin(value);
                break;
            default:
                break;
        }
    }

    const cancelRegister = () => {
        resetErrors();
        navigate('/doctors');
    }

    const isFormValid = () => {
        const cyrNameErrorMessage = getCyrilicNameError(cyrName, true);
        const latNameErrorMessage = getNameError(latName, true);

        if(cyrNameErrorMessage) {
            setCyrNameError(cyrNameErrorMessage);
        } 

        if(latNameErrorMessage) {
            setLatNameError(latNameErrorMessage);
        } 

        return (cyrNameErrorMessage || latNameErrorMessage) ? false : true;
    }

    const resetErrors = () => {
        setCyrNameError('');
        setLatNameError('');
    }

    return (
        <>
        <Link to='/doctors' type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}}>Назад</Link>
        <div className='background-wrapper' style={{width: 'auto', height: 'calc(100vh - 140px)',  backgroundColor: 'inherit'}}>
            {loading ? <Spinner /> : null}
            <div className='background-form-wrapper'>
                <h1>Додади нов корисник</h1>
                <form onSubmit={editDoctor}>
                    <div className='input-wrapper'>
                        <label htmlFor="cyrName">Име и презиме ( кирилица )</label>
                        <input type='text' name='cyrName' value={cyrName} onChange={handleInputChange}/>
                        <span className='error text-danger'>{cyrNameError}</span>
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="latName">Име и презиме (латиница)</label>
                        <input type='text' name='latName' value={latName} onChange={handleInputChange}/>
                        <span className='error text-danger'>{latNameError}</span>
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="specialityCyrilic">Специјалност ( кирилица )</label>
                        <input type='text' name='specialityCyrilic' value={specialityCyrilic} onChange={handleInputChange}/>
                        <span className='error text-danger'>{cyrNameError}</span>
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="specialityLatin">Специјалност ( латиница )</label>
                        <input type='text' name='specialityLatin' value={specialityLatin} onChange={handleInputChange}/>
                        <span className='error text-danger'>{latNameError}</span>
                    </div>
                    <div className='button-wrapper d-flex justify-content-between'>
                        <div>
                            <button className='submit p-2 me-2' type='submit'>Промени</button>
                            <button className='cancel p-2' type='button' onClick={cancelRegister}>Откажи</button>
                        </div>
                        <div className='m-0'>
                            <button className='delete p-2' type='button' onClick={deleteDoctor}>Избриши</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </>
    );
}

export default EditDoctor;