import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import axios from "axios";
import config from "../../config.json";
import HolterAppointmentsModal from "./HolterAppointmentsModal";
import { NotificationManager } from "react-notifications";

function HolterAppointmentsTable({ header, date }) {
    const navigate = useNavigate();
    const [appointments, setAppointments] = useState([]);
    const [returnAppointments, setReturnAppointments] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [selectedAppointment, setSelectedAppointment] = useState({});
    const [returnAppointment, setReturnAppointment] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [availableTimes, setAvailableTimes] = useState([]);
    const [returnAvailableTimes, setReturnAvailableTimes] = useState([]);
    const [showHolterReturn, setShowHolterReturn] = useState(false);

    useEffect(() => {
        getDoctors();
    }, []);

    useEffect(() => {
        getAppointmentsByDate(date);
    }, [date]);

    const handleShow = (appointment) => {
        if (!appointment.id) {
            appointment.status = "#FEDC80";
        }
        appointment.start_date_time = new Date(appointment.start_date_time);
        setSelectedAppointment(appointment);
        if (appointment.connected_id) {
            getReturnAppointment(appointment.connected_id);
        }
        setShowModal(true);
    };

    const handleClose = () => {
        setReturnAppointment({});
        setShowModal(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!selectedAppointment.doctor_id || !selectedAppointment.service || !selectedAppointment.patient_name || !selectedAppointment.patient_phone) {
            NotificationManager.error("Не се пополнети потребните информации");
            return;
        }
        setLoading(true);

        delete selectedAppointment.patient;
        delete selectedAppointment.doctor;
        delete returnAppointment.patient;
        delete returnAppointment.doctor;
        const itemsToSave = [selectedAppointment, returnAppointment];
        if (!showHolterReturn) {
            itemsToSave.pop();
        }
        const url = selectedAppointment.id ? `${config.SERVER_URL}/holter-appointments/${selectedAppointment.id}` : `${config.SERVER_URL}/holter-appointments/add`;

        axios({
            method: selectedAppointment.id ? "put" : "post",
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
            data: {
                appointments: itemsToSave,
            },
        })
            .then((res) => {
                NotificationManager.success("Успешно зачуван термин");
                setTimeout(() => {
                    getAppointmentsByDate(new Date(date));
                }, 100);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate("/signin");
                }
                NotificationManager.error(err.response.data);
            })
            .finally(() => {
                setReturnAppointment({});
                setSelectedAppointment({});
                setLoading(false);
                handleClose();
            });
    };

    const getAppointmentsByDate = async (date, isReturn = false) => {
        try {
            const response = await axios.get(`${config.SERVER_URL}/holter-appointments-bydate/${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
            });

            const appointments = response.data.map((a) => {
                a.start_date_time = new Date(a.start_date_time);
                return a;
            });

            if (isReturn) {
                setReturnAppointments(appointments);
                if (returnAppointment.start_date_time) {
                    setReturnAvailableTimes(getAvailableTimes(returnAppointment, appointments, "return"));
                }
            } else {
                setAppointments(appointments);
                if (selectedAppointment.start_date_time) {
                    setAvailableTimes(getAvailableTimes(selectedAppointment, appointments, "times"));
                }
            }
            return appointments;
        } catch (error) {
            console.error("Error fetching appointments:", error);
        }
    };

    const getReturnAppointment = async (id) => {
        //get and set return appoinemtnt
        try {
            const response = await axios.get(`${config.SERVER_URL}/holter-appointments/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
            });
            const appointment = { ...response.data };
            appointment.start_date_time = new Date(response.data.start_date_time);
            setReturnAppointment(appointment);
            getAppointmentsByDate(appointment.start_date_time, true);
        } catch (error) {
            console.error("Error fetching appointments:", error);
        }
    };

    const handleInputChange = (e) => {
        if (!e.target) {
            setSelectedAppointment({
                ...selectedAppointment,
                start_date_time: e,
            });
            return;
        }
        const { name, value } = e.target;
        switch (name) {
            case "patient_name":
                setSelectedAppointment({
                    ...selectedAppointment,
                    patient_name: value,
                });
                if (selectedAppointment.connected_id) {
                    setReturnAppointment({
                        ...returnAppointment,
                        patient_name: value,
                    });
                }
                break;
            case "patient_phone":
                setSelectedAppointment({
                    ...selectedAppointment,
                    patient_phone: value,
                });
                if (selectedAppointment.connected_id) {
                    setReturnAppointment({
                        ...returnAppointment,
                        patient_phone: value,
                    });
                }
                break;
            case "time":
                const dateTime = new Date(selectedAppointment.start_date_time);
                dateTime.setHours(Number(value.split(":")[0]));
                dateTime.setMinutes(Number(value.split(":")[1]));
                setSelectedAppointment({
                    ...selectedAppointment,
                    start_date_time: dateTime,
                });
                break;
            case "duration":
                setSelectedAppointment({
                    ...selectedAppointment,
                    duration: Number(value),
                });
                break;
            case "status":
                setSelectedAppointment({
                    ...selectedAppointment,
                    status: value,
                });
                break;
            case "service":
                setSelectedAppointment({
                    ...selectedAppointment,
                    service: value,
                });
                if (selectedAppointment.connected_id) {
                    setReturnAppointment({
                        ...returnAppointment,
                        patient_name: value,
                    });
                }
                break;
            case "doctor_id":
                setSelectedAppointment({
                    ...selectedAppointment,
                    doctor_id: Number(value),
                });
                if (selectedAppointment.connected_id) {
                    setReturnAppointment({
                        ...returnAppointment,
                        doctor_id: Number(value),
                    });
                }
                break;
            default:
                setSelectedAppointment({
                    ...selectedAppointment,
                    [name]: value,
                });
                break;
        }
    };

    const handleReturnInputChange = (e) => {
        if (!e.target) {
            const newDate = new Date(returnAppointment.start_date_time);
            newDate.setFullYear(e.getFullYear(), e.getMonth(), e.getDate());
            setReturnAppointment({
                ...returnAppointment,
                start_date_time: newDate,
            });
            getAppointmentsByDate(newDate, true);
            return;
        }
        const { name, value } = e.target;
        switch (name) {
            case "time":
                const dateTime = new Date(returnAppointment.start_date_time);
                dateTime.setHours(Number(value.split(":")[0]));
                dateTime.setMinutes(Number(value.split(":")[1]));
                setReturnAppointment({
                    ...returnAppointment,
                    start_date_time: dateTime,
                });
                break;
            case "duration":
                setReturnAppointment({
                    ...returnAppointment,
                    duration: Number(value),
                });
                setReturnAvailableTimes(getAvailableTimes(Number(value), returnAppointments));
                break;
            default:
                setReturnAppointment({
                    ...returnAppointment,
                    [name]: value,
                });
                break;
        }
    };

    const getDoctors = () => {
        setLoading(true);
        axios({
            method: "get",
            url: `${config.SERVER_URL}/doctors`,
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        })
            .then((res) => {
                setDoctors(res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate("/signin");
                }
                NotificationManager.error(err.response.data);
            })
            .finally(() => setLoading(false));
    };

    const getAvailableTimes = (selectedAppointment, appointments, text) => {
        console.log("------------------------------------------");
        console.log(text);
        console.log(selectedAppointment);
        console.log(appointments);
        console.log("------------------------------------------");
        const duration = selectedAppointment.duration;
        const availableTimes = [];

        const appointmentsCopy = appointments;

        const modifiedAppointments = appointmentsCopy.map((a) => {
            return { ...a };
        });

        if (selectedAppointment.id) {
            const selectedIndex = appointmentsCopy.findIndex((a) => a.id === selectedAppointment.id);
            console.log(selectedIndex);

            if (selectedIndex !== -1 && !appointmentsCopy[selectedIndex === 0 ? 0 : selectedIndex - 1].id) {
                modifiedAppointments[selectedIndex].duration += modifiedAppointments[selectedIndex - 1].duration;
                modifiedAppointments[selectedIndex].start_date_time = new Date(modifiedAppointments[selectedIndex - 1].start_date_time);
                modifiedAppointments[selectedIndex - 1].id = -1;
            }
            if (selectedIndex !== -1 && !appointmentsCopy[selectedIndex === appointmentsCopy.length - 1 ? selectedIndex : selectedIndex + 1].id) {
                modifiedAppointments[selectedIndex].duration += appointmentsCopy[selectedIndex + 1].duration;
                modifiedAppointments[selectedIndex + 1].id = -1;
            }
        }

        const emptyAppointments = modifiedAppointments.filter((a) => !a.id || a.id === selectedAppointment.id);

        emptyAppointments.map((a) => {
            if (a.duration >= duration) {
                const endEmptyTime = new Date(a.start_date_time);
                endEmptyTime.setMinutes(endEmptyTime.getMinutes() + a.duration);
                const startEmptyTime = new Date(a.start_date_time);
                let endAppointmentTime = new Date(startEmptyTime);
                do {
                    availableTimes.push(new Date(startEmptyTime));
                    startEmptyTime.setMinutes(startEmptyTime.getMinutes() + 5);
                    endAppointmentTime = new Date(startEmptyTime);
                    endAppointmentTime.setMinutes(endAppointmentTime.getMinutes() + duration);
                } while (endAppointmentTime.getTime() <= endEmptyTime.getTime());
            }
        });
        return availableTimes;
    };

    const getAllTimes = (date, text) => {
        const startTime = new Date(date);
        startTime.setHours(8, 0, 0, 0);

        const endTime = new Date(date);
        endTime.setHours(20, 0, 0, 0);

        const allTimes = [];
        while (startTime.getTime() < endTime.getTime()) {
            allTimes.push(new Date(startTime));
            startTime.setMinutes(startTime.getMinutes() + 5);
        }
        return allTimes;
    };

    const handleRemoveAppointment = () => {
        setLoading(true);
        axios({
            method: "delete",
            url: `${config.SERVER_URL}/holter-appointments/${selectedAppointment.id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        })
            .then((res) => {
                NotificationManager.success("Успешно избришан термин");
                getAppointmentsByDate(new Date(selectedAppointment.start_date_time));
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate("/signin");
                }
                NotificationManager.error(err.response.data);
            })
            .finally(() => {
                setSelectedAppointment({});
                setLoading(false);
                handleClose();
            });
    };

    return (
        <div>
            <div style={{ fontSize: "18px", textAlign: "center", borderBottom: "1px solid #ddd", display: "flex", marginTop: "-15px", paddingBottom: "10px" }}>
                <b className="w-50 text-start d-flex align-items-center">{header}</b>
                <span className="w-50 text-end"></span>
            </div>

            <div style={{ border: "1px solid black" }}>
                <div style={{ fontSize: "16px", textAlign: "center", borderBottom: "1px solid #ddd", backgroundColor: "#39828f", color: "#fff", padding: "5px 0", position: "relative" }}>
                    <b>Холтер ЕКГ / Холтер АБП</b>
                </div>
                <div style={{ fontSize: "16px", textAlign: "center", borderBottom: "1px solid #ddd" }}>
                    <table className="table table-hover table-borderless align-middle mb-0 table-sm">
                        <thead>
                            <tr>
                                <th scope="col" style={{ textAlign: "left" }}>
                                    Време
                                </th>
                                <th scope="col">Име и презиме</th>
                                <th scope="col">Услуга</th>
                                <th scope="col">Доктор</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {appointments.map((item, index) => {
                                const startDateTime = new Date(item.start_date_time);

                                const endDate = new Date(startDateTime);
                                const endDateTime = new Date(endDate.setMinutes(endDate.getMinutes() + item.duration));
                                item.doctor = doctors.find((d) => d.id === item.doctor_id);
                                return (
                                    <tr
                                        key={item.id + "-" + index}
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "14px",
                                            boxSizing: "border-box",
                                            height: item.duration * 2 + "px",
                                            lineHeight: "20px",
                                            backgroundColor: item.patient_name ? item.status : "#fff",
                                            overflow: "hidden",
                                            borderBottom: "1px solid #fff",
                                        }}
                                        onClick={item.patient_name ? null : () => handleShow(item)}
                                    >
                                        {item.duration >= 30 ? (
                                            <td className="py-0" style={{ width: "13%" }}>
                                                <div className="d-flex flex-column justify-content-between py-1" style={{ height: item.duration * 2 + "px" }}>
                                                    <span style={{ lineHeight: "14px", textAlign: "left" }}>{`${startDateTime.getHours()}:${startDateTime.getMinutes() < 10 ? "0" + startDateTime.getMinutes() : startDateTime.getMinutes()}`}</span>
                                                    <span style={{ height: item.duration * 2 - 40 + "px", borderLeft: "1px dashed #000", marginLeft: "15px" }}></span>
                                                    <span style={{ lineHeight: "14px", textAlign: "left" }}>{`${endDateTime.getHours()}:${endDateTime.getMinutes() < 10 ? "0" + endDateTime.getMinutes() : endDateTime.getMinutes()}`}</span>
                                                </div>
                                            </td>
                                        ) : (
                                            <td className="py-0" style={{ width: "13%", textAlign: "left" }}>
                                                {`${startDateTime.getHours()}:${startDateTime.getMinutes() < 10 ? "0" + startDateTime.getMinutes() : startDateTime.getMinutes()}`} -{" "}
                                                {`${endDateTime.getHours()}:${endDateTime.getMinutes() < 10 ? "0" + endDateTime.getMinutes() : endDateTime.getMinutes()}`}
                                            </td>
                                        )}
                                        <td className="py-0" style={{ width: "30%" }}>
                                            <div style={{ maxHeight: item.duration * 2 + "px", overflow: "hidden" }}>
                                                {item.patient_id > 0 ? (
                                                    <Link to={`/patients/${item.patient_id}`} style={{ color: "#39828f" }}>
                                                        <b>{item.patient_name}</b>
                                                    </Link>
                                                ) : (
                                                    <b style={{ color: "#39828f" }}>{item.patient_name}</b>
                                                )}
                                            </div>
                                        </td>
                                        <td className="py-0" style={{ width: "23%" }}>
                                            <div style={{ maxHeight: item.duration * 2 + "px", overflow: "hidden" }}>{item.service}</div>
                                        </td>
                                        <td className="py-0" style={{ width: "29%" }}>
                                            <div style={{ maxHeight: item.duration * 2 + "px", overflow: "hidden" }}>{item.doctor?.name_cyrilic}</div>
                                        </td>
                                        <td className="py-0" style={{ width: "5%" }}>
                                            {item.patient_name ? (
                                                <div style={{ maxHeight: item.duration * 2 + "px", overflow: "hidden" }}>
                                                    <FaEdit style={{ color: "#39828f", fontSize: "18px" }} onClick={() => handleShow(item)} />
                                                </div>
                                            ) : null}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {showModal && (
                <HolterAppointmentsModal
                    show={showModal}
                    close={handleClose}
                    handleSubmit={handleSubmit}
                    handleRemoveAppointment={handleRemoveAppointment}
                    appointment={selectedAppointment}
                    returnAppointment={returnAppointment}
                    appointments={appointments}
                    returnAppointments={returnAppointments}
                    handleInputChange={handleInputChange}
                    handleReturnInputChange={handleReturnInputChange}
                    setSelectedAppointment={setSelectedAppointment}
                    setReturnAppointment={setReturnAppointment}
                    doctors={doctors}
                    availableTimes={selectedAppointment.start_date_time && appointments.length ? getAvailableTimes(selectedAppointment, appointments, "times") : []}
                    returnAvailableTimes={returnAppointment.start_date_time && returnAppointments.length ? getAvailableTimes(returnAppointment, returnAppointments, "return") : []}
                    allTimes={selectedAppointment.start_date_time ? getAllTimes(selectedAppointment.start_date_time) : []}
                    allReturnTimes={returnAppointment.start_date_time ? getAllTimes(returnAppointment.start_date_time) : []}
                    getAppointmentsByDate={getAppointmentsByDate}
                    showHolterReturn={showHolterReturn}
                    setShowHolterReturn={setShowHolterReturn}
                />
            )}
        </div>
    );
}

export default HolterAppointmentsTable;
