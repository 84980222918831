
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager} from 'react-notifications';
import config from '../../../../config.json';
import Spinner from '../../../SharedComponents/Spinner';
import { BsFillPrinterFill } from 'react-icons/bs'
import { AiFillCloseCircle, AiFillPlusCircle } from 'react-icons/ai'

import CustomDatePicker from '../../../SharedComponents/CustomDatePicker';
import CardioExamPdf from './CardioExamPdf';
import ExportPatientPdf from '../../ExportPatientPdf';
import { template1 } from '../../../../helpers/templates';


const EditCardioExam = () => {
    const navigate = useNavigate();
    const [cardioExam, setCardioExam] = useState({});
    const [date, setDate] = useState(); 
    const [loading, setLoading] = useState(false);
    const [print, setPrint] = useState(false);
    const [doctors, setDoctors] = useState();
    const {id, examId} = useParams();
    const [medications, setMedications] = useState([]);
    const [showMK, setShowMK] = useState(true)
    const [showAL, setShowAL] = useState(true)

    

    const togglePrint = () => {
        setPrint(!print);
    }

    useEffect(() => {
        getDoctors();
    }, [])

    const getDoctors = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/doctors`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            setDoctors(res.data); 
            getExam(examId)
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));

    }

    const getExam = (examId) => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/patients/${id}/exams/cardio/${examId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            setMedications(res.data.therapy)
            delete res.data.therapy;
            setCardioExam(res.data); 
            setDate(new Date(res.data.exam_date))
            
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
            navigate('..')
        })
        .finally(() => setLoading(false));
    }
    
        

    const editExam = (e) => {
        e.preventDefault();
        // if (!isFormValid()) {
        //     return;
        // }
        const therapyToSave = [];
        medications.map(t => {
            if (t.medication && t.medication !== '') {
                therapyToSave.push(t)
            }
        })

        const newCardioExam = {...cardioExam};
        const resetedExam = {};
        let cardioExamToSave = {};
        if (!showAL) {   
            Object.keys(newCardioExam).map(k => {
                const last3 = k.substring(k.length - 3);
                if (last3 === '_al') {
                    resetedExam[k] = null;
                } else {
                    resetedExam[k] = newCardioExam[k]
                }
            })
            cardioExamToSave = {...resetedExam}
        } else if (!showMK) {   
            Object.keys(newCardioExam).map(k => {
                const last3 = k.substring(k.length - 3);
                if (last3 !== '_al' && last3 !== '_id' && k !== 'exam_date' && k !== 'id' && last3 !== '_by' && last3 !== '_on' ) {
                    resetedExam[k] = null;
                } else {
                    resetedExam[k] = newCardioExam[k]
                }
            })
            cardioExamToSave = {
                ...resetedExam, doctor_id: cardioExam.doctor_id, exam_date: cardioExam.exam_date
            }
        } else {
            cardioExamToSave = {...cardioExam}
        }
        setLoading(true)
        axios({
            method: 'put',
            url: `${config.SERVER_URL}/patients/${id}/exams/cardio/${examId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                ...cardioExamToSave,
                therapy: therapyToSave
            }
        }).then(res => {
            const data = res.data
            setCardioExam(data); 
            setMedications(data.therapy)
            setDate(new Date(data.exam_date))
            NotificationManager.success('Извештајот е успешно променет')
            // navigate(`/patients/${id}/exams/cardio/${res.data.exam_id}`);
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const handleInputChange = (e) => {
        resetErrors();
        const { name, value} = e.target;
        setCardioExam({
            ...cardioExam,
            [name]: value,
          });
    }

    const handleTherapyChange = (index, e) => {
        resetErrors();
        const { name, value} = e.target;
        const newMedications = [...medications]
        newMedications[index] = {...newMedications[index], [name.split('_')[0]]: value}
        setMedications([...newMedications])
    }
    


    const resetErrors = () => {
        
        
    }

    const setExamDate = (e) => {
        setCardioExam({
            ...cardioExam,
            'exam_date': e,
          });
          setDate(e);
    }

    const handlePlus = () => {
        const newMedications = [...medications]
        newMedications.push({medication: '', morning: '', lunch: '', evening: ''})
        setMedications([...newMedications])
    }

    const handleMinus = (index) => {
        if (medications[index]) {
            const newMedications = [...medications]
            newMedications.splice(index, 1);
            setMedications([...newMedications])
        }
        
    }

    const resetAL = () => {
        setShowAL(false)
        if (!showMK) {
            returnMK();
        }
    }

    const returnAL = () => {
        setShowAL(true)
    }

    const resetMK = () => {
        setShowMK(false)
        if (!showAL) {
            returnAL();
        }
    }

    const returnMK = () => {
        setShowMK(true)
    }

    const getTemplate = () => {
        setCardioExam({
            ...cardioExam,
            ...template1
        })
    }

    return (
        <>
        {print ? 
            <ExportPatientPdf togglePrint={togglePrint} showLangTemplate={true}>
                <CardioExamPdf medications={medications} cardioExam={cardioExam} doctor={doctors.find(d => d.id == cardioExam.doctor_id)}/>
            </ExportPatientPdf>
         : <div className='background-wrapper_patient' style={{width: 'auto', height: 'auto',  backgroundColor: 'inherit'}}>
            {loading ? <Spinner /> : null}
                <form style={{width: '100%'}} onSubmit={editExam}>
                    <div className='background-form-wrapper_patient'>
                        <h1 className='mt-3'>Кардиолошки преглед</h1>
                        <div className='d-flex justify-content-end w-100'>
                            <button className='btn fs-6 p-10' style={{marginLeft: '10px', backgroundColor: '#39828f', color: '#fff'}} type='button'  onClick={getTemplate}>
                                Превземи темплејт
                            </button>  
                            {showMK ? 
                            <button className='btn fs-6 p-10' style={{marginLeft: '10px', backgroundColor: '#ad4e56', color: '#fff'}} type='button'  onClick={resetMK}>
                                Избриши МК
                            </button> :
                            <button className='btn fs-6 p-10' style={{marginLeft: '10px', backgroundColor: '#39828f', color: '#fff'}} type='button'  onClick={returnMK}>
                                Врати МК
                            </button>  
                            }
                            {showAL ? 
                            <button className='btn fs-6 p-10' style={{marginLeft: '10px', backgroundColor: '#ad4e56', color: '#fff'}} type='button'  onClick={resetAL}>
                                Избриши АЛ
                            </button> :
                            <button className='btn fs-6 p-10' style={{marginLeft: '10px', backgroundColor: '#39828f', color: '#fff'}} type='button'  onClick={returnAL}>
                                Врати АЛ
                            </button>
                            }
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="exam_date">Датум на преглед</label>
                            <CustomDatePicker name='exam_date' startDate={date} setStartDate={setExamDate} />
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="doctor_id">Доктор</label>
                            <select name="doctor_id" value={cardioExam.doctor_id} onChange={handleInputChange}>
                                {doctors && doctors.map(doctor => <option key={doctor.id} value={doctor.id}>{doctor.name_cyrilic}</option>)}
                            </select>
                        </div>
                        {showMK && 
                        <div className='input-wrapper' style={{marginRight: '10%', width: showMK && !showAL ? '100%' : '40%'}}>
                            <label htmlFor="anamnestic_data">Анамнестички податоци</label>
                            <textarea className="form-control" placeholder="Анамнестички податоци..." value={cardioExam.anamnestic_data} name="anamnestic_data" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        }
                        {showAL && 
                        <div className='input-wrapper' style={{marginRight: '10%', width: !showMK && showAL ? '100%' : '40%'}}>
                            <label htmlFor="anamnestic_data_al">Të dhënat anamnestike</label>
                            <textarea className="form-control" placeholder="Të dhënat anamnestike..." value={cardioExam.anamnestic_data_al} name="anamnestic_data_al" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        }

                        {showMK &&
                        <div className='input-wrapper' style={{marginRight: '10%', width: showMK && !showAL ? '100%' : '40%'}}>
                            <label htmlFor="objective_find">Објективен наод</label>
                            <textarea className="form-control" placeholder="Објективен наод..." value={cardioExam.objective_find} name="objective_find" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        }
                        {showAL && 
                        <div className='input-wrapper' style={{marginRight: '10%', width: !showMK && showAL ? '100%' : '40%'}}>
                            <label htmlFor="objective_find_al">Ekzaminimi objektiv</label>
                            <textarea className="form-control" placeholder="Ekzaminimi objektiv..." value={cardioExam.objective_find_al} name="objective_find_al" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        }
                        <div className='mt-3' style={{marginRight: '10%', width: '100%'}}>
                            <h5 htmlFor="echocardiography" style={{color: 'rgb(57, 130, 143)'}}>Ехокардиографија</h5>
                            <div className='d-flex flex-wrap justify-content-between p-2' style={{border: '1px solid rgb(57, 130, 143)'}}>
                                <p className='w-100 fw-bold'>M-Mod</p>
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: '21%'}}>
                                    <label style={{width: '20%'}} htmlFor="ao">Ao:</label>
                                    <input type="number" className="form-control" name='ao' value={cardioExam.ao} onChange={handleInputChange}/>
                                    <span className="input-group-text">mm</span>
                                </div>
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: '21%'}}>
                                    <label style={{width: '20%'}} htmlFor="ivsd">IVSd:</label>
                                    <input type="number" className="form-control" name='ivsd' value={cardioExam.ivsd} onChange={handleInputChange}/>
                                    <span className="input-group-text">mm</span>
                                </div>
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: '21%'}}>
                                    <label style={{width: '20%'}} htmlFor="lvds">LVDS:</label>
                                    <input type="number" className="form-control" name='lvds' value={cardioExam.lvds} onChange={handleInputChange} />
                                    <span className="input-group-text">mm</span>
                                </div>
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: '21%'}}>
                                    <label style={{width: '20%'}} htmlFor="ef">EF:</label>
                                    <input type="number" className="form-control" name='ef' value={cardioExam.ef} onChange={handleInputChange}/>
                                    <span className="input-group-text">%</span>
                                </div>
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: '21%'}}>
                                    <label style={{width: '20%'}} htmlFor="la">LA:</label>
                                    <input type="number" className="form-control" name='la' value={cardioExam.la} onChange={handleInputChange}/>
                                    <span className="input-group-text">mm</span>
                                </div>
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: '21%'}}>
                                    <label style={{width: '20%'}} htmlFor="lvdd">LVDd:</label>
                                    <input type="number" className="form-control" name='lvdd' value={cardioExam.lvdd} onChange={handleInputChange}/>
                                    <span className="input-group-text">mm</span>
                                </div>
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: '21%'}}>
                                    <label style={{width: '20%'}} htmlFor="edv">EDV:</label>
                                    <input type="number" className="form-control" name='edv' value={cardioExam.edv} onChange={handleInputChange}/>
                                    <span className="input-group-text">ml</span>
                                </div>
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: '21%'}}>
                                    <label style={{width: '20%'}} htmlFor="fs">FS:</label>
                                    <input type="number" className="form-control" name='fs' value={cardioExam.fs} onChange={handleInputChange}/>
                                    <span className="input-group-text">%</span>
                                </div>
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: '21%'}}>
                                    <label style={{width: '20%'}} htmlFor="rvd">RVD:</label>
                                    <input type="number" className="form-control" name='rvd' value={cardioExam.rvd} onChange={handleInputChange}/>
                                    <span className="input-group-text">mm</span>
                                </div>
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: '21%'}}>
                                    <label style={{width: '20%'}} htmlFor="pwdd">PWDd:</label>
                                    <input type="number" className="form-control" name='pwdd' value={cardioExam.pwdd} onChange={handleInputChange}/>
                                    <span className="input-group-text">mm</span>
                                </div>
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: '21%'}}>
                                    <label style={{width: '20%'}} htmlFor="esv">ESV:</label>
                                    <input type="number" className="form-control" name='esv' value={cardioExam.esv} onChange={handleInputChange}/>
                                    <span className="input-group-text">ml</span>
                                </div>
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: '21%'}}>
                                    <label style={{width: '20%'}} htmlFor="lv">Ao asc:</label>
                                    <input type="text" className="form-control" name='lv' value={cardioExam.lv} onChange={handleInputChange}/>
                                    <span className="input-group-text">mm</span>
                                </div>

                                <p className='w-100 fw-bold'>Dopler</p>

                                {showMK && 
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: showMK && !showAL ? '100%' : '47%'}}>
                                    <label style={{width: '30%'}} htmlFor="valvula_mitrale">Митрална валвула:</label>
                                    <input type="text" className="form-control" value={cardioExam.valvula_mitrale}  name='valvula_mitrale' onChange={handleInputChange}/>
                                    {/* <span className="input-group-text">&</span> */}
                                </div>
                                }
                                {showAL &&
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: !showMK && showAL ? '100%' : '47%'}}>
                                    <label style={{width: '30%'}} htmlFor="valvula_mitrale_al">Valvula Mitrale:</label>
                                    <input type="text" className="form-control" value={cardioExam.valvula_mitrale_al} name='valvula_mitrale_al' onChange={handleInputChange}/>
                                    {/* <span className="input-group-text">&</span> */}
                                </div>
                                }
                                {showMK && 
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: showMK && !showAL ? '100%' : '47%'}}>
                                    <label style={{width: '30%'}} htmlFor="valvula_aortale">Аортна валвула:</label>
                                    <input type="text" className="form-control" value={cardioExam.valvula_aortale} name='valvula_aortale' onChange={handleInputChange}/>
                                    {/* <span className="input-group-text">&</span> */}
                                </div>
                                }
                                {showAL &&
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: !showMK && showAL ? '100%' : '47%'}}>
                                    <label style={{width: '30%'}} htmlFor="valvula_aortale_al">Valvula Aortale:</label>
                                    <input type="text" className="form-control" value={cardioExam.valvula_aortale_al} name='valvula_aortale_al' onChange={handleInputChange}/>
                                    {/* <span className="input-group-text">&</span> */}
                                </div>
                                }
                                {showMK && 
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: showMK && !showAL ? '100%' : '47%'}}>
                                    <label style={{width: '30%'}} htmlFor="valvula_trikuspidale">Трикуспидна валвула:</label>
                                    <input type="text" className="form-control" value={cardioExam.valvula_trikuspidale} name='valvula_trikuspidale' onChange={handleInputChange}/>
                                    {/* <span className="input-group-text">&</span> */}
                                </div>
                                }
                                {showAL &&
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: !showMK && showAL ? '100%' : '47%'}}>
                                    <label style={{width: '30%'}} htmlFor="valvula_trikuspidale_al">Valvula Trikuspidale:</label>
                                    <input type="text" className="form-control" value={cardioExam.valvula_trikuspidale_al} name='valvula_trikuspidale_al' onChange={handleInputChange}/>
                                    {/* <span className="input-group-text">&</span> */}
                                </div>
                                }

                                {showMK && 
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: showMK && !showAL ? '100%' : '47%'}}>
                                    <label style={{width: '30%'}} htmlFor="valvula_pulmonale">Пулмонална валвула:</label>
                                    <input type="text" className="form-control" value={cardioExam.valvula_pulmonale} name='valvula_pulmonale' onChange={handleInputChange}/>
                                    {/* <span className="input-group-text">&</span> */}
                                </div>
                                }
                                {showAL &&
                                <div className="input-group mb-3 align-items-center justify-content-between" style={{width: !showMK && showAL ? '100%' : '47%'}}>
                                    <label style={{width: '30%'}} htmlFor="valvula_pulmonale_al">Valvula Pulmonale:</label>
                                    <input type="text" className="form-control" value={cardioExam.valvula_pulmonale_al} name='valvula_pulmonale_al' onChange={handleInputChange}/>
                                    {/* <span className="input-group-text">&</span> */}
                                </div>
                                }
                                {showMK && 
                                <div className='input-wrapper' style={{marginRight: '0', width: showMK && !showAL ? '100%' : '47%'}}>
                                    <label htmlFor="conclusion">Заклучок</label>
                                    <textarea className="form-control" placeholder="Заклучок..." value={cardioExam.conclusion} name="conclusion" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                                    {/* <span className='error text-danger'>{nameError}</span> */}
                                </div>
                                }
                                {showAL &&
                                <div className='input-wrapper' style={{marginRight: '0', width: !showMK && showAL ? '100%' : '47%'}}>
                                    <label htmlFor="conclusion_al">Përfundim</label>
                                    <textarea className="form-control" placeholder="Përfundim..." value={cardioExam.conclusion_al} name="conclusion_al" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                                    {/* <span className='error text-danger'>{nameError}</span> */}
                                </div>
                                }                   
                            </div>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        {showMK && 
                        <div className='input-wrapper' style={{marginRight: '10%', width: showMK && !showAL ? '100%' : '40%'}}>
                            <label htmlFor="further_investigation">Дополнителни иследувања</label>
                            <textarea className="form-control" placeholder="Дополнителни иследувања..." value={cardioExam.further_investigation}  name="further_investigation" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        }
                        {showAL &&
                        <div className='input-wrapper' style={{marginRight: '10%', width: !showMK && showAL ? '100%' : '40%'}}>
                            <label htmlFor="further_investigation_al">Ekzaminime plotësuese</label>
                            <textarea className="form-control" placeholder="Ekzaminime plotësuese..." value={cardioExam.further_investigation_al} name="further_investigation_al" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        }
                        {showMK &&
                        <div className='input-wrapper' style={{marginRight: '10%', width: showMK && !showAL ? '100%' : '40%'}}>
                            <label htmlFor="diagnose">Дијагноза</label>
                            <textarea className="form-control" placeholder="Дијагноза..." value={cardioExam.diagnose} name="diagnose" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        }
                        {showAL &&
                        <div className='input-wrapper' style={{marginRight: '10%', width: !showMK && showAL ? '100%' : '40%'}}>
                            <label htmlFor="diagnose_al">Diagnoza</label>
                            <textarea className="form-control" placeholder="Diagnoza..." value={cardioExam.diagnose_al} name="diagnose_al" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        }
                        <div className='mt-3' style={{ width: '90%', marginRight: '50px'}}>
                            <h5 htmlFor="echocardiography" style={{color: 'rgb(57, 130, 143)'}}>Терапија</h5>
                            <div className='d-flex justify-content-between input-group' style={{border: '1px solid rgb(57, 130, 143)'}}>
                                <div style={{width: '100%'}}>
                                    {medications.length > 0 ? medications.map((item, index) => {
                                        return (
                                            <div className='d-flex' key={`therapy_${index}`}>
                                                <input type="text" 
                                                    className="form-control" 
                                                    placeholder="Лек" 
                                                    name={`medication_${index}`} 
                                                    style={{width: '42%', borderRadius: '0'}} 
                                                    onChange={(e) => handleTherapyChange(index, e)}
                                                    value={item.medication}
                                                    />
                                                <input type="text" 
                                                    className="form-control text-center" 
                                                    placeholder="Утро" 
                                                    name={`morning_${index}`} 
                                                    style={{width: '16%', borderRadius: '0'}} 
                                                    onChange={(e) => handleTherapyChange(index, e)}
                                                    value={item.morning}
                                                    />
                                                <input type="text" 
                                                    className="form-control text-center" 
                                                    placeholder="Ручек" 
                                                    name={`lunch_${index}`} 
                                                    style={{width: '16%', borderRadius: '0'}} 
                                                    onChange={(e) => handleTherapyChange(index, e)}
                                                    value={item.lunch}
                                                    />
                                                <input type="text" 
                                                    className="form-control text-center" 
                                                    placeholder="Вечер" 
                                                    name={`evening_${index}`} 
                                                    style={{width: '16%', borderRadius: '0'}} 
                                                    onChange={(e) => handleTherapyChange(index, e)}
                                                    value={item.evening}
                                                    />
                                                <div className='plus-minus-btn text-center' style={{width: '10%'}}>
                                                    <button type='button' onClick={handlePlus} className='btn plus-btn'>
                                                        <AiFillPlusCircle />
                                                    </button>
                                                    <button type='button' onClick={() => handleMinus(index)} className='btn minus-btn'>
                                                        <AiFillCloseCircle />
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }) :  (
                                       
                                        <div className='d-flex align-items-center p-1'>
                                            <div style={{width: '50%'}}>Без терапија</div>
                                            <div className='plus-minus-btn d-flex justify-content-end' style={{width: '50%'}}>
                                                <button type='button' onClick={handlePlus}  className='btn plus-btn'>
                                                    <AiFillPlusCircle />
                                                </button>
                                            </div>
                                        </div>
                                          
                                    )}
                                    
                                    
                                </div>
                                
                            </div>
                        </div>
                        {showMK &&
                        <div className='input-wrapper' style={{marginRight: '10%', width: showMK && !showAL ? '100%' : '40%'}}>
                            <label htmlFor="recommendation">Препорака</label>
                            <textarea className="form-control" placeholder="Препорака..." value={cardioExam.recommendation} name="recommendation" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        }
                        {showAL &&
                        <div className='input-wrapper' style={{marginRight: '10%', width: !showMK && showAL ? '100%' : '40%'}}>
                            <label htmlFor="recommendation_al">Rekomandim</label>
                            <textarea className="form-control" placeholder="Rekomandim..." value={cardioExam.recommendation_al} name="recommendation_al" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        }
                        <div className='button-wrapper d-flex w-100'>
                            <button className='submit' type='submit'>Промени</button>
                            <button className='print fs-4 p-0' style={{marginLeft: '10px'}} type='button'  onClick={togglePrint}>
                                <BsFillPrinterFill />
                            </button>
                        </div>
                    </div>
                </form>
        </div>
        }
        </>
    );
}

export default EditCardioExam;