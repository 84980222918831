import React, { useEffect, useState } from 'react'
import Card from '../SharedComponents/Card';
import { Link, useNavigate } from 'react-router-dom';
import {FaUserEdit} from 'react-icons/fa'
import axios from 'axios';
import config from '../../config.json';
import {NotificationManager} from 'react-notifications';
import Spinner from "../../components/SharedComponents/Spinner";

export default function DoctorList() {
    const navigate = useNavigate();
    const [doctors, setDoctors] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getDoctors();
    }, [])

    const getDoctors = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/doctors`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            setDoctors(res.data); 
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));

    }

    return (
        <>
        {loading ? <Spinner /> : null}
        <Link to='add' type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}}>Додади доктор</Link>
        <Card>
            
            <table className="table">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Име и презиме ( кирилица )</th>
                    <th scope="col">Име и презиме ( латиница )</th>
                    <th scope="col">Специјалност</th>
                    <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {doctors.map((doctor, index) => {
                        return (
                            <tr key={doctor.id}>
                                <th>{index + 1}</th>
                                <td>{doctor.name_cyrilic}</td>
                                <td>{doctor.name_latin}</td>
                                <td>{doctor.speciality_cyrilic}</td>
                                <td style={{width: '50px', paddingTop: '0'}}>
                                    <Link to={`${doctor.id}`} type="button" className="btn">
                                        <FaUserEdit style={{color: '#39828f'}} />
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                    
                </tbody>
            </table>
        </Card>
        </>
    )
}
