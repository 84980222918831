import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager} from 'react-notifications';
import config from '../../config.json';
import { getUsernameError, getCyrilicNameError, getPasswordError } from '../../helpers/validators';
import Spinner from '../SharedComponents/Spinner';
import Form from 'react-bootstrap/Form';


const Register = () => {
    
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [roles, setRoles] = useState(''); 
    const [loading, setLoading] = useState(false);

    const handleRegister = (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            return;
        }
        setLoading(true)
        axios({
            method: 'post',
            url: `${config.SERVER_URL}/users/add`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                name: name,
                username: username,
                password: password,
                roles: roles
            }
        }).then(res => {
            NotificationManager.success('Успешно додаден корисник')
            navigate('..');
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const handleInputChange = (e) => {
        resetErrors();
        const { name, value} = e.target;
        switch (name) {
            case 'name':
                setName(value); 
                break;
            case 'username':
                setUsername(value);
                break;
            case 'password':
                setPassword(value);
                break;
            default:
                break;
        }
    }

    const handleRoleChange = (e) => {
        const role = e.target.value;
        const newRoles = roles.length === 0 ? [] : roles.split(',');
        const roleIndex = newRoles.findIndex(r => r === role);
        if (roleIndex !== -1) {
            newRoles.splice(roleIndex, 1);
        } else {
            newRoles.push(role);
        }
        setRoles(newRoles.sort().join(','));
    }

    const cancelRegister = () => {
        resetErrors();
        navigate('/users');
    }

    const isFormValid = () => {
        const nameErrorMessage = getCyrilicNameError(name);
        const usernameErrorMessage = getUsernameError(username);
        const passwordErrorMessage = getPasswordError(password);

        if(nameErrorMessage) {
            setNameError(nameErrorMessage);
        } 

        if(usernameErrorMessage) {
            setUsernameError(usernameErrorMessage);
        } 

        if(passwordErrorMessage) {
            setPasswordError(passwordErrorMessage);
        } 

        return (nameErrorMessage || usernameErrorMessage || passwordErrorMessage) ? false : true;
    }

    const resetErrors = () => {
        
        setNameError('');
        setUsernameError('');
        setPasswordError('');
    }

    return (
        <div className='background-wrapper' style={{width: 'auto', height: 'calc(100vh - 140px)',  backgroundColor: 'inherit'}}>
            {loading ? <Spinner /> : null}
            <div className='background-form-wrapper'>
                <h1>Додади нов корисник</h1>
                <form onSubmit={handleRegister}>
                    <div className='input-wrapper'>
                        <label htmlFor="name">Име и презиме</label>
                        <input type='text' name='name' onChange={handleInputChange}/>
                        <span className='error text-danger'>{nameError}</span>
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="username">Корисничко име</label>
                        <input type='text' name='username' onChange={handleInputChange}/>
                        <span className='error text-danger'>{usernameError}</span>
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="password">Лозинка</label>
                        <input type='password' name='password' onChange={handleInputChange}/>
                        <span className='error text-danger'>{passwordError}</span>
                    </div>
                    
                    <div className='d-flex flex-column justify-content-between' style={{paddingTop: '30px'}}>
                        <div className='input-wrapper'>
                            <label htmlFor="password">Улога</label>
                            <hr style={{marginTop: "0"}}/>
                        </div> 
                            <Form.Check // prettier-ignore
                                type='checkbox'
                                id={`admin`}
                                label={`Администратор`}
                                name='admin'
                                value='Администратор'
                                onChange={handleRoleChange}
                                style={{marginTop: "0"}}
                            />

                            <Form.Check // prettier-ignore
                                type='checkbox'
                                id={`owner`}
                                label={`Сопственик`}
                                name='owner'
                                value='Сопственик'
                                onChange={handleRoleChange}
                                style={{marginTop: "0"}}
                            />

                            <Form.Check // prettier-ignore
                                type='checkbox'
                                id={`doctor`}
                                label={`Доктор`}
                                name='doctor'
                                value='Доктор'
                                onChange={handleRoleChange}
                                style={{marginTop: "0"}}
                            />

                            <Form.Check // prettier-ignore
                                type='checkbox'
                                id={`employee`}
                                label={`Вработен`}
                                name='employee'
                                value='Вработен'
                                onChange={handleRoleChange}
                                style={{marginTop: "0"}}
                            />
                        </div>
                    <div className='button-wrapper'>
                        <button className='submit' type='submit'>Зачувај</button>
                        <button className='cancel' type='button' onClick={cancelRegister}>Откажи</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Register;