import React from 'react';
import { isAuthenticated } from '../../helpers/auth';
import { Navigate, Outlet } from 'react-router-dom';


const CustomRoute = ({isPrivate}) => {
    const routeElement = () => {
        if (isAuthenticated() && !isPrivate ) {
            return <Navigate to='/' />
        } else if (!isAuthenticated() && isPrivate) {
            return <Navigate to='/signin' />
        } else {
            return <Outlet />
        }
    }  
    return routeElement();      
}

export default CustomRoute;
