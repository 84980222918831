import React from 'react'
import { registerLocale } from  "react-datepicker";
import mk from 'date-fns/locale/mk';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

export default function CustomDatePicker({startDate, setStartDate, disabled = false}) {
  registerLocale('mk', mk);

  return (
    <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        locale="mk" 
        dateFormat="dd/MM/yyyy" 
        autoComplete="off"
        className="date-picker"
        disabled={disabled}
    />
  )
}
