import React from 'react'
import { Outlet } from 'react-router-dom'

export default function Patients() {

  return (
    <div className="d-flex flex-column">
      <Outlet />
    </div>
  )
}
