import React from 'react'

const ComponentToPrint = React.forwardRef(
    (props, ref) => {
        return (
          <>
          <div ref={ref}>
                {props.children}
          </div>
          </>
          
            
         
        )
      }
) 

export default ComponentToPrint;
