
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager} from 'react-notifications';
import config from '../../../config.json';
import Spinner from '../../SharedComponents/Spinner';
import CustomDatePicker from '../../SharedComponents/CustomDatePicker';
import {AiFillPlusCircle, AiFillCloseCircle} from 'react-icons/ai'
import { BsFillPrinterFill } from 'react-icons/bs'
import ExportPatientPdf from '../ExportPatientPdf';
import PaymentPdf from './PaymentPdf';


const EditPayment = () => {
    const navigate = useNavigate();
    const [payment, setPayment] = useState({});
    const [date, setDate] = useState(new Date()); 
    const [loading, setLoading] = useState(false);
    const [doctors, setDoctors] = useState();
    const [prices, setPrices] = useState();
    const [allPrices, setAllPrices] = useState();
    const [selectedServices, setSelectedServices] = useState([])
    const {id, paymentId} = useParams();
    const [print, setPrint] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [creationDate, setCreationDate] = useState();
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    
    useEffect(() => {
        getDoctors();
        
    }, [])

    const togglePrint = () => {
        setPrint(!print);
    }

    const getDoctors = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/doctors`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            setDoctors(res.data); 
            getPrices();
        })
        .catch(err => {
          if (err.response.status === 401) {
              navigate('/signin')
          }
          NotificationManager.error(err.response.data)
      })
        .finally(() => setLoading(false));

    }

    const getPrices = () => {
      setLoading(true);
      axios({
          method: 'get',
          url: `${config.SERVER_URL}/prices-all`,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('token')
          }
      })
      .then(res => {   
          if(typeof res.data === 'string') {
              NotificationManager.info(res.data)
          } else {
              const actualPrices = res.data.filter(p => p.deleted_on === null);
              setAllPrices(res.data); 
              setPrices(actualPrices);
              getPayment(res.data);
          }
          
      })
      .catch(err => {
        if (err.response.status === 401) {
            navigate('/signin')
        }
        NotificationManager.error(err.response.data)
    })
      .finally(() => setLoading(false));

  }

  const getPayment = (pricesResponse) => {
    setLoading(true);
      axios({
          method: 'get',
          url: `${config.SERVER_URL}/patients/${id}/payments/${paymentId}`,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('token')
          }
      })
      .then(res => {   
          const services = res.data.services.map(s => {
            s.price = pricesResponse.find(p => p.id == s.price_id)
            s.amount = Number(s.amount)
            return s;
          })
          setSelectedServices(services);
          const payment = {...res.data};
          payment.date_of_service = new Date(payment.date_of_service);
          payment.is_secondary = payment.is_secondary ? 1 : 0;
          delete payment.services; 
          setDate(new Date(res.data.date_of_service))
          setCreationDate(new Date(res.data.created_on));
          setPayment(payment);
          setIsPaid(payment.payment_rest < 1);
           
      })
      .catch(err => {
        if (err.response.status === 401) {
            navigate('/signin')
        }
        NotificationManager.error(err.response.data)
        navigate("../")
    })
      .finally(() => setLoading(false));
  }

    const editPayment = (e) => {
        e.preventDefault();
        
        if (payment.doctor_id == '0') {
          NotificationManager.error('Изберете доктор')
          return;
        }
        if (payment.is_secondary === '') {
          NotificationManager.error('Изберете систем')
          return;
        }
        if (selectedServices[0].price_id <= 0) {
          NotificationManager.error('Изберете услуга')
          return
        }
        setLoading(true)

        axios({
            method: 'put',
            url: `${config.SERVER_URL}/patients/${id}/payments/${paymentId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                ...payment,
                services: getServicesToAdd()
            }
        }).then(res => {
            NotificationManager.success('Плаќањето е успешно променето')
            setIsPaid(res.data.payment_rest < 1);
            setPayment({...res.data})
            const services = res.data.services.map(service => {
              service.price = prices.find(p => p.id == service.price_id)
              return service;
            })

            setSelectedServices(services)
        })
        .catch(err => {
          if (err.response.status === 401) {
              navigate('/signin')
          }
          NotificationManager.error(err.response.data)
      })
        .finally(() => setLoading(false));
    }

    const deletePayment = (e) => {
      e.preventDefault();
     
      setLoading(true)

      axios({
          method: 'delete',
          url: `${config.SERVER_URL}/patients/${id}/payments/${paymentId}`,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('token')
          }
      }).then(res => {
          NotificationManager.success('Плаќањето е успешно избришано')
          navigate('../payments');
      })
      .catch(err => {
        if (err.response.status === 401) {
            navigate('/signin')
        }
        NotificationManager.error(err.response.data)
    })
      .finally(() => setLoading(false));
  }

    const getServicesToAdd = () => {
      const servicesToAdd = selectedServices.filter(service => {
        if (service.price_id !== 0) {
          delete service.price;
          delete service.payment_id;
          delete service.id;
          return true;
        }
        return false;
      })

      return servicesToAdd;
    }

    const handleInputChange = (e) => {
      let { name, value} = e.target;
      if (name === 'is_secondary' && value !== '') {
        value = parseInt(value);
      }
        setPayment({
          ...payment,
          [name]: value,
        });
    }

    const handleDiscountChange = (index, e) => {
      if (selectedServices[index].price_id === 0) {
        return;
      }
        const {value} = e.target;
        const newSelectedServices = [...selectedServices];
        newSelectedServices[index] = {
          ...newSelectedServices[index],
          discount: parseInt(value, 10),
          amount: (newSelectedServices[index].price.value - (newSelectedServices[index].price.value * Number(value)/100)) * newSelectedServices[index].volume
        }
        setPaymentAmount(newSelectedServices)
        setSelectedServices([...newSelectedServices])
      }
  
      const handleServiceChange = (index, e) => {
        const price = prices.find(p => p.id == e.target.value)
        const newSelectedServices = [...selectedServices];
        newSelectedServices[index] = {
          ...newSelectedServices[index],
          price_id: price.id,
          price: price,
          volume: 1,
          discount: 0,
          amount: price.value
        }
        setPaymentAmount(newSelectedServices)
        setSelectedServices([...newSelectedServices])
      }

      const handleVolumeChange = (index, e) => {
        if (selectedServices[index].price_id === 0) {
          return;
        }
        let {value} = e.target;
        if (Number(value) < 1) {
          value = 1;
        }
        const newSelectedServices = [...selectedServices];
        newSelectedServices[index] = {
          ...newSelectedServices[index],
          volume: Number(value),
          amount: (newSelectedServices[index].price.value - (newSelectedServices[index].price.value * newSelectedServices[index].discount/100)) * Number(value)
        }
        setPaymentAmount(newSelectedServices)
        setSelectedServices([...newSelectedServices])
      }
  
      const setPaymentAmount = (services) => {
        const amount = services.reduce((total, current) => total + current.amount, 0);
        setPayment({
          ...payment,
          payment_amount: amount,
          payment_cash: 0,
          payment_card: 0,
          bank_transfer: 0,
          payment_rest: amount
        });
      }

    const cancelRegister = (e) => {
      e.preventDefault();
        navigate('../payments');
    }

    const setPaymentDate = (e) => {
        setPayment({
            ...payment,
            'date_of_service': e,
          });
          setDate(e);
    }

    const changePaidValue = (e) => {
     
      const { name, value} = e.target;
      let cash = isNaN(payment.payment_cash) ? 0 : Number(payment.payment_cash)
      let card = isNaN(payment.payment_card) ? 0 : Number(payment.payment_card)
      let bank = isNaN(payment.bank_transfer) ? 0 : Number(payment.bank_transfer)
      
      if (name === 'payment_cash') {
        cash = Number(value);
        setPayment({
          ...payment,
          'payment_cash': cash,
          'payment_rest': payment.payment_amount - (cash + card + bank)
        });
      }
      if (name === 'payment_card') {
        card = Number(value);
        setPayment({
          ...payment,
          'payment_card': card,
          'payment_rest': payment.payment_amount - (cash + card + bank)
        });
      }
      if (name === 'bank_transfer') {
        bank = Number(value);
        setPayment({
          ...payment,
          'bank_transfer': bank,
          'payment_rest': payment.payment_amount - (cash + card + bank)
        });
      }
    }

    const handlePlus = () => {
      if (isEmptyService()) {
        return;
      }
        const newSelectedServices = [...selectedServices]
        newSelectedServices.push({
            price_id: 0,
            discount: '',
            volume: '',
        })
        setSelectedServices([...newSelectedServices])
    }
  
    const handleMinus = (index) => {
      
        if (selectedServices[index]) {
            const newSelectedServices = [...selectedServices]
            newSelectedServices.splice(index, 1);
            setSelectedServices([...newSelectedServices])
            setPaymentAmount(newSelectedServices)
        }
        
    }

    const isEmptyService = () => {
      return selectedServices.findIndex(s => s.price_id === 0) !== -1;
    }

    const revertPayment = () => {
      if (payment.revert_id) {
        NotificationManager.info('Сметката е веќе сторнирана')
        return;
      }
      const returnPayment = {...payment};
      returnPayment.revert_id = payment.id;
      returnPayment.payment_amount = -returnPayment.payment_amount;
      returnPayment.payment_cash = returnPayment.payment_amount;
      returnPayment.payment_card = 0;
      returnPayment.bank_transfer = 0;
      returnPayment.payment_rest = 0;
      delete returnPayment.id;
      const returnServices = selectedServices.map(s => {
        const returnService = {...s};
        delete returnService.id;
        delete returnService.created_on;
        returnService.amount = -returnService.amount;
        returnService.price.value = -returnService.price.value
        return returnService;
      })
      navigate(`/patients/${id}/payments/add`, {state: {payment: returnPayment, selectedServices: returnServices}});

    }

    return (
        <>
        {print ? 
            <ExportPatientPdf togglePrint={togglePrint} showLangTemplate={false}>
                <PaymentPdf services={selectedServices} payment={payment} doctor={doctors.find(d => d.id == payment.doctor_id)}/>
            </ExportPatientPdf>
         : 
        <div className='background-wrapper_patient' style={{width: 'auto', height: 'auto',  backgroundColor: 'inherit'}}>
            {loading ? <Spinner /> : null}
                <form style={{width: '100%'}} onSubmit={editPayment}>
                    <div className='background-form-wrapper_patient justify-content-between'>
                        <h1 className='mt-3'>Креирај документ за наплата</h1>
                        <div className='input-wrapper me-0' style={{width: '15%'}}>
                            <label htmlFor="invoice_number">Број на фактура</label>
                            <input type='number' name='invoice_number' value={payment.invoice_number} onChange={handleInputChange} style={{width: '100%'}}/>
                        </div>
                        <fieldset className='background-form-wrapper_patient justify-content-between p-0' style={{border: 'none'}} disabled={isPaid}>
                        <div className='input-wrapper' style={{width: '28%', marginRight: '10%'}}>
                            <label htmlFor="date_of_service">Датум на услуга</label>
                            <CustomDatePicker name='date_of_service' startDate={date} setStartDate={setPaymentDate}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper' style={{width: '25%'}}>
                            <label htmlFor="doctor_id">Реализација на услуга</label>
                            <select name="doctor_id" value={payment.doctor_id} onChange={handleInputChange} >
                                {doctors && doctors.map(doctor => <option key={doctor.id} value={doctor.id}>{doctor.name_cyrilic}</option>)}
                            </select>
                        </div>

                        <div className='w-100 d-flex justify-content-between mt-5'>
                              <div style={{width: '50%', marginTop: '10px', marginRight: '0'}}>
                                  Услуга
                              </div>
                              <div style={{width: '6%', marginTop: '10px', marginRight: '0'}}>
                                Цена
                              </div>
                              <div style={{width: '6%', marginTop: '10px', marginRight: '0'}}>
                                Количина
                              </div>
                              <div style={{width: '6%', marginTop: '10px', marginRight: '0'}}>
                                  Попуст %
                              </div>
                              <div style={{width: '6%', marginTop: '10px', marginRight: '0'}}>
                                 Износ
                              </div>
                              <div style={{width: '10%'}}></div>
                            </div>


                        {selectedServices ? selectedServices.map((service, index) => {
                          return (
                            <div key={`price_${index}`} className='w-100 d-flex justify-content-between'>
                              <div className='input-wrapper' style={{width: '50%', marginTop: '5px', marginRight: '0'}}>
                                  <select name={`service_id_${index}`} value={service.price_id} onChange={(e) => handleServiceChange(index, e)} style={{width: '100%'}}>
                                    <option value='0'></option>
                                      {!isPaid && prices && prices.map(price => <option key={price.id} value={price.id}>{price.code} - {price.service}</option>)}
                                      {isPaid && allPrices && allPrices.map(price => <option key={price.id} value={price.id}>{price.code} - {price.service}</option>)}
                                  </select>
                              </div>
                              <div className='input-wrapper' style={{width: '6%', marginTop: '5px', marginRight: '0'}}>
                                <p className='view-form-field m-0' style={{width: '100%'}}>{service.price ? service.price.value + ' МКД' : ''}</p>
                                {/* <span className='error text-danger'>{cyrNameError}</span> */}
                              </div>
                              <div className='input-wrapper' style={{width: '6%', marginTop: '5px', marginRight: '0'}}>
                                <input type='number' className='text-center m-0' name={`volume_${index}`} value={service.volume} onChange={(e) => handleVolumeChange(index, e)} style={{width: '100%'}} />
                                {/* <span className='error text-danger'>{cyrNameError}</span> */}
                              </div>
                              <div className='input-wrapper' style={{width: '6%', marginTop: '5px', marginRight: '0'}}>
                                <input type='number' className='text-center m-0' name={`discount_${index}`} value={service.discount} onChange={(e) => handleDiscountChange(index, e)} style={{width: '100%', fontWeight: 'bold', textAlign: 'center'}} />
                                {/* <span className='error text-danger'>{cyrNameError}</span> */}
                              </div>
                              <div className='input-wrapper' style={{width: '6%', marginTop: '5px', marginRight: '0'}}>
                                  <p className='view-form-field m-0' style={{width: '100%'}}>{service.amount || service.amount === 0 ? service.amount + ' МКД' : ''}</p>
                                  {/* <span className='error text-danger'>{cyrNameError}</span> */}
                              </div>
                              <div className='plus-minus-btn text-center d-flex align-items-center' style={{width: '10%'}}>
                                  <button type='button' onClick={handlePlus} className='btn plus-btn'>
                                      <AiFillPlusCircle />
                                  </button>
                                  <button type='button' disabled={selectedServices.length < 2} onClick={() => handleMinus(index)} className='btn minus-btn'>
                                      <AiFillCloseCircle />
                                  </button>
                              </div>
                            </div>
                          )
                        }): null}

                       
                        {/* <div className='input-wrapper' style={{width: '100%', marginTop: '30px', marginRight: '0'}}>
                          <label htmlFor="payment_cash">Вкупно за враќање</label>
                          <p className='view-form-field m-0' style={{width: '10%'}}>{payment.payment_amount || payment.payment_amount === 0 ? payment.payment_amount + ' МКД' : ''}</p>
                        </div> */}
                          <div className='input-wrapper' style={{width: '100%', marginTop: '30px', marginRight: '0'}}>
                            <label htmlFor="payment_cash">Вкупно за наплата</label>
                            <p className='view-form-field m-0' style={{width: '10%'}}>{payment.payment_amount || payment.payment_amount === 0 ? payment.payment_amount + ' МКД' : ''}</p>
                          </div>

                          <div className='input-wrapper me-0' style={{width: '100%'}}>
                            <label htmlFor="is_secondary">Избери систем:</label>
                            <select name="is_secondary" value={payment.is_secondary} style={{width: '10%'}} onChange={handleInputChange}>
                                <option value=''></option>
                                <option value='0'>Ситем 1</option>
                                <option value='1'>Систем 2</option>
                            </select>
                        </div>

                        {payment.is_secondary === 0 ? 
                        
                          <>
                            <div className='input-wrapper' style={{width: '12%', marginTop: '30px', marginRight: '10%'}}>
                                <label htmlFor="payment_cash">Наплатен износ во готово</label>
                                <input type='text' name='payment_cash' value={payment.payment_cash} onChange={changePaidValue} style={{width: '100%'}}/>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className='input-wrapper' style={{width: '12%', marginTop: '30px', marginRight: '10%'}}>
                                <label htmlFor="payment_card">Наплатен износ со картичка</label>
                                <input type='text' name='payment_card' value={payment.payment_card} onChange={changePaidValue} style={{width: '100%'}}/>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className='input-wrapper' style={{width: '12%', marginTop: '30px', marginRight: '40%'}}>
                                <label htmlFor="bank_transfer">Наплатен износ преку банка</label>
                                <input type='text' name='bank_transfer' value={payment.bank_transfer} onChange={changePaidValue} style={{width: '100%'}}/>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className='input-wrapper' style={{width: '100%', marginTop: '30px', marginRight: '10%'}}>
                                <label htmlFor="payment_rest">Остаток</label>
                                <input type='text' name='payment_rest' value={payment.payment_rest} onChange={handleInputChange} style={{width: '15%'}} disabled/>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                          </>
                          : payment.is_secondary === 1 ?
                          <>
                            <div className='input-wrapper' style={{width: '12%', marginTop: '30px', marginRight: '10%'}}>
                                <label htmlFor="payment_cash">Наплатен износ во готово</label>
                                <input type='text' name='payment_cash' value={payment.payment_cash} onChange={changePaidValue} style={{width: '100%'}}/>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className='input-wrapper' style={{width: '100%', marginTop: '30px', marginRight: '10%'}}>
                                <label htmlFor="payment_rest">Остаток</label>
                                <input type='text' name='payment_rest' value={payment.payment_rest} onChange={handleInputChange} style={{width: '15%'}} disabled/>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                          </>
                          : null
                      }
                          </fieldset>          
                        
                        <div className='button-wrapper d-flex justify-content-between' style={{width: '100%'}}>
                         
                          <div className='d-flex justify-content-between' style={{width: '270px'}}>
                            <button className='submit' type='submit p-0'>Зачувај</button>
                            <button className='cancel' type='button p-0' onClick={cancelRegister}>Откажи</button>
                          </div>
                          
                          {isPaid ? (
                            <div className='d-flex justify-content-between' style={{width: '270px'}}>
                              <button className='print fs-4 p-0' type='button'  onClick={togglePrint}>
                                  <BsFillPrinterFill />
                              </button>
                              {payment.revert_id ? null : <button className='revert p-0' type='button' onClick={revertPayment}>Сторнирај</button>}
                            </div>
                          ) : null}

                          {creationDate && creationDate.getDate() === today.getDate() && creationDate.getMonth() === today.getMonth() && creationDate.getFullYear === today.getFullYear ? 
                            <div className='d-flex justify-content-end' style={{width: '270px'}}>
                              <button className='delete p-0' type='button' onClick={deletePayment}>
                                  Избриши
                              </button>
                            </div> 
                          : null}
                         
                      </div>
                    </div>
                </form>
                
        </div>
        }
        </>
    );
}

export default EditPayment;