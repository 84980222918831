import React, { useState } from 'react'
import { NotificationManager } from 'react-notifications';
import config from '../../config.json'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function AddPrice({setLoading, getPrices}) {
    const [code, setCode] = useState('');
    const [service, setService] = useState('');
    const [value, setValue] = useState('');
    const navigate = useNavigate();
    
    const addPrice = (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            return;
        }
        setLoading(true)
        axios({
            method: 'post',
            url: `${config.SERVER_URL}/prices/add`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                code: code,
                service: service,
                value: value
            }
        }).then(res => {
            NotificationManager.success('Успешно внесена услуга')
            resetForm();
            getPrices();
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const isFormValid = () => {

        return code.length > 0 && service.length > 0 && value.length > 0;
    }

    const handleInputChange = (e) => {
        const { name, value} = e.target;
        switch (name) {
            case 'code':
                setCode(value); 
                break;
            case 'service':
                setService(value);
                break;
            case 'value':
                setValue(value);
                break;
            default:
                break;
        }
    }

    const resetForm = () => {
        setCode('');
        setService('');
        setValue('');
    }

  return (
    <div className='background-form-wrapper p-2' style={{boxShadow: 'none'}}>
        <h1>Додади услуга</h1>
        <form onSubmit={addPrice}>
            <div className='input-wrapper' style={{width: '100%', marginTop: '30px'}}>
                <label htmlFor="code">Шифра</label>
                <input type='text' name='code' value={code} onChange={handleInputChange} style={{width: '100%'}}/>
                {/* <span className='error text-danger'>{cyrNameError}</span> */}
            </div>
            <div className='input-wrapper' style={{width: '100%', marginTop: '30px'}}>
                <label htmlFor="service">Услуга</label>
                <input type='text' name='service' value={service} onChange={handleInputChange} style={{width: '100%'}}/>
                {/* <span className='error text-danger'>{latNameError}</span> */}
            </div>
            <div className='input-wrapper' style={{width: '100%', marginTop: '30px'}}>
                <label htmlFor="value">Цена</label>
                <input type='text' name='value' value={value} onChange={handleInputChange} style={{width: '100%'}}/>
                {/* <span className='error text-danger'>{latNameError}</span> */}
            </div>
            <div className='button-wrapper'>
                <button className='submit' type='submit'>Зачувај</button>
            </div>
        </form>
    </div>
  )
}
