import React, { useState, useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import Spinner from '../components/SharedComponents/Spinner'
import Card from '../components/SharedComponents/Card'
import AddPrice from '../components/Prices/AddPrice'
import EditPrice from '../components/Prices/EditPrice'
import PriceList from '../components/Prices/PriceList'
import axios from 'axios'
import config from '../config.json'
import { useNavigate } from 'react-router-dom'

export default function Prices() {
    const [loading, setLoading] = useState(false)
    const [prices, setPrices] = useState([])
    const [isEditView, setIsEditView] = useState(false);
    const [priceToEdit, setPriceToEdit] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        getPrices();
    }, [])

    const getPrices = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/prices`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            if(typeof res.data === 'string') {
                NotificationManager.info(res.data)
            } else {
                setPrices(res.data); 
            }
            
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));

    }

    const handleEditPrice = (id) => {
        if (id > 0) {
            setPriceToEdit(prices.find(p => p.id === id));
            setIsEditView(true);
        } else {
            setPriceToEdit({});
            setIsEditView(false)
        }
        
    }
    
  return (
    <div className="d-flex flex-row justify-content-between" >
      {loading ? <Spinner /> : null}
        <div style={{width: '60%'}}>
            <Card>
                <PriceList prices={prices} handleEditPrice={handleEditPrice}/>
            </Card>
        </div>
        <div style={{width: '39%'}}>
            <Card>
            {isEditView ? 
               <EditPrice setLoading={setLoading} getPrices={getPrices} price={priceToEdit} handleEditPrice={handleEditPrice} /> :
               <AddPrice setLoading={setLoading} getPrices={getPrices}/> 
            }
            </Card>
        </div>
    </div>
  )
}
