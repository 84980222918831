import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import config from "../../../../config.json";
import Spinner from "../../../SharedComponents/Spinner";
import CustomDatePicker from "../../../SharedComponents/CustomDatePicker";
import { AiFillCloseCircle, AiFillPlusCircle } from "react-icons/ai";
import { templateDermatology, templateEndocrinology, templateNeurology } from "../../../../helpers/templates";

const AddSpecReview = () => {
    const navigate = useNavigate();
    const [specReview, setSpecReview] = useState({});
    const [date, setDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [doctors, setDoctors] = useState();
    const { id } = useParams();
    const [medications, setMedications] = useState([]);

    useEffect(() => {
        getDoctors();
        // getLastCardioExam();
        setSpecReview({
            exam_date: date,
        });
    }, []);

    const getDoctors = () => {
        setLoading(true);
        axios({
            method: "get",
            url: `${config.SERVER_URL}/doctors`,
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        })
            .then((res) => {
                setDoctors(res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate("/signin");
                }
                NotificationManager.error(err.response.data);
            })
            .finally(() => setLoading(false));
    };

    // const getLastCardioExam = () => {
    //     setLoading(true);
    //     axios({
    //         method: "get",
    //         url: `${config.SERVER_URL}/patients/${id}/exams/spec/new/last`,
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: localStorage.getItem("token"),
    //         },
    //     })
    //         .then((res) => {
    //             if (res.data.id) {
    //                 const therapies = res.data.therapy.map((t) => {
    //                     return {
    //                         medication: t.medication,
    //                         morning: t.morning,
    //                         lunch: t.lunch,
    //                         evening: t.evening,
    //                     };
    //                 });
    //                 setMedications(therapies);
    //                 delete res.data.therapy;
    //                 delete res.data.id;
    //                 delete res.data.patient_id;
    //                 delete res.data.exam_date;
    //                 setSpecReview({
    //                     exam_date: date,
    //                     ...res.data,
    //                 });
    //             } else {
    //                 setSpecReview({
    //                     doctor_id: 1,
    //                     exam_date: new Date(),
    //                 });
    //             }
    //         })
    //         .catch((err) => {
    //             if (err.response.status === 401) {
    //                 navigate("/signin");
    //             }
    //         })
    //         .finally(() => setLoading(false));
    // };

    const createSpecReview = (e) => {
        e.preventDefault();
        console.log(specReview.doctor_id);
        if (!specReview.doctor_id) {
            NotificationManager.error("Изберете доктор");
            return;
        }
        setLoading(true);

        const newSpecReview = { ...specReview };

        axios({
            method: "post",
            url: `${config.SERVER_URL}/patients/${id}/exams/spec/new/add`,
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
            data: {
                ...newSpecReview,
                therapy: medications,
            },
        })
            .then((res) => {
                NotificationManager.success("Извештајот е успешно креиран");
                navigate(`/patients/${id}/exams/spec/${res.data}`);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate("/signin");
                }
                NotificationManager.error(err.response.data);
            })
            .finally(() => setLoading(false));
    };

    const handleInputChange = (e) => {
        resetErrors();
        const { name, value } = e.target;
        setSpecReview({
            ...specReview,
            [name]: value,
        });
    };

    const handleTherapyChange = (index, e) => {
        resetErrors();
        const { name, value } = e.target;
        const newMedications = [...medications];
        newMedications[index] = { ...newMedications[index], [name.split("_")[0]]: value };
        setMedications([...newMedications]);
    };

    const cancelRegister = () => {
        resetErrors();
        navigate("../exams");
    };

    const resetErrors = () => {};

    const setExamDate = (e) => {
        setSpecReview({
            ...specReview,
            exam_date: e,
        });
        setDate(e);
    };

    const handlePlus = () => {
        const newMedications = [...medications];
        newMedications.push({ medication: "", morning: "", lunch: "", evening: "" });
        setMedications([...newMedications]);
    };

    const handleMinus = (index) => {
        if (medications[index]) {
            const newMedications = [...medications];
            newMedications.splice(index, 1);
            setMedications([...newMedications]);
        }
    };

    const getDermaTemplate = () => {
        setSpecReview({
            ...specReview,
            ...templateDermatology,
        });
    };

    const getEndoTemplate = () => {
        setSpecReview({
            ...specReview,
            ...templateEndocrinology,
        });
    };

    const getNeuroTemplate = () => {
        setSpecReview({
            ...specReview,
            ...templateNeurology,
        });
    };

    return (
        <>
            <div className="background-wrapper_patient" style={{ width: "auto", height: "auto", backgroundColor: "inherit" }}>
                {loading ? <Spinner /> : null}
                <form style={{ width: "100%" }} onSubmit={createSpecReview}>
                    <div className="background-form-wrapper_patient">
                        <h1 className="mt-3">Креирај специјалистички преглед</h1>
                        <div className="d-flex justify-content-end w-100">
                            <button className="btn fs-6 p-10" style={{ marginLeft: "10px", backgroundColor: "#39828f", color: "#fff" }} type="button" onClick={getNeuroTemplate}>
                                Темплејт невролог
                            </button>
                            <button className="btn fs-6 p-10" style={{ marginLeft: "10px", backgroundColor: "#39828f", color: "#fff" }} type="button" onClick={getDermaTemplate}>
                                Темплејт дерматолог
                            </button>
                            <button className="btn fs-6 p-10" style={{ marginLeft: "10px", backgroundColor: "#39828f", color: "#fff" }} type="button" onClick={getEndoTemplate}>
                                Темплејт ендокринолог
                            </button>
                        </div>
                        <div className="input-wrapper">
                            <label htmlFor="exam_date">Датум на преглед</label>
                            <CustomDatePicker name="exam_date" startDate={date} setStartDate={setExamDate} />
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className="input-wrapper">
                            <label htmlFor="doctor_id">Доктор</label>
                            <select name="doctor_id" onChange={handleInputChange}>
                                <option value="0">Изберете доктор...</option>
                                {doctors &&
                                    doctors.map((doctor) => (
                                        <option key={doctor.id} value={doctor.id}>
                                            {doctor.name_cyrilic}
                                        </option>
                                    ))}
                            </select>
                        </div>

                        <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                            <label htmlFor="anamnestic_data">Анамнестички податоци</label>
                            <textarea className="form-control" placeholder="Анамнестички податоци..." value={specReview.anamnestic_data} name="anamnestic_data" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>

                        <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                            <label htmlFor="anamnestic_data_al">Të dhënat anamnestike</label>
                            <textarea className="form-control" placeholder="Të dhënat anamnestike..." value={specReview.anamnestic_data_al} name="anamnestic_data_al" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>

                        <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                            <label htmlFor="objective_find">Објективен наод</label>
                            <textarea className="form-control" placeholder="Објективен наод..." value={specReview.objective_find} name="objective_find" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>

                        <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                            <label htmlFor="objective_find_al">Ekzaminimi objektiv</label>
                            <textarea className="form-control" placeholder="Ekzaminimi objektiv..." value={specReview.objective_find_al} name="objective_find_al" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>

                        <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                            <label htmlFor="investigation">Иследувања</label>
                            <textarea className="form-control" placeholder="Дополнителни иследувања..." value={specReview.investigation} name="investigation" style={{ height: "200px" }} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>

                        <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                            <label htmlFor="investigation_al">Ekzaminime</label>
                            <textarea className="form-control" placeholder="Ekzaminime plotësuese..." value={specReview.investigation_al} name="investigation_al" style={{ height: "200px" }} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>

                        <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                            <label htmlFor="diagnose">Дијагноза</label>
                            <textarea className="form-control" placeholder="Дијагноза..." value={specReview.diagnose} name="diagnose" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>

                        <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                            <label htmlFor="diagnose_al">Diagnoza</label>
                            <textarea className="form-control" placeholder="Diagnoza..." value={specReview.diagnose_al} name="diagnose_al" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>

                        <div className="mt-3" style={{ width: "90%", marginRight: "50px" }}>
                            <h5 htmlFor="echocardiography" style={{ color: "rgb(57, 130, 143)" }}>
                                Терапија
                            </h5>
                            <div className="d-flex justify-content-between input-group" style={{ border: "1px solid rgb(57, 130, 143)" }}>
                                <div style={{ width: "100%" }}>
                                    {medications.length > 0
                                        ? medications.map((item, index) => {
                                              return (
                                                  <div className="d-flex" key={`therapy_${index}`}>
                                                      <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Лек"
                                                          name={`medication_${index}`}
                                                          style={{ width: "42%", borderRadius: "0" }}
                                                          onChange={(e) => handleTherapyChange(index, e)}
                                                          value={item.medication}
                                                      />
                                                      <input
                                                          type="text"
                                                          className="form-control text-center"
                                                          placeholder="Утро"
                                                          name={`morning_${index}`}
                                                          style={{ width: "16%", borderRadius: "0" }}
                                                          onChange={(e) => handleTherapyChange(index, e)}
                                                          value={item.morning}
                                                      />
                                                      <input
                                                          type="text"
                                                          className="form-control text-center"
                                                          placeholder="Ручек"
                                                          name={`lunch_${index}`}
                                                          style={{ width: "16%", borderRadius: "0" }}
                                                          onChange={(e) => handleTherapyChange(index, e)}
                                                          value={item.lunch}
                                                      />
                                                      <input
                                                          type="text"
                                                          className="form-control text-center"
                                                          placeholder="Вечер"
                                                          name={`evening_${index}`}
                                                          style={{ width: "16%", borderRadius: "0" }}
                                                          onChange={(e) => handleTherapyChange(index, e)}
                                                          value={item.evening}
                                                      />
                                                      <div className="plus-minus-btn text-center" style={{ width: "10%" }}>
                                                          <button type="button" onClick={handlePlus} className="btn plus-btn">
                                                              <AiFillPlusCircle />
                                                          </button>
                                                          <button type="button" onClick={() => handleMinus(index)} className="btn minus-btn">
                                                              <AiFillCloseCircle />
                                                          </button>
                                                      </div>
                                                  </div>
                                              );
                                          })
                                        : null}
                                    {medications.length === 0 ? (
                                        <div className="d-flex align-items-center p-1">
                                            <div style={{ width: "50%" }}>Без терапија</div>
                                            <div className="plus-minus-btn d-flex justify-content-end" style={{ width: "50%" }}>
                                                <button type="button" onClick={handlePlus} className="btn plus-btn">
                                                    <AiFillPlusCircle />
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                            <label htmlFor="recommendation">Препорака</label>
                            <textarea className="form-control" placeholder="Препорака..." value={specReview.recommendation} name="recommendation" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>

                        <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                            <label htmlFor="recommendation_al">Rekomandim</label>
                            <textarea className="form-control" placeholder="Rekomandim..." value={specReview.recommendation_al} name="recommendation_al" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>

                        <div className="button-wrapper">
                            <button className="submit me-2" type="submit">
                                Креирај
                            </button>
                            <button className="cancel" type="button" onClick={cancelRegister}>
                                Откажи
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddSpecReview;
