import React, { useEffect, useState } from 'react'
import { FaUserEdit } from 'react-icons/fa'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Card from '../../../SharedComponents/Card'
import Spinner from '../../../SharedComponents/Spinner'
import axios from 'axios'
import config from '../../../../config.json';
import { NotificationManager} from 'react-notifications';

export default function OtherExamsList() {
    const [loading, setLoading] = useState(false)
    const {id} = useParams();
    const [exams, setExams] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getOtherExams();
    }, [])

    const getOtherExams = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/patients/${id}/exams/other`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
        })
        .then(res => {   
            if (typeof(res.data) === "string") {
                // NotificationManager.info(res.data)
            } else {
                setExams(res.data); 
            }
            
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }


  return (
    <>
        {loading ? <Spinner /> : null}
        <Card title={'Стрес тест / Холтер'}>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Датум на преглед</th>
                        <th scope="col">Доктор</th>
                        <th scope="col">Извршена улуга</th>
                        <th scope="col" style={{width: '165px', paddingTop: '0'}}>
                            <Link to='other-exams/add' type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}}>Креирај преглед</Link>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {exams.map((exam, index) => {
                        const examDate = new Date(exam.exam_date);
                        return (
                            <tr key={exam.id}>
                                <th>{index + 1}</th>
                                <td>{`${examDate.getDate()}/${examDate.getMonth() + 1 < 10 ? '0' + (examDate.getMonth() + 1) : (examDate.getMonth() + 1) }/${examDate.getFullYear()}`}</td>
                                <td>{exam.name_cyrilic ? exam.name_cyrilic : exam.name_latin }</td>
                                <td>{exam.stress_test ? <p>Стрес тест</p> : null } {exam.holter_ekg ? <p>Холтер ЕКГ</p> : null } {exam.holter_abp ? <p>Холтер АБП</p> : null }</td>
                               
                                <td style={{width: '50px', paddingTop: '0'}}>
                                    <Link to={`other-exams/${exam.id}`} type="button" className="btn">
                                        <FaUserEdit style={{color: '#39828f'}} />
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                    
                </tbody>
            </table>
        </Card>
        </>
  )
}
