import React, { useEffect, useState } from 'react'
import Card from '../SharedComponents/Card';
import { Link, useNavigate } from 'react-router-dom';
import {FaUserEdit} from 'react-icons/fa'
import axios from 'axios';
import config from '../../config.json';
import {NotificationManager} from 'react-notifications';
import Spinner from "../../components/SharedComponents/Spinner";
import { FaSearch, FaAngleDoubleLeft, FaAngleLeft, FaAngleRight } from 'react-icons/fa'

export default function PatientsList() {
    const [patients, setPatients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [skip, setSkip] = useState(0)
    const navigate = useNavigate();
    const take = 20;

    useEffect(() => {
        getPatients(searchTerm, skip);
    }, [])

    const getPatients = (term, skip) => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/patients?searchTerm=${term}&skip=${skip}&take=${take}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
        })
        .then(res => {   
            setPatients(res.data); 
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));

    }

    const handleSearch = (e) => {
        let searchTerm = e.target.value;
        if (hasNumber(e.target.value)) {
            searchTerm = parseInt(e.target.value.replace( /^\D+/g, ''));
        }
        setSearchTerm(searchTerm);
        setSkip(0)
        getPatients(searchTerm, 0);
    }

    const hasNumber = (value) => {
        return /\d/.test(value);
      }

      const goToNextPage = (e) => {
        e.preventDefault();
        setSkip(skip + take)
        getPatients(searchTerm, skip+take);
      }

      const goToPreviousPage = (e) => {
        e.preventDefault();
        setSkip(skip - take)
        getPatients(searchTerm, skip-take);
      }

      const handleRowClick = (patient) => {
        navigate(`${patient.id}`, {state: {patient}})
      }

      const goToFirstPage = (e) => {
        e.preventDefault()
        setSkip(0)
        getPatients(searchTerm, 0);
      }

    return (
        <>
        {loading ? <Spinner /> : null}
        <div className='d-flex justify-content-between'>
            <div style={{width: '350px'}}>
                <div className="input-group">
                    <input type="text" value={searchTerm} onChange={handleSearch} className="form-control" placeholder='Пронајди пациент...' />
                    <span className="input-group-text" style={{backgroundColor: '#fff'}}>
                        <FaSearch />
                    </span>
                </div>
            </div>
            <Link to='add' type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}}>Креирај пациент</Link>
        </div>
        
        <Card className='pt-0 pb-0'>
            <table className="table table-hover align-middle mb-0 table-sm">
                <thead>
                    <tr>
                    <th scope="col">Број на досие</th>
                    <th scope="col">Име и презиме</th>
                    <th scope="col">Датум на раѓање</th>
                    <th scope="col">Град</th>
                    <th scope="col">Држава</th>
                    <th scope="col">Телефон</th>
                    <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {patients.map((patient, index) => {
                        const dob = patient.date_of_birth ? new Date(patient.date_of_birth) : null;
                        return (
                            <tr key={patient.id} style={{cursor: 'pointer', fontSize: '15px', lineHeight: '24px'}} onClick={() => handleRowClick(patient)}>
                                <td>CV{patient.id}</td>
                                <td>{patient.name_cyrilic ? patient.name_cyrilic : patient.name_latin }</td>
                                {dob ? 
                                <td>{`${dob.getDate() < 10 ? '0' + dob.getDate() : dob.getDate()}/${dob.getMonth() + 1 < 10 ? '0' + (dob.getMonth() + 1) : (dob.getMonth() + 1) }/${dob.getFullYear()}`}</td> : <td></td>
                                }
                                
                                <td>{patient.city_cyrilic ? patient.city_cyrilic : patient.city_latin}</td>
                                <td>{patient.country_cyrilic ? patient.country_cyrilic : patient.country_latin}</td>
                                <td>{patient.phone}</td>
                                <td style={{width: '50px', paddingTop: '0'}}>
                                        <FaUserEdit style={{color: '#39828f'}} />
                                </td>
                            </tr>
                        )
                    })}
                    
                </tbody>
            </table>
        </Card>
        <div className='w-100 d-flex justify-content-end mb-3 pagination'>
            <button type='button' className='page-btn' onClick={goToFirstPage} disabled={skip === 0}>
                <FaAngleDoubleLeft />
            </button>
            <button type='button' className='page-btn ms-2' onClick={goToPreviousPage} disabled={skip === 0}>
                <FaAngleLeft />
            </button>

            <span className='m-2'>Страна {skip / take + 1}</span>

            <button type='button' className='page-btn' onClick={goToNextPage} disabled={patients.length < take}>
                <FaAngleRight />
            </button>
        </div>
        </>
    )
}
