import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import config from "../../../../config.json";
import Spinner from "../../../SharedComponents/Spinner";
import { BsFillPrinterFill } from "react-icons/bs";
import { AiFillCloseCircle, AiFillPlusCircle } from "react-icons/ai";

import CustomDatePicker from "../../../SharedComponents/CustomDatePicker";
import ExportPatientPdf from "../../ExportPatientPdf";
import SpecReviewPdf from "./SpecReviewPdf";

const EditSpecReview = () => {
    const navigate = useNavigate();
    const [specReview, setSpecReview] = useState({});
    const [date, setDate] = useState();
    const [loading, setLoading] = useState(false);
    const [print, setPrint] = useState(false);
    const [doctors, setDoctors] = useState();
    const { id, examId } = useParams();
    const [medications, setMedications] = useState([]);

    const togglePrint = () => {
        setPrint(!print);
    };

    useEffect(() => {
        getDoctors();
    }, []);

    const getDoctors = () => {
        setLoading(true);
        axios({
            method: "get",
            url: `${config.SERVER_URL}/doctors`,
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        })
            .then((res) => {
                setDoctors(res.data);
                getExam(examId);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate("/signin");
                }
                NotificationManager.error(err.response.data);
            })
            .finally(() => setLoading(false));
    };

    const getExam = (examId) => {
        setLoading(true);
        axios({
            method: "get",
            url: `${config.SERVER_URL}/patients/${id}/exams/spec/new/${examId}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        })
            .then((res) => {
                setMedications(res.data.therapy);
                delete res.data.therapy;
                setSpecReview(res.data);
                setDate(new Date(res.data.exam_date));
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate("/signin");
                }
                NotificationManager.error(err.response.data);
                navigate("..");
            })
            .finally(() => setLoading(false));
    };

    const editExam = (e) => {
        e.preventDefault();
        // if (!isFormValid()) {
        //     return;
        // }
        setLoading(true);
        const therapyToSave = [];
        medications.map((t) => {
            if (t.medication && t.medication !== "") {
                therapyToSave.push(t);
            }
        });
        axios({
            method: "put",
            url: `${config.SERVER_URL}/patients/${id}/exams/spec/new/${examId}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
            data: {
                ...specReview,
                therapy: therapyToSave,
            },
        })
            .then((res) => {
                const data = res.data;
                setSpecReview(data);
                setMedications(data.therapy);
                setDate(new Date(data.exam_date));
                NotificationManager.success("Извештајот е успешно променет");
                // navigate(`/patients/${id}/exams/cardio/${res.data.exam_id}`);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate("/signin");
                }
                NotificationManager.error(err.response.data);
            })
            .finally(() => setLoading(false));
    };

    const handleInputChange = (e) => {
        resetErrors();
        const { name, value } = e.target;
        setSpecReview({
            ...specReview,
            [name]: value,
        });
    };

    const handleTherapyChange = (index, e) => {
        resetErrors();
        const { name, value } = e.target;
        const newMedications = [...medications];
        newMedications[index] = { ...newMedications[index], [name.split("_")[0]]: value };
        setMedications([...newMedications]);
    };

    const resetErrors = () => {};

    const setExamDate = (e) => {
        setSpecReview({
            ...specReview,
            exam_date: e,
        });
        setDate(e);
    };

    const handlePlus = () => {
        const newMedications = [...medications];
        newMedications.push({ medication: "", morning: "", lunch: "", evening: "" });
        setMedications([...newMedications]);
    };

    const handleMinus = (index) => {
        if (medications[index]) {
            const newMedications = [...medications];
            newMedications.splice(index, 1);
            setMedications([...newMedications]);
        }
    };

    return (
        <>
            {print ? (
                <ExportPatientPdf togglePrint={togglePrint} showLangTemplate={true}>
                    <SpecReviewPdf medications={medications} specReview={specReview} doctor={doctors.find((d) => d.id == specReview.doctor_id)} />
                </ExportPatientPdf>
            ) : (
                <div className="background-wrapper_patient" style={{ width: "auto", height: "auto", backgroundColor: "inherit" }}>
                    {loading ? <Spinner /> : null}
                    <form style={{ width: "100%" }} onSubmit={editExam}>
                        <div className="background-form-wrapper_patient">
                            <h1 className="mt-3">Специјалистички преглед</h1>
                            <div className="input-wrapper">
                                <label htmlFor="exam_date">Датум на преглед</label>
                                <CustomDatePicker name="exam_date" startDate={date} setStartDate={setExamDate} />
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="doctor_id">Доктор</label>
                                <select name="doctor_id" value={specReview.doctor_id} onChange={handleInputChange}>
                                    {doctors &&
                                        doctors.map((doctor) => (
                                            <option key={doctor.id} value={doctor.id}>
                                                {doctor.name_cyrilic}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                                <label htmlFor="anamnestic_data">Анамнестички податоци</label>
                                <textarea className="form-control" value={specReview.anamnestic_data} placeholder="Анамнестички податоци..." name="anamnestic_data" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                                <label htmlFor="anamnestic_data_al">Të dhënat anamnestike</label>
                                <textarea className="form-control" value={specReview.anamnestic_data_al} placeholder="Të dhënat anamnestike..." name="anamnestic_data_al" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                                <label htmlFor="objective_find">Објективен наод</label>
                                <textarea className="form-control" value={specReview.objective_find} placeholder="Објективен наод..." name="objective_find" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                                <label htmlFor="objective_find_al">Ekzaminimi objektiv</label>
                                <textarea className="form-control" value={specReview.objective_find_al} placeholder="Ekzaminimi objektiv..." name="objective_find_al" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                                <label htmlFor="investigation">Иследувања</label>
                                <textarea className="form-control" value={specReview.investigation} placeholder="Иследувања..." name="investigation" style={{ height: "200px" }} onChange={handleInputChange}></textarea>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                                <label htmlFor="investigation_al">Ekzaminime</label>
                                <textarea className="form-control" value={specReview.investigation_al} placeholder="Ekzaminime..." name="investigation_al" style={{ height: "200px" }} onChange={handleInputChange}></textarea>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                                <label htmlFor="diagnose">Дијагноза</label>
                                <textarea className="form-control" value={specReview.diagnose} placeholder="Дијагноза..." name="diagnose" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                                <label htmlFor="diagnose_al">Diagnoza</label>
                                <textarea className="form-control" value={specReview.diagnose_al} placeholder="Diagnoza..." name="diagnose_al" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            {/* <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                                <label htmlFor="therapy">Терапија</label>
                                <textarea className="form-control" value={specReview.therapy} placeholder="Терапија..." name="therapy" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                            </div>
                            <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                                <label htmlFor="therapy_al">Terapia</label>
                                <textarea className="form-control" value={specReview.therapy_al} placeholder="Terapia..." name="therapy_al" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                            </div> */}
                            <div className="mt-3" style={{ width: "90%", marginRight: "50px" }}>
                                <h5 htmlFor="echocardiography" style={{ color: "rgb(57, 130, 143)" }}>
                                    Терапија
                                </h5>
                                <div className="d-flex justify-content-between input-group" style={{ border: "1px solid rgb(57, 130, 143)" }}>
                                    <div style={{ width: "100%" }}>
                                        {medications.length > 0 ? (
                                            medications.map((item, index) => {
                                                return (
                                                    <div className="d-flex" key={`therapy_${index}`}>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Лек"
                                                            name={`medication_${index}`}
                                                            style={{ width: "42%", borderRadius: "0" }}
                                                            onChange={(e) => handleTherapyChange(index, e)}
                                                            value={item.medication}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control text-center"
                                                            placeholder="Утро"
                                                            name={`morning_${index}`}
                                                            style={{ width: "16%", borderRadius: "0" }}
                                                            onChange={(e) => handleTherapyChange(index, e)}
                                                            value={item.morning}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control text-center"
                                                            placeholder="Ручек"
                                                            name={`lunch_${index}`}
                                                            style={{ width: "16%", borderRadius: "0" }}
                                                            onChange={(e) => handleTherapyChange(index, e)}
                                                            value={item.lunch}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control text-center"
                                                            placeholder="Вечер"
                                                            name={`evening_${index}`}
                                                            style={{ width: "16%", borderRadius: "0" }}
                                                            onChange={(e) => handleTherapyChange(index, e)}
                                                            value={item.evening}
                                                        />
                                                        <div className="plus-minus-btn text-center" style={{ width: "10%" }}>
                                                            <button type="button" onClick={handlePlus} className="btn plus-btn">
                                                                <AiFillPlusCircle />
                                                            </button>
                                                            <button type="button" onClick={() => handleMinus(index)} className="btn minus-btn">
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="d-flex align-items-center p-1">
                                                <div style={{ width: "50%" }}>Без терапија</div>
                                                <div className="plus-minus-btn d-flex justify-content-end" style={{ width: "50%" }}>
                                                    <button type="button" onClick={handlePlus} className="btn plus-btn">
                                                        <AiFillPlusCircle />
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                                <label htmlFor="recommendation">Препорака</label>
                                <textarea className="form-control" value={specReview.recommendation} placeholder="Препорака..." name="recommendation" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className="input-wrapper" style={{ marginRight: "10%", width: "40%" }}>
                                <label htmlFor="recommendation_al">Rekomandim</label>
                                <textarea className="form-control" value={specReview.recommendation_al} placeholder="Rekomandim..." name="recommendation_al" style={{ height: "110px" }} onChange={handleInputChange}></textarea>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className="button-wrapper d-flex w-100">
                                <button className="submit" type="submit">
                                    Промени
                                </button>
                                <button className="print fs-4 p-0" style={{ marginLeft: "10px" }} type="button" onClick={togglePrint}>
                                    <BsFillPrinterFill />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

export default EditSpecReview;
