import React from 'react'
import AppointmentsTable from './AppointmentsTable';
import {FaEdit} from 'react-icons/fa'

export default function WorkingHoursTable({workingHours, header, handleShowScheduleModal, room, handleShow}) {
  return (
    <div>
        <div>
            <div>
                <div style={{fontSize: '18px', textAlign: 'center', borderBottom: '1px solid #ddd', display: 'flex', marginTop: '-15px', paddingBottom: '10px'}}>
                
                    <b className='w-50 text-start d-flex align-items-center'>{header}</b>
                    <span className='w-50 text-end'>
                        <button className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}} onClick={() => handleShowScheduleModal(room)}>Додади доктор</button>
                    </span>
                </div>
                {workingHours.map(wh => {
                   
                    return (
                        <div key={wh.id} style={{border: '1px solid black'}}>
                            <div style={{fontSize: '16px', textAlign: 'center', borderBottom: '1px solid #ddd', backgroundColor: "#39828f", color: "#fff", padding: '5px 0', position: 'relative'}}>
                                <b>Др.{wh.doctor.name_cyrilic}</b>
                                <FaEdit onClick={() => handleShowScheduleModal(room, wh)} style={{position: 'absolute', right: '10px', fontSize: '18px', cursor: 'pointer'}}/>
                            </div>
                            <div style={{fontSize: '16px', textAlign: 'center', borderBottom: '1px solid #ddd'}}>
                                <AppointmentsTable workTime={wh} appointments={wh.appointments} handleShow={handleShow}  />
                            </div>
                        </div>
                    )
                })

                }
            </div>
            
        </div>
        
    </div>
  )
}
