export const getCyrilicNameError = (name, isRequired) => {
    if(!name && isRequired) {
        return 'Полето е задолжително';
    } else if (!/^[аАбБвВгГдДѓЃеЕжЖзЗѕЅиИјЈкКлЛљЉмМнНњЊоОпПрРсСтТќЌуУфФхХцЦчЧџЏшШ\s]*$/.test(name)) {
        return 'Дозволено е само кирилица';
    } 
    return '';
}

export const getNameError = (name, isRequred) => {
    if(!name && isRequred) {
        return 'Полето е задолжително';
    } else if (!/^[a-zA-Z\s]*$/.test(name)) {
        return 'Дозволено е само латиница';
    } 
    return '';
}

export const getUsernameError = (name) => {
    if(!name) {
        return 'Полето е задолжително';
    } else if (!/^[a-zA-Z.]*$/.test(name)) {
        return 'Дозволено е само латиница и "."';
    } 
    return '';
}

export const getEmailError = (email, isRequired) => {
    if(!email && isRequired) {
        return 'Полето е задолжително';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        return 'Невалиден e-mail';
    }
    return '';
}

export const getPasswordError = (password, isRequired) => {
    if(!password && isRequired) {
        return 'Полето е задолжително';
    } 
    return '';
}
