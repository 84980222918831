import React, { useEffect, useState } from 'react'
import { FaUserEdit } from 'react-icons/fa'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Card from '../../SharedComponents/Card'
import Spinner from '../../SharedComponents/Spinner'
import axios from 'axios'
import config from '../../../config.json';
import { NotificationManager} from 'react-notifications';

export default function PaymentsList() {
    const [loading, setLoading] = useState(false)
    const {id} = useParams();
    const [payments, setPayments] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getPayments();
    }, [])

    const getPayments = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/patients/${id}/payments`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
        })
        .then(res => {   
            if (typeof(res.data) === "string") {
                NotificationManager.info(res.data)
            } else {
                setPayments(res.data); 
            }
            
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }


  return (
    <>
        {loading ? <Spinner /> : null}
        <Card title={'Плаќања'}>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Датум на услуга</th>
                        <th scope="col">Услуга</th>
                        <th scope="col">Наплатен износ</th>
                        <th scope="col" style={{width: '10px', paddingTop: '0'}}>
                            <Link to='add' type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}}>+</Link>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {payments.map((payment, index) => {
                        const paymentDate = new Date(payment.date_of_service);
                        return (
                            <tr key={payment.id}>
                                <td>{`${paymentDate.getDate()}/${paymentDate.getMonth() + 1 < 10 ? '0' + (paymentDate.getMonth() + 1) : (paymentDate.getMonth() + 1) }/${paymentDate.getFullYear()}`}</td>
                                <td style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden'}}>{payment.service }</td>
                                <td style={{textAlign: 'center'}}><span className={payment.payment_rest < 1 ? '' : 'text-danger'}>{payment.payment_amount }</span></td>
                                
                                <td style={{width: '50px', paddingTop: '0'}}>
                                    <Link to={`${payment.id}`} type="button" className="btn">
                                        <FaUserEdit style={{color: '#39828f'}} />
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                    
                </tbody>
            </table>
        </Card>
        </>
  )
}
