import jwt from 'jwt-decode';

export const isAuthenticated = () => {
    const token = localStorage.getItem('token'); 
    if (token) {
        const data = jwt(token);
        if(data.exp * 1000 <= Date.now() ) {
            localStorage.removeItem('token');
            return false;
        } else {
            return true;
        }
        
    } else {
        return false;
    }
}

export const getTokenPayload = () => {
    const token = localStorage.getItem('token'); 
    if (token) {
        return jwt(token);
    }
    return null;  
}