
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager} from 'react-notifications';
import config from '../../../../config.json';
import Spinner from '../../../SharedComponents/Spinner';
import { BsFillPrinterFill } from 'react-icons/bs'

import CustomDatePicker from '../../../SharedComponents/CustomDatePicker';
import ExportPatientPdf from '../../ExportPatientPdf';
import ConsultationPdf from './ConsultationPdf';


const EditConsultation = () => {
    const navigate = useNavigate();
    const [consultation, setConsultation] = useState({});
    const [date, setDate] = useState(); 
    const [loading, setLoading] = useState(false);
    const [print, setPrint] = useState(false);
    const [doctors, setDoctors] = useState();
    const {id, examId} = useParams();
    

    const togglePrint = () => {
        setPrint(!print);
    }

    useEffect(() => {
        getDoctors();
    }, [])

    const getDoctors = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/doctors`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            setDoctors(res.data); 
            getConsultation(examId)
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));

    }

    const getConsultation = (examId) => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/patients/${id}/exams/consultations/${examId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            setConsultation(res.data); 
            setDate(new Date(res.data.exam_date))
            
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
            navigate('..')
        })
        .finally(() => setLoading(false));
    }
    
        

    const editConsultation = (e) => {
        e.preventDefault();
        // if (!isFormValid()) {
        //     return;
        // }
        setLoading(true)
        axios({
            method: 'put',
            url: `${config.SERVER_URL}/patients/${id}/exams/consultations/${examId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                ...consultation
            }
        }).then(res => {
            const data = res.data[0]
            setConsultation(data); 
            setDate(new Date(data.exam_date))
            NotificationManager.success('Извештајот е успешно променет')
            // navigate(`/patients/${id}/exams/cardio/${res.data.exam_id}`);
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const handleInputChange = (e) => {
        resetErrors();
        const { name, value} = e.target;
        setConsultation({
            ...consultation,
            [name]: value,
          });
    }

    // const handleSelectChange = (e) => {
    //     setRole(e.target.value);
    // }

    


    const resetErrors = () => {
        
        
    }

    const setExamDate = (e) => {
        setConsultation({
            ...consultation,
            'exam_date': e,
          });
          setDate(e);
    }

    return (
        <>
        {print ? 
            <ExportPatientPdf togglePrint={togglePrint} showLangTemplate={true}>
                <ConsultationPdf consultation={consultation} doctor={doctors.find(d => d.id == consultation.doctor_id)}/>
            </ExportPatientPdf>
         : <div className='background-wrapper_patient' style={{width: 'auto', height: 'auto',  backgroundColor: 'inherit'}}>
            {loading ? <Spinner /> : null}
                <form style={{width: '100%'}} onSubmit={editConsultation}>
                    <div className='background-form-wrapper_patient'>
                        <h1 className='mt-3'>Лекарска препорака</h1>
                        <div className='input-wrapper'>
                            <label htmlFor="exam_date">Датум на преглед</label>
                            <CustomDatePicker name='exam_date' startDate={date} setStartDate={setExamDate} />
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="doctor_id">Доктор</label>
                            <select name="doctor_id" value={consultation.doctor_id} onChange={handleInputChange}>
                                {doctors && doctors.map(doctor => <option key={doctor.id} value={doctor.id}>{doctor.name_cyrilic}</option>)}
                            </select>
                        </div>


                        <div className='input-wrapper' style={{marginRight: '10%', width: '40%'}}>
                            <label htmlFor="consult_motive">Мотив за консултација</label>
                            <textarea className="form-control" value={consultation.consult_motive} placeholder="Мотив за консултација..." name="consult_motive" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper' style={{marginRight: '10%', width: '40%'}}>
                            <label htmlFor="consult_motive_al">Motivet e konsultës</label>
                            <textarea className="form-control" value={consultation.consult_motive_al} placeholder="Motivet e konsultës..." name="consult_motive_al" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        
                        <div className='input-wrapper' style={{marginRight: '10%', width: '40%'}}>
                            <label htmlFor="recommendation">Препорака</label>
                            <textarea className="form-control" value={consultation.recommendation} placeholder="Препорака..." name="recommendation" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper' style={{marginRight: '10%', width: '40%'}}>
                            <label htmlFor="recommendation_al">Rekomandim</label>
                            <textarea className="form-control" value={consultation.recommendation_al} placeholder="Rekomandim..." name="recommendation_al" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>

                        <div className='button-wrapper d-flex w-100'>
                            <button className='submit' type='submit'>Промени</button>
                            <button className='print fs-4 p-0' style={{marginLeft: '10px'}} type='button'  onClick={togglePrint}>
                                <BsFillPrinterFill />
                            </button>
                        </div>
                    </div>
                </form>
        </div>
        }
        </>
    );
}

export default EditConsultation;