import React, { useEffect, useState } from 'react'
import Spinner from '../SharedComponents/Spinner';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'
import config from '../../config.json';
import { NotificationManager} from 'react-notifications';
import CustomDatePicker from '../SharedComponents/CustomDatePicker';

export default function StatisticsTab() {
    const {fromDate, toDate} = useParams();
    const [loading, setLoading] = useState(false)
    const [payments, setPayments] = useState([]);
    const [services, setServices] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctorId, setSelectedDoctorId] = useState(0);
    const [startDate, setStartDate] = useState(fromDate ? new Date(fromDate) : new Date());
    const [endDate, setEndDate] = useState(toDate ? new Date(toDate) : new Date());
    const navigate = useNavigate();

    useEffect(() => {
        getDoctors();
    }, [])


    const getDoctors = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/doctors`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            setDoctors(res.data); 
            getPayments(startDate, endDate, selectedDoctorId);
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));

    }

    const getPayments = (startDate, endDate, doctorId) => {
        setLoading(true);
        const from = `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`;
        const to = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`;
        axios({
            method: 'post',
            url: `${config.SERVER_URL}/finance/payments`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                filter: {
                    startDateString: from,
                    endDateString: to,
                    doctorId: doctorId
                }
            }
        })
        .then(res => {   
            if (typeof(res.data) === "string") {
                NotificationManager.info(res.data)
            } else {
                setPayments(res.data); 
                setServices(res.data.map(p => p.services).flat(1))
            }
            
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const handleStartDateChange = (date) => {
        setStartDate(date);
        getPayments(date, endDate, selectedDoctorId);
    }

    const handleEndDateChange = (date) => {
        setEndDate(date);
        getPayments(startDate, date, selectedDoctorId);
    }

    const handleInputChange = (e) => {
        setSelectedDoctorId(e.target.value);
        getPayments(startDate, endDate, e.target.value)
    }

    const currencyFormat = (num) => {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' МКД'
     }

    const groupByKey = (list, key, {omitKey=false}) => list.reduce((hash, {[key]:value, ...rest}) => ({...hash, [value]:( hash[value] || [] ).concat(omitKey ? {...rest} : {[key]:value, ...rest})} ), {})

    return (
        <>
        
        <div className='background-wrapper_patient' style={{width: 'auto', height: 'auto',  backgroundColor: '#fff'}}>
            {loading ? <Spinner /> : null}
            {doctors.length > 0 && 
            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: '50%', flexWrap: 'wrap'}}>
                    <p style={{width: '100%', margin: '0px 10px'}}>Извештај за период:</p>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap'}}>
                        <div className="input-group d-flex" style={{width: 'auto', flexWrap: 'nowrap', margin: '10px'}}>
                            <CustomDatePicker type="text" startDate={startDate} setStartDate={handleStartDateChange}/>
                        </div>
                        <div className="input-group d-flex" style={{width: 'auto', flexWrap: 'nowrap', margin: '10px'}}>
                            <CustomDatePicker type="text" startDate={endDate} setStartDate={handleEndDateChange}/>
                        </div>
                        
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '50%', flexWrap: 'wrap'}}>
                    <p style={{width: '100%', margin: '0px 10px'}}>Изберете доктор:</p>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap'}}>
                        <div className="input-group d-flex" style={{width: 'auto', flexWrap: 'nowrap', margin: '10px'}}>
                            <select name="doctor_id" onChange={handleInputChange} style={{padding: '3px', width: '380px'}}>
                                <option key={0} value={0}>Сите доктори</option>
                                {doctors && doctors.map(doctor => <option key={doctor.id} value={doctor.id}>{doctor.name_cyrilic}</option>)}
                            </select>
                        </div>
                       
                        
                    </div>
                </div>
                <table className="table" style={{width: '100%'}}>
                    <thead>
                        <tr>
                            <th scope="col">Услуга</th>
                            <th scope="col">Број на услуги</th>
                            <th scope="col" className='text-end' style={{paddingRight: '25px'}}>Вкупен промет</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {Object.entries(groupByKey(services, 'service', {omitKey:true})).sort((a,b) => (a[0] > b[0]) ? 1 : ((b[0] > a[0]) ? -1 : 0)).map((payment, index) => {
                            
                        const totalPayment = currencyFormat(payment[1].reduce((acc, curr) => {
                                return acc + curr.amount;
                            }, 0))
                           
                            return (
                                <tr key={payment[0] + "_" + index}>
                                    <td>{payment[0]}</td>
                                    <td className='text-center'>{payment[1].length}</td>
                                    <td className='text-end' style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden', paddingRight: '25px'}}>{totalPayment}</td>
                                </tr>
                            )
                        })}
                        <tr style={{borderTop: '2px solid black'}}>
                                    <td><b>Вкупно</b></td>
                                    <td className='text-center'><b>{services.length}</b></td>
                                    <td className='text-end' style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden', paddingRight: '25px', fontWeight: 'bold'}}>
                                    {currencyFormat(payments.reduce((acc, curr) => {
                                        return acc + curr.payment_amount;
                                    }, 0))}
                                    </td>
                                </tr>
                        
                    </tbody>
                </table>
                
                

                
                
            </div>}
                
        </div>
        
        
        </>
    );
}
