import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import axios from 'axios';
import config from '../config.json';
import Spinner from "../components/SharedComponents/Spinner";

const Signin = ()  =>{
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleSignin = (e) => {
        e.preventDefault();
        if (username.length < 1 || password.length < 1) {
            NotificationManager.error('Невалиден корисник')
            return;
        }
        setLoading(true);
        axios({
            method: 'post',
            url: `${config.SERVER_URL}/signin`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                username: username,
                password: password
            }
        }).then(res => {
            if(res.data.token !== undefined) {
                localStorage.setItem('token', res.data.token);
                navigate('/');
            }
        })
        .catch(err => NotificationManager.error(err.response.data))
        .finally(() => {
            setLoading(false);
        });
    }

    const handleInputChange = (e) => {
        const { name, value} = e.target;
        switch (name) {
            case 'username':
                setUsername(value);
                break;
            case 'password':
                setPassword(value);
                break;
            default:
                return;
        }
    }
     
        
    return (
        <div className='background-wrapper'>
            {loading ? <Spinner /> : null}
            <div className='background-form-wrapper'>
                <form onSubmit={handleSignin}>
                    <div className='input-wrapper'>
                        <label htmlFor="username">Корисничко име</label>
                        <input type='text' name='username' onChange={handleInputChange} />
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="password">Лозинка</label>
                        <input type='password' name='password' onChange={handleInputChange} />
                    </div>
                    <div className='button-wrapper'>
                        <button className='submit' type='submit'>Влез</button>
                    </div>
                </form>
            </div>
        </div>
    );
    
}

export default Signin; 