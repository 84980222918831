import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { getUsernameError, getNameError, getPasswordError, getCyrilicNameError } from '../../helpers/validators';
import { NotificationManager} from 'react-notifications';
import axios from 'axios';
import config from '../../config.json';
import Spinner from '../SharedComponents/Spinner';
import Form from 'react-bootstrap/Form';

export default function EditUser() {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [roles, setRoles] = useState('');
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUser(id);
    },[id])

    const getUser = (id) => {
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/users/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {
            setName(res.data.name);
            setUsername(res.data.username);
            setRoles(res.data.roles);       
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const editUser = (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            return;
        }
        setLoading(true);
        axios({
            method: 'put',
            url: `${config.SERVER_URL}/users/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                name: name,
                username: username,
                password: password,
                newPassword: newPassword,
                roles: roles
            }
        }).then(res => {
            NotificationManager.success('Успешна промена');
            navigate('/users');
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const deleteUser = (e) => {
        e.preventDefault();
        setLoading(true);
        axios({
            method: 'delete',
            url: `${config.SERVER_URL}/users/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        }).then(res => {
            NotificationManager.success('Успешна промена');
            navigate('/users');
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
        
    }

    const handleInputChange = (e) => {
        resetErrors();
        const { name, value} = e.target;
        switch (name) {
            case 'name':
                setName(value); 
                break;
            case 'username':
                setUsername(value);
                break;
            case 'newPassword':
                setNewPassword(value);
                break;
            default:
                break;
        }
    }

    const handleRoleChange = (e) => {
        const role = e.target.value;
        const newRoles = roles.length === 0 ? [] : roles.split(',');
        const roleIndex = newRoles.findIndex(r => r === role);
        if (roleIndex !== -1) {
            newRoles.splice(roleIndex, 1);
        } else {
            newRoles.push(role);
        }
        setRoles(newRoles.sort().join(','));
    }

    const cancelRegister = () => {
        resetErrors();
        navigate('..');
    }

    const isFormValid = () => {
        const nameErrorMessage = getCyrilicNameError(name, true);
        const usernameErrorMessage = getUsernameError(username, true);
        const newPasswordErrorMessage = getPasswordError(newPassword, false)

        if(nameErrorMessage) {
            setNameError(nameErrorMessage);
        } 

        if(usernameErrorMessage) {
            setUsernameError(usernameErrorMessage);
        } 

        if(newPasswordErrorMessage !== '') {
            setNewPasswordError(newPasswordErrorMessage);
        }

        return (nameErrorMessage || usernameErrorMessage || newPasswordErrorMessage ) ? false : true;
    }

    const resetErrors = () => {
        
        setNameError('');
        setUsernameError('');
        setNewPasswordError('');
    }
  return (
    <div className='background-wrapper' style={{width: 'auto', height: 'calc(100vh - 140px)',  backgroundColor: 'inherit'}}>
            {loading ? <Spinner /> : null}
            <div className='background-form-wrapper'>
                <h1>Податоци за корисник</h1>
                <form onSubmit={editUser}>
                    <div className='input-wrapper'>
                        <label htmlFor="name">Име и презиме</label>
                        <input type='text' name='name' value={name} onChange={handleInputChange}/>
                        <span className='error text-danger'>{nameError}</span>
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="username">Корисничко име</label>
                        <input type='text' name='username' value={username} onChange={handleInputChange}/>
                        <span className='error text-danger'>{usernameError}</span>
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="newPassword">Нова лозинка</label>
                        <input type='password' name='newPassword' onChange={handleInputChange}/>
                        <span className='error text-danger'>{newPasswordError}</span>
                    </div>
                    <div className='d-flex flex-column justify-content-between' style={{paddingTop: '30px'}}>
                        <div className='input-wrapper'>
                            <label htmlFor="password">Улога</label>
                            <hr style={{marginTop: "0"}}/>
                        </div> 
                            <Form.Check // prettier-ignore
                                type='checkbox'
                                id={`admin`}
                                label={`Администратор`}
                                name='admin'
                                value='Администратор'
                                onChange={handleRoleChange}
                                checked={roles.includes('Администратор')}
                                style={{marginTop: "0"}}
                            />

                            <Form.Check // prettier-ignore
                                type='checkbox'
                                id={`owner`}
                                label={`Сопственик`}
                                name='owner'
                                value='Сопственик'
                                onChange={handleRoleChange}
                                checked={roles.includes('Сопственик')}
                                style={{marginTop: "0"}}
                            />

                            <Form.Check // prettier-ignore
                                type='checkbox'
                                id={`doctor`}
                                label={`Доктор`}
                                name='doctor'
                                value='Доктор'
                                onChange={handleRoleChange}
                                checked={roles.includes('Доктор')}
                                style={{marginTop: "0"}}
                            />

                            <Form.Check // prettier-ignore
                                type='checkbox'
                                id={`employee`}
                                label={`Вработен`}
                                name='employee'
                                value='Вработен'
                                onChange={handleRoleChange}
                                checked={roles.includes('Вработен')}
                                style={{marginTop: "0"}}
                            />
                        </div>
                    <div className='button-wrapper d-flex justify-content-between'>
                        <div>
                            <button className='submit p-2 me-2' type='submit'>Промени</button>
                            <button className='cancel p-2' type='button' onClick={cancelRegister}>Откажи</button>
                        </div>
                        <div className='m-0'>
                            <button className='delete p-2' type='button' onClick={deleteUser}>Избриши</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
  )
}
