import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager} from 'react-notifications';
import config from '../../config.json';
import { getNameError, getCyrilicNameError } from '../../helpers/validators';
import Spinner from '../SharedComponents/Spinner';


const AddDoctor = () => { 
    const navigate = useNavigate();
    const [cyrName, setCyrName] = useState('');
    const [cyrNameError, setCyrNameError] = useState('');
    const [latName, setLatName] = useState('');
    const [latNameError, setLatNameError] = useState('');
    const [specialityCyrilic, setSpecialityCyrilic] = useState('Субспецијалист Кардиолог');
    const [specialityLatin, setSpecialityLatin] = useState('Subspecialist Kardiolog');
    const [loading, setLoading] = useState(false);

    const handleRegister = (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            return;
        }
        setLoading(true)
        axios({
            method: 'post',
            url: `${config.SERVER_URL}/doctors/add`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                name_cyrilic: cyrName,
                name_latin: latName,
                speciality_cyrilic: specialityCyrilic,
                speciality_latin: specialityLatin
            }
        }).then(res => {
            NotificationManager.success('Успешно внесен доктор')
            navigate('..');
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const handleInputChange = (e) => {
        resetErrors();
        const { name, value} = e.target;
        switch (name) {
            case 'cyrName':
                setCyrName(value); 
                break;
            case 'latName':
                setLatName(value);
                break;
            case 'specialityCyrilic':
                setSpecialityCyrilic(value);
                break;
            case 'specialityLatin':
                setSpecialityLatin(value);
                break;
            default:
                break;
        }
    }

    const cancelRegister = () => {
        resetErrors();
        navigate('/doctors');
    }

    const isFormValid = () => {
        const cyrNameErrorMessage = getCyrilicNameError(cyrName, true);
        const latNameErrorMessage = getNameError(latName, true);

        if(cyrNameErrorMessage) {
            setCyrNameError(cyrNameErrorMessage);
        } 

        if(latNameErrorMessage) {
            setLatNameError(latNameErrorMessage);
        } 

        return (cyrNameErrorMessage || latNameErrorMessage) ? false : true;
    }

    const resetErrors = () => {
        setCyrNameError('');
        setLatNameError('');
    }

    return (
        <>
        <Link to='/doctors' type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}}>Назад</Link>
        <div className='background-wrapper' style={{width: 'auto', height: 'calc(100vh - 140px)',  backgroundColor: 'inherit'}}>
            {loading ? <Spinner /> : null}
            <div className='background-form-wrapper'>
                <h1>Додади доктор</h1>
                <form onSubmit={handleRegister}>
                    <div className='input-wrapper'>
                        <label htmlFor="cyrName">Име и презиме ( кирилица )</label>
                        <input type='text' name='cyrName' onChange={handleInputChange}/>
                        <span className='error text-danger'>{cyrNameError}</span>
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="latName">Име и презиме ( латиница )</label>
                        <input type='text' name='latName' onChange={handleInputChange}/>
                        <span className='error text-danger'>{latNameError}</span>
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="specialityCyrilic">Специјалност ( кирилица )</label>
                        <input type='text' name='specialityCyrilic' onChange={handleInputChange}/>
                        <span className='error text-danger'>{cyrNameError}</span>
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="specialityLatin">Специјалност ( латиница )</label>
                        <input type='text' name='specialityLatin' onChange={handleInputChange}/>
                        <span className='error text-danger'>{latNameError}</span>
                    </div>
                    
                    <div className='button-wrapper'>
                        <button className='submit' type='submit'>Зачувај</button>
                        <button className='cancel' type='button' onClick={cancelRegister}>Откажи</button>
                    </div>
                </form>
            </div>
        </div>
        </>
    );
}

export default AddDoctor;