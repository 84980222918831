import React, { useEffect, useState } from 'react'
import Spinner from '../SharedComponents/Spinner';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'
import config from '../../config.json';
import { NotificationManager} from 'react-notifications';
import CustomMonthPicker from '../SharedComponents/CustomMonthPicker';
import {FaEdit, FaSave, FaRegWindowClose} from 'react-icons/fa'

export default function StatisticsTab() {
    const {fromDate, toDate} = useParams();
    const [loading, setLoading] = useState(false)
    const [payments, setPayments] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState({})
    const [dateToEdit, setDateToEdit] = useState('');
    const [selectedDoctorId, setSelectedDoctorId] = useState(0);
    const [startDate, setStartDate] = useState(fromDate ? new Date(fromDate) : new Date(new Date().setMonth(0)));
    const [endDate, setEndDate] = useState(toDate ? new Date(toDate) : new Date());
    const navigate = useNavigate();

    useEffect(() => {
        
      getPayments(startDate, endDate, selectedDoctorId);
        
    }, [])

    const addEditAddons = (e) => {
      e.preventDefault();
      // if (!isFormValid()) {
      //     return;
      // }
      setLoading(true)
      let url = `${config.SERVER_URL}/finance/addons/add`;
      let method = 'post';
      
      if (selectedMonth.id) {
        url = `${config.SERVER_URL}/finance/addons/${selectedMonth.id}`;
        method = 'put';
      }
      axios({
          method: method,
          url: url,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('token')
          },
          data: {
              date: new Date(selectedMonth.date),
              addon1: selectedMonth.addon1,
              addon2: selectedMonth.addon2,
              addon3: selectedMonth.addon3,
              addon4: selectedMonth.addon4,
          }
      }).then(res => {
          NotificationManager.success('Успешно внесена услуга')
          // resetForm();
          getAddons(startDate, endDate, payments, true);
      })
      .catch(err => {
          if (err.response.status === 401) {
              navigate('/signin')
          }
          NotificationManager.error(err.response.data)
      })
      .finally(() => setLoading(false));
  }

    const getAddons = (startDate, endDate, payments, rerender = false) => {
      setLoading(true);
      const start = new Date(startDate.setDate(1));
      const from = `${start.getFullYear()}-${start.getMonth()+1}-${start.getDate()}`;
      const to = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`;
      axios({
          method: 'post',
          url: `${config.SERVER_URL}/finance/addons`,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('token')
          },
          data: {
              filter: {
                  startDateString: from,
                  endDateString: to
              }
          }
      })
      .then(res => {   
          if (typeof(res.data) === "string") {
              NotificationManager.info(res.data)
          } else {
              let sortedPayments = payments;
              if (!rerender) {
                sortedPayments = Object.entries(groupByKey(payments, 'date_of_service', {omitKey:true})).map((payment, index) => {
                  const calcPayment = payment[1].reduce((acc, curr) => {
                      return acc + curr.payment_amount;
                  }, 0)
                  return {
                    date: new Date(payment[0]),
                    monthlyPayment: calcPayment,
                    totalPayment: calcPayment
                  }
                })
              } 
              

              res.data.map(a => {
                a.date = new Date(a.date);
                const sameDatePayment = sortedPayments.find(p => p.date.getTime() === a.date.getTime())
                sameDatePayment.addon1 = a.addon1;
                sameDatePayment.addon2 = a.addon2;
                sameDatePayment.addon3 = a.addon3;
                sameDatePayment.addon4 = a.addon4;
                sameDatePayment.id = a.id;
                sameDatePayment.totalPayment = sameDatePayment.monthlyPayment + a.addon1 + a.addon2 + a.addon3 + a.addon4;
                return a;
              })
              
              setPayments(sortedPayments);
              closeEditMode();
              
              
          }
          
      })
      .catch(err => {
          if (err.response.status === 401) {
              navigate('/signin')
          }
          NotificationManager.error(err.response.data)
      })
      .finally(() => setLoading(false));
  }

    const getPayments = (startDate, endDate, doctorId) => {
        setLoading(true);
        const start = new Date(startDate.setDate(1));
        const from = `${start.getFullYear()}-${start.getMonth()+1}-${start.getDate()}`;
        const to = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`;
        axios({
            method: 'post',
            url: `${config.SERVER_URL}/finance/payments`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                filter: {
                    startDateString: from,
                    endDateString: to,
                    doctorId: doctorId
                }
            }
        })
        .then(res => {  
            if (typeof(res.data) === "string") {
                NotificationManager.info(res.data)
            } else {
                const data = res.data.map(p => {
                  const date = new Date(p.date_of_service).setDate(1)
                  p.date_of_service = new Date(date);
                  return p;
                })
                getAddons(startDate, endDate, data);
            }
            
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const handleStartDateChange = (date) => {
        setStartDate(date);
        // navigateToDate(date);
        getPayments(date, endDate, selectedDoctorId);
    }

    const handleEndDateChange = (date) => {
      date.setMonth(date.getMonth() + 1);
      date.setDate(date.getDate() - 1);
      
        setEndDate(date);
        // navigateToDate(date);
        getPayments(startDate, date, selectedDoctorId);
    }

    const showEditMode = (payment) => {
      setDateToEdit(payment.date);
      const selectedMonth = payments.find(m => m.date === payment.date)
      setSelectedMonth(selectedMonth);

    }

    const closeEditMode = () => {
      setDateToEdit('')
      setSelectedMonth({})
    }

    const addonsChange = (e) => {
      const { name, value} = e.target;
      setSelectedMonth({
        ...selectedMonth,
        [name]: parseInt(value)
      })
    }

    const currencyFormat = (num) => {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' МКД'
     }

    const groupByKey = (list, key, {omitKey=false}) => list.reduce((hash, {[key]:value, ...rest}) => ({...hash, [value]:( hash[value] || [] ).concat(omitKey ? {...rest} : {[key]:value, ...rest})} ), {})

    return (
        <>
        
        <div className='background-wrapper_patient' style={{width: 'auto', height: 'auto',  backgroundColor: '#fff'}}>
            {loading ? <Spinner /> : null}
            {payments && 
            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: '50%', flexWrap: 'wrap'}}>
                    <p style={{width: '100%', margin: '0px 10px'}}>Извештај за период:</p>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap'}}>
                        <div className="input-group d-flex" style={{width: 'auto', minWidth: '222px', flexWrap: 'nowrap', margin: '10px'}}>
                            <CustomMonthPicker type="text" startDate={startDate} setStartDate={handleStartDateChange}/>
                        </div>
                        <div className="input-group d-flex" style={{width: 'auto', minWidth: '222px', flexWrap: 'nowrap', margin: '10px'}}>
                            <CustomMonthPicker type="text" startDate={endDate} setStartDate={handleEndDateChange}/>
                        </div>
                        
                    </div>
                </div>
                
                <table className="table" style={{width: '100%'}}>
                    <thead>
                        <tr>
                            <th scope="col">Месец</th>
                            <th scope="col" className='text-end'>Промет</th>
                            <th scope="col" className='text-end'>Додаток I</th>
                            <th scope="col" className='text-end'>Додаток II</th>
                            <th scope="col" className='text-end'>Додаток III</th>
                            <th scope="col" className='text-end'>Додаток IV</th>
                            <th scope="col" className='text-end' style={{paddingRight: '25px'}}>Вкупен промет</th>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {payments.map((payment, index) => {
                           
                           const date = new Date(payment.date);
                            return (
                                <tr key={payment.date + "_" + index}>
                                    <td>{`${date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1) }/${date.getFullYear()}`}</td>
                                    <td className='text-end' style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden'}}>{currencyFormat(payment.monthlyPayment)}</td>
                                    <td className='text-end'>{dateToEdit === payment.date ? <input type='number' name='addon1' value={selectedMonth.addon1} onChange={addonsChange}/> : payment.addon1 ? currencyFormat(payment.addon1) : payment.addon1}</td>
                                    <td className='text-end'>{dateToEdit === payment.date ? <input type='number' name='addon2' value={selectedMonth.addon2} onChange={addonsChange}/> : payment.addon2 ? currencyFormat(payment.addon2) : payment.addon2}</td>
                                    <td className='text-end'>{dateToEdit === payment.date ? <input type='number' name='addon3' value={selectedMonth.addon3} onChange={addonsChange}/> : payment.addon3 ? currencyFormat(payment.addon3) : payment.addon3}</td>
                                    <td className='text-end'>{dateToEdit === payment.date ? <input type='number' name='addon4' value={selectedMonth.addon4} onChange={addonsChange}/> : payment.addon4 ? currencyFormat(payment.addon4) : payment.addon4}</td>
                                    <td className='text-end' style={{paddingRight: '25px'}}>{currencyFormat(payment.totalPayment)}</td>
                                    <td className='text-end' style={{width: '100px', paddingTop: '0', fontSize: '21px', cursor: 'pointer'}}>
                                      {dateToEdit === payment.date ? 
                                      <>
                                        <FaSave style={{color: '#39828f', marginRight: '10px'}} onClick={addEditAddons}/>
                                        <FaRegWindowClose style={{color: '#ad4e56'}} onClick={closeEditMode}/>
                                      </>
                                    : <FaEdit style={{color: '#39828f'}} onClick={() => showEditMode(payment)}/>}
                                        
                                  </td>
                                </tr>
                            )
                        })}
                        <tr style={{borderTop: '2px solid black'}}>
                            <td><b>Вкупно</b></td>
                            <td className='text-end' style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden',  fontWeight: 'bold'}}>
                            {currencyFormat(payments.reduce((acc, curr) => {
                                return acc + curr.monthlyPayment;
                            }, 0))}
                            </td>
                            <td className='text-end'>
                            {currencyFormat(payments.reduce((acc, curr) => {
                                const addon = curr.addon1 && curr.addon1 !== null ? curr.addon1 : 0
                                return acc + addon;
                            }, 0))}
                            </td>
                            <td className='text-end'>
                            {currencyFormat(payments.reduce((acc, curr) => {
                                const addon = curr.addon2 && curr.addon2 !== null ? curr.addon2 : 0
                                return acc + addon;
                            }, 0))}
                            </td>
                            <td className='text-end'>
                            {currencyFormat(payments.reduce((acc, curr) => {
                                const addon = curr.addon3 && curr.addon3 !== null ? curr.addon3 : 0
                                return acc + addon;
                            }, 0))}
                            </td>
                            <td className='text-end'>
                            {currencyFormat(payments.reduce((acc, curr) => {
                                const addon = curr.addon4 && curr.addon4 !== null ? curr.addon4 : 0
                                return acc + addon;
                            }, 0))}
                            </td>
                            <td className='text-end' style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden', paddingRight: '25px',  fontWeight: 'bold'}}>
                            {currencyFormat(payments.reduce((acc, curr) => {
                                return acc + curr.totalPayment;
                            }, 0))}
                            </td>
                            <td></td>
                        </tr>
                        
                    </tbody>
                </table>
                
                

                
                
            </div>}
                
        </div>
        
        
        </>
    );
}
