import React from 'react'
import { Outlet} from 'react-router-dom'

export default function Schedule() {
  return (
    <div className="d-flex flex-column">
      <Outlet />
    </div>
  )
}
