import React from 'react'
import { Link, Outlet} from 'react-router-dom'

export default function Users() {

  return (
    <div className="d-flex flex-column">
      <Outlet />
    </div>
  )
}
