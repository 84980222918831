import React, {useRef, useState} from 'react'
import { useReactToPrint } from 'react-to-print'
import ComponentToPrint from '../SharedComponents/ComponentToPrint'
import { BsFillPrinterFill } from 'react-icons/bs'

const ExportPatientPdf = ({togglePrint, showLangTemplate, children}) => {
  const [lang, setLang] = useState('');
  const [isMultiLang, setIsMultiLang] = useState(false);
    const componentRef = useRef()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })

    const changePrintLang = (e) => {
      setLang(e.target.value)
    }

    const changeMultiLang = (e) => {
      setIsMultiLang(!!e.target.value)
    }

  return (
    
    <div>
      <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
        {showLangTemplate ? (
          <>
            <label>Јазик: </label>
            <select name='lang' className='form-control m-2' style={{width: '200px'}} value={lang} onChange={(e) => changePrintLang(e)}>
              <option value=''>Македонски</option>
              <option value='al'>Албански</option>
            </select>
            <label>Темплејт: </label>
            <select name='isMultiLang' className='form-control m-2' style={{width: '200px'}} value={isMultiLang} onChange={(e) => changeMultiLang(e)}>
              <option value=''>Еднојазичен</option>
              <option value='true'>Двојазичен</option>
            </select>
          </>
        ) : null}
        <button className='print fs-4 p-0 mr-2' style={{width: '80px', borderRadius: '5px'}} type='button'  onClick={handlePrint}>
            <BsFillPrinterFill />
        </button>
        <button onClick={() => togglePrint()} className='btn btn-light'>Откажи</button>
      </div>
     
      <div style={{maxHeight: 'calc(100vh - 240px', overflowY: 'scroll', overflowX: 'hidden'}}>
        <ComponentToPrint ref={componentRef} >
            {React.cloneElement(children, {lang, isMultiLang})}
        </ComponentToPrint>
      </div>
      
    </div>
  )
}

export default ExportPatientPdf;
