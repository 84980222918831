import React, {useState} from 'react'
import { Modal, Button } from "react-bootstrap";
import CustomDatePicker from '../SharedComponents/CustomDatePicker';
import axios from 'axios';
import config from '../../config.json';
import {NotificationManager} from 'react-notifications';
import Spinner from "../../components/SharedComponents/Spinner";
import { useNavigate } from 'react-router-dom';

export default function AppointmentsModal({show, handleClose, handleSubmit, appointment, handleInputChange, allTimes, availableTimes, setSelectedAppointment, handleRemoveAppointment}) {
    const [loading, setLoading] = useState(false);
    const [patients, setPatients] = useState([]);
    const navigate = useNavigate();
    const created_at = new Date(appointment.created_on);
    console.log(appointment)

    const getPatients = (term) => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/patients?searchTerm=${term}&skip=0&take=10`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
        })
        .then(res => {   
            setPatients(res.data); 
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const handlePatientSearch = (e) => {
        const name = e.target.value;
        if (name.length > 0) {
            getPatients(name);
        } else {
            setPatients([]);
        }
        const newAppointment = {
            ...appointment,
            patient_name: name,
            patient_id: 0
        }
        setSelectedAppointment(newAppointment)
        
    }

    const handlePatientSelect = (patient) => {
        setPatients([])
        setSelectedAppointment({
            ...appointment,
            patient_id: patient.id,
            patient_name: patient.name_cyrilic,
            patient_phone: patient.phone
            
        })
    }

    return (
    <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
            <Modal.Title>Термин</Modal.Title>
            {appointment.id ? 
                <p className='w-100 mb-0 text-center'>{`Закажан на: ${created_at.getDate()}/${created_at.getMonth() + 1}/${created_at.getFullYear()}`}</p>
            : null}
            
        </Modal.Header>
        <Modal.Body>
            <div className='mb-3'>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="status" checked={appointment.status === '#FEDC80'} value='#FEDC80'  onChange={handleInputChange} id="status1" />
                    <label className="form-check-label" htmlFor="inlineRadio1">Закажан преглед</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="status" id="status1" checked={appointment.status === '#ff8484'} value='#ff8484'  onChange={handleInputChange} />
                    <label className="form-check-label" htmlFor="inlineRadio1">Чека за преглед</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="status" id="status2" checked={appointment.status === '#adffad'} value='#adffad'  onChange={handleInputChange} />
                    <label className="form-check-label" htmlFor="inlineRadio2">Завршен преглед</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="status" id="status3" checked={appointment.status === '#d39160'} value='#d39160'  onChange={handleInputChange} />
                    <label className="form-check-label" htmlFor="inlineRadio3">Откажан преглед</label>
                </div>
            </div>
            <div className="mb-3">
                
                <label htmlFor="date" className="form-label">Датум</label>
                <CustomDatePicker type="text" disabled={true} startDate={appointment.start_date_time} setStartDate={handleInputChange}/>
                {/* <input type="text" className="form-control" name='start_date_time' value={appointment.start_date_time} onChange={handleInputChange}/> */}
            </div>
            <div className="mb-3">
                <label htmlFor="service" className="form-label">Услуга</label>
                <input type="text" className="form-control" name='service' value={appointment.service} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
                <label htmlFor="duration" className="form-label">Времетраење</label>
                <input type="text" className="form-control" name='duration' value={appointment.duration} onChange={handleInputChange}/>
            </div>
            <div className="mb-3">
                <label htmlFor="time" className="form-label">Термин</label>
                <select className="form-control" name='time' value={`${appointment.start_date_time?.getHours()}:${appointment.start_date_time?.getMinutes()}`} onChange={handleInputChange}>
                    {allTimes.map((item, i) => {
                        const hours = item.getHours();
                        const minutes = item.getMinutes();
                        const availableTime = availableTimes.findIndex(t => t.getTime() === item.getTime())
                        if (availableTime !== -1) {
                            return <option key={`${hours}-${minutes}`} value={`${hours}:${minutes}`}>{hours < 10 ? '0' + hours : hours}:{minutes < 10 ? '0' + minutes : minutes}</option>
                        }
                        return <option key={`${hours}-${minutes}`} className='text-danger' style={{backgroundColor: '#ddd'}} value={`${hours}:${minutes}`} disabled>{hours < 10 ? '0' + hours : hours}:{minutes < 10 ? '0' + minutes : minutes}</option>
                    })}

                </select>
            </div>   
            <div className="mb-3" style={{position: 'relative'}}>
                <label htmlFor="patient_name" className="form-label">Име и презиме</label>
                <input type="text" className="form-control" name='patient_name' value={appointment.patient_name}  onChange={handlePatientSearch} autoComplete='off'/>
                <div style={{position: 'absolute', width: '100%', backgroundColor: '#fff'}}>
                    {patients.map(p => <div onClick={() => handlePatientSelect(p)}>{p.name_cyrilic} - {p.phone}</div>)}
                </div>
            </div>   
            <div className="mb-3">
                <label htmlFor="patient_phone" className="form-label">Телефон</label>
                <input type="text" className="form-control" name='patient_phone' value={appointment.patient_phone} onChange={handleInputChange} />
            </div>         
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'space-between'}}>
            <div className='text-left'>
                <Button className='me-3' variant="success" type='submit' onClick={handleClose}>
                    Зачувај
                </Button>
                
                {appointment.id && appointment.id > 0 ? 
                <Button variant="danger" type='button' onClick={handleRemoveAppointment}>
                    Избриши
                </Button>
                : null}
            </div>
            <Button variant="secondary" type='button' onClick={handleClose}>
                Откажи
            </Button>
            
        </Modal.Footer>
        </form>
    </Modal>
  )
}
