
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager} from 'react-notifications';
import config from '../../../../config.json';
import Spinner from '../../../SharedComponents/Spinner';
import CustomDatePicker from '../../../SharedComponents/CustomDatePicker';


const AddConsultation = () => {
    const navigate = useNavigate();
    const [consultation, setConsultation] = useState({});
    const [date, setDate] = useState(new Date()); 
    const [loading, setLoading] = useState(false);
    const [doctors, setDoctors] = useState();
    const {id} = useParams();

    useEffect(() => {
        getDoctors();
        getLastCardioExam();
    }, [])

    const getDoctors = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/doctors`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            setDoctors(res.data); 
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));

    }

    const getLastCardioExam = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/patients/${id}/exams/consultations/last`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
             if(res.data.id) {
                delete res.data.id;
                delete res.data.patient_id;
                delete res.data.exam_date;
                setConsultation({
                    exam_date: date,
                    ...res.data
                })
             } else {
                setConsultation({
                    doctor_id: 1,
                    exam_date: new Date()
                })
             }
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
        })
        .finally(() => setLoading(false));
    }

    const createConsultation = (e) => {
        e.preventDefault();
        // if (!isFormValid()) {
        //     return;
        // }
        setLoading(true)
        axios({
            method: 'post',
            url: `${config.SERVER_URL}/patients/${id}/exams/consultations/add`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                ...consultation
            }
        }).then(res => {
            NotificationManager.success('Извештајот е успешно креиран')
            navigate(`/patients/${id}/exams/consultations/${res.data.id}`);
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const handleInputChange = (e) => {
        resetErrors();
        const { name, value} = e.target;
        setConsultation({
            ...consultation,
            [name]: value,
          });
    }

    const cancelRegister = () => {
        resetErrors();
        navigate('../exams');
    }


    const resetErrors = () => {
        
        
    }

    const setExamDate = (e) => {
        setConsultation({
            ...consultation,
            'exam_date': e,
          });
          setDate(e);
    }

    return (
        <>
        <div className='background-wrapper_patient' style={{width: 'auto', height: 'auto',  backgroundColor: 'inherit'}}>
            {loading ? <Spinner /> : null}
                <form style={{width: '100%'}} onSubmit={createConsultation}>
                    <div className='background-form-wrapper_patient'>
                        <h1 className='mt-3'>Креирај лекарска препорака</h1>
                        <div className='input-wrapper'>
                            <label htmlFor="exam_date">Датум на преглед</label>
                            <CustomDatePicker name='exam_date' startDate={date} setStartDate={setExamDate}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="doctor_id">Доктор</label>
                            <select name="doctor_id" onChange={handleInputChange}>
                                {doctors && doctors.map(doctor => <option key={doctor.id} value={doctor.id}>{doctor.name_cyrilic}</option>)}
                            </select>
                        </div>
                        <div className='input-wrapper' style={{marginRight: '10%', width: '40%'}}>
                            <label htmlFor="consult_motive">Мотив за консултација</label>
                            <textarea className="form-control" placeholder="Мотив за консултација..." value={consultation.consult_motive} name="consult_motive" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper' style={{marginRight: '10%', width: '40%'}}>
                            <label htmlFor="consult_motive_al">Motivet e konsultës</label>
                            <textarea className="form-control" placeholder="Motivet e konsultës..." value={consultation.consult_motive_al} name="consult_motive_al" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        
                        <div className='input-wrapper' style={{marginRight: '10%', width: '40%'}}>
                            <label htmlFor="recommendation">Препорака</label>
                            <textarea className="form-control" placeholder="Препорака..." value={consultation.recommendation} name="recommendation" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper' style={{marginRight: '10%', width: '40%'}}>
                            <label htmlFor="recommendation_al">Rekomandim</label>
                            <textarea className="form-control" placeholder="Rekomandim..." value={consultation.recommendation_al}  name="recommendation_al" style={{height: '110px'}} onChange={handleInputChange}></textarea>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        
                        <div className='button-wrapper'>
                        <button className='submit me-2' type='submit'>Креирај</button>
                        <button className='cancel' type='button' onClick={cancelRegister}>Откажи</button>
                    </div>
                    </div>
                </form>
           
        </div>
        </>
    );
}

export default AddConsultation;