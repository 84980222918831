import React, {useState} from 'react'
import { Modal, Button } from "react-bootstrap";
import CustomDatePicker from '../SharedComponents/CustomDatePicker';
import { useNavigate } from 'react-router-dom';

export default function InOutModal({show, handleClose, handleSubmit, inOutItem, handleInputChange, setInOutItem, handleRemove}) {
    
    return (
    <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
            <Modal.Title>Термин</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           
            <div className="mb-3">
                
                <label htmlFor="date" className="form-label">Датум</label>
                <CustomDatePicker type="text" startDate={inOutItem.date} setStartDate={handleInputChange}/>
                
            </div>
            <div className="mb-3">
                <label htmlFor="type" className="form-label">Тип</label>
                <select className="form-control" name='type' value={inOutItem.type} onChange={handleInputChange}>
                    <option value={true}>Влез</option>
                    <option value={false}>Излез</option>

                </select>
            </div>
            <div className="mb-3">
                <label htmlFor="description" className="form-label">Опис</label>
                <input type="text" className="form-control" name='description' value={inOutItem.description} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
                <label htmlFor="amount" className="form-label">Вредност</label>
                <input type="number" className="form-control" name='amount' value={inOutItem.amount} onChange={handleInputChange}/>
            </div>
              
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'space-between'}}>
            <div className='text-left'>
                <Button className='me-3' variant="success" type='submit' onClick={handleClose}>
                    Зачувај
                </Button>
                
                {inOutItem.id && inOutItem.id > 0 ? 
                <Button variant="danger" type='button' onClick={handleRemove}>
                    Избриши
                </Button>
                : null}
            </div>
            <Button variant="secondary" type='button' onClick={handleClose}>
                Откажи
            </Button>
            
        </Modal.Footer>
        </form>
    </Modal>
  )
}
