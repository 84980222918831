import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager} from 'react-notifications';
import config from '../../../../config.json';
import Spinner from '../../../SharedComponents/Spinner';
import CustomDatePicker from '../../../SharedComponents/CustomDatePicker';
import Form from 'react-bootstrap/Form'

export default function AddOtherExam() {
    const navigate = useNavigate();
    const [consultation, setConsultation] = useState({});
    const [date, setDate] = useState(new Date()); 
    const [loading, setLoading] = useState(false);
    const [doctors, setDoctors] = useState();
    const {id} = useParams();

    useEffect(() => {
        getDoctors();
        setConsultation({
            doctor_id: 1,
            exam_date: new Date(),
            stress_test: false,
            holter_ekg: false,
            holter_abp: false
        })
        // getLastCardioExam();
    }, [])

    const getDoctors = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/doctors`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            setDoctors(res.data); 
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));

    }

    const createConsultation = (e) => {
        
        e.preventDefault();
        // if (!isFormValid()) {
        //     return;
        // }
        setLoading(true)
        axios({
            method: 'post',
            url: `${config.SERVER_URL}/patients/${id}/exams/other/add`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                ...consultation
            }
        }).then(res => {
            NotificationManager.success('Извештајот е успешно креиран')
            navigate(`/patients/${id}/exams/other-exams/${res.data.id}`);
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));

        console.log(consultation)
        return;
    }

    const handleInputChange = (e) => {
      
        resetErrors();
        const { name, value} = e.target;
        if (name === 'stress_test' || name === 'holter_ekg' || name === 'holter_abp') {
            setConsultation({
                ...consultation,
                [name]: !consultation[name],
              });
        } else {
            setConsultation({
                ...consultation,
                [name]: value,
              });
        }
        
        
    }

    const cancelRegister = () => {
        resetErrors();
        navigate('../exams');
    }


    const resetErrors = () => {
        
        
    }

    const setExamDate = (e) => {
        setConsultation({
            ...consultation,
            'exam_date': e,
          });
          setDate(e);
    }

    return (
        <>
        <div className='background-wrapper_patient' style={{width: 'auto', height: 'auto',  backgroundColor: 'inherit'}}>
            {loading ? <Spinner /> : null}
                <Form style={{width: '100%'}} onSubmit={createConsultation}>
                    <div className='background-form-wrapper_patient'>
                        <h1 className='mt-3'>Извршени услуги</h1>
                        <div className='d-flex flex-column justify-content-between' style={{width: '50%'}}>
                            <div className='input-wrapper' style={{width: '50%'}}>
                                <label htmlFor="exam_date">Датум на преглед</label>
                                <CustomDatePicker name='exam_date' startDate={date} setStartDate={setExamDate}/>
                                {/* <span className='error text-danger'>{nameError}</span> */}
                            </div>
                            <div className='input-wrapper' style={{width: '50%'}}>
                                <label htmlFor="doctor_id">Доктор</label>
                                <select name="doctor_id" onChange={handleInputChange} style={{width: '100%'}}>
                                    {doctors && doctors.map(doctor => <option key={doctor.id} value={doctor.id}>{doctor.name_cyrilic}</option>)}
                                </select>
                            </div>
                        </div>

                        <div className='d-flex flex-column justify-content-between' style={{width: '50%', paddingTop: '30px'}}>
                            <Form.Check // prettier-ignore
                                type='checkbox'
                                id={`stress_test`}
                                label={`Стрес тест`}
                                name='stress_test'
                                value={consultation.stress_test}
                                onChange={handleInputChange}
                            />

                            <Form.Check // prettier-ignore
                                type='checkbox'
                                id={`holter_ekg`}
                                label={`24 часовен холтер ЕКГ`}
                                name='holter_ekg'
                                value={consultation.holter_ekg}
                                onChange={handleInputChange}
                            />

                            <Form.Check // prettier-ignore
                                type='checkbox'
                                id={`holter_abp`}
                                label={`24 часовен холтер АБП`}
                                name='holter_abp'
                                value={consultation.holter_abp}
                                onChange={handleInputChange}
                            />
                        </div>
                        
                        
                        <div className='button-wrapper'>
                            <button className='submit me-2' type='submit'>Креирај</button>
                            <button className='cancel' type='button' onClick={cancelRegister}>Откажи</button>
                        </div>
                    </div>
                </Form>
           
        </div>
        </>
    );
}
