
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager} from 'react-notifications';
import config from '../../../config.json';
import { getUsernameError, getNameError, getPasswordError } from '../../../helpers/validators';
import Spinner from '../../SharedComponents/Spinner';
import CustomDatePicker from '../../SharedComponents/CustomDatePicker';


const AddPatient = () => {
    const navigate = useNavigate();
    const [name_cyrilic, setName_cyrilic] = useState('');
    const [name_latin, setName_latin] = useState('');
    const [address_cyrilic, setAddress_cyrilic] = useState('');
    const [address_latin, setAddress_latin] = useState('');
    const [city_cyrilic, setCity_cyrilic] = useState('');
    const [city_latin, setCity_latin] = useState('');
    const [country_cyrilic, setCountry_cyrilic] = useState('');
    const [country_latin, setCountry_latin] = useState('');
    const [gender, setGender] = useState(""); 
    const [date_of_birth, setDate_of_birth] = useState(""); 
    const [embg, setEmbg] = useState(""); 
    const [personal_doc_num, setPersonal_doc_num] = useState('');
    const [phone, setPhone] = useState(""); 
    const [email, setEmail] = useState(""); 
    const [loading, setLoading] = useState(false);

    const handleRegister = (e) => {
        e.preventDefault();
        // if (!isFormValid()) {
        //     return;
        // }
        setLoading(true)
        axios({
            method: 'post',
            url: `${config.SERVER_URL}/patients/add`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                name_cyrilic,
                name_latin,
                address_cyrilic,
                address_latin,
                city_cyrilic,
                city_latin,
                country_cyrilic,
                country_latin,
                gender,
                date_of_birth,
                embg,
                personal_doc_num,
                phone,
                email
            }
        }).then(res => {
            NotificationManager.success('Успешно креиран пациент')
            navigate(`/patients/${res.data.id}`);
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const handleInputChange = (e) => {
        resetErrors();
        const { name, value} = e.target;
        switch (name) {
            case 'name_cyrilic':
                setName_cyrilic(value); 
                break;
            case 'name_latin':
                setName_latin(value);
                break;
            case 'address_cyrilic':
                setAddress_cyrilic(value);
                break;
            case 'address_latin':
                setAddress_latin(value);
                break;
            case 'city_cyrilic':
                setCity_cyrilic(value); 
                break;
            case 'city_latin':
                setCity_latin(value);
                break;
            case 'country_cyrilic':
                setCountry_cyrilic(value);
                break;
            case 'country_latin':
                setCountry_latin(value);
                break;
            case 'gender':
                setGender(value); 
                break;
            case 'date_of_birth':
                setDate_of_birth(value);
                break;
            case 'embg':
                setEmbg(value);
                break;
            case 'personal_doc_num':
                setPersonal_doc_num(value);
                break;
            case 'phone':
                setPhone(value); 
                break;
            case 'email':
                setEmail(value);
                break;
            default:
                break;
        }
    }

    // const handleSelectChange = (e) => {
    //     setRole(e.target.value);
    // }

    const cancelRegister = () => {
        resetErrors();
        navigate('/patients');
    }

    

    // const isFormValid = () => {
    //     const nameErrorMessage = getNameError(name);
    //     const usernameErrorMessage = getUsernameError(username);
    //     const passwordErrorMessage = getPasswordError(password);

    //     if(nameErrorMessage) {
    //         setNameError(nameErrorMessage);
    //     } 

    //     if(usernameErrorMessage) {
    //         setUsernameError(usernameErrorMessage);
    //     } 

    //     if(passwordErrorMessage) {
    //         setPasswordError(passwordErrorMessage);
    //     } 

    //     return (nameErrorMessage || usernameErrorMessage || passwordErrorMessage) ? false : true;
    // }

    const resetErrors = () => {
        
        // setNameError('');
        // setUsernameError('');
        // setPasswordError('');
    }

    return (
        <>
        <Link to='/patients' type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}}>Назад</Link>
        <div className='background-wrapper_patient mt-3' style={{width: 'auto', height: 'auto',  backgroundColor: 'inherit'}}>
            {loading ? <Spinner /> : null}
            
                <form style={{width: '100%'}} onSubmit={handleRegister}>
                    <div className='background-form-wrapper_patient'>
                        <h1 className='mt-3'>Креирај нов пациент</h1>
                        <div className='input-wrapper'>
                            <label htmlFor="name_cyrilic">Име и презиме ( кирилица )</label>
                            <input type='text' name='name_cyrilic' onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="name_latin">Име и презиме ( латиница )</label>
                            <input type='text' name='name_latin' onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="address_cyrilic">Адреса ( кирилица )</label>
                            <input type='text' name='address_cyrilic' onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="address_latin">Адреса ( латиница )</label>
                            <input type='text' name='address_latin' onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="city_cyrilic">Град ( кирилица )</label>
                            <input type='text' name='city_cyrilic' onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="city_latin">Град ( латиница )</label>
                            <input type='text' name='city_latin' onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="country_cyrilic">Држава ( кирилица )</label>
                            <input type='text' name='country_cyrilic' onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="country_latin">Држава ( латиница )</label>
                            <input type='text' name='country_latin' onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                        <label htmlFor="gender">Пол</label>
                        <select className='select_dropdown' name="gender" onChange={handleInputChange}>
                            <option value=''></option>
                            <option value='маж'>Маж</option>
                            <option value='жена'>Жена</option>
                        </select>
                    </div>
                        <div className='input-wrapper'>
                            <label htmlFor="date_of_birth">Датум на раѓање</label>
                            <CustomDatePicker name='exam_date' 
                                startDate={date_of_birth} 
                                setStartDate={setDate_of_birth} 
                                />
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="embg">Матичен број</label>
                            <input type='text' name='embg' onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="personal_doc_num">Број на документ за идентификација</label>
                            <input type='text' name='personal_doc_num' onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="phone">Телефон</label>
                            <input type='text' name='phone' onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="email">Е-пошта</label>
                            <input type='text' name='email' onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='button-wrapper'>
                        <button className='submit' type='submit'>Креирај</button>
                        <button className='cancel' type='button' onClick={cancelRegister}>Откажи</button>
                    </div>
                    </div>
                </form>
           
        </div>
        </>
    );
}

export default AddPatient;