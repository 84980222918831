import React, {useEffect, useState} from 'react'
import { Modal, Button } from "react-bootstrap";
import CustomDatePicker from '../SharedComponents/CustomDatePicker';
import axios from 'axios';
import config from '../../config.json';
import {NotificationManager} from 'react-notifications';
import Spinner from "../../components/SharedComponents/Spinner";
import { useNavigate } from 'react-router-dom';

export default function DoctorScheduleModal({show, handleClose, room_number, date, getWorkingHours, busyStartTimes, busyArray, selectedWH, workingHoursByDoctor}) {
    const [loading, setLoading] = useState(false);
    const [doctors, setDoctors] = useState([]);
    const [workingHours, setWorkingHours] = useState();
    const [maxTime, setMaxTime] = useState();
    const navigate = useNavigate();

    useEffect(()=> {
        getDoctors()
        const availableTimes = getAvailableStartTimes(date)
        const startTime = availableTimes[0];
        
        const endTime = getAvailableEndTime(startTime);
        
        if (selectedWH && selectedWH?.id) {
            setWorkingHours({
                ...selectedWH,
                start_date_time: new Date(selectedWH.start_date_time),
                end_date_time: new Date(selectedWH.end_date_time),
            })
        } else {
            setWorkingHours({
                room_number: room_number,
                doctor_id: 1,
                start_date_time: startTime,
                end_date_time: endTime
            })
        }
        
        
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        // if (!isFormValid()) {
        //     return;
        // }
        setLoading(true)
        const objToSave = {...workingHours};
        objToSave.room_number = room_number;
        objToSave.duration = (objToSave.end_date_time - objToSave.start_date_time) / 60000;
        if (objToSave.id) {
            objToSave.secondId = workingHoursByDoctor.find(wh => wh.id !== objToSave.id).id;
        }
        
        
        axios({
            method: 'post',
            url: `${config.SERVER_URL}/working-hours/add`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                ...objToSave
            }
        }).then(res => {
            NotificationManager.success('Успешно зачуван термин')
            getWorkingHours(date)
            handleClose();
            // getAppointments(selectedDate)
            // resetForm();
            // getDoctors();
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const handleInputChange = (e) => {
       
        const { name, value} = e.target;
        switch (name) {
            case 'doctor_id':
                setWorkingHours({
                    ...workingHours,
                    doctor_id: Number(value)
                }) 
                break;
            case 'startTime':
                const startDateTime = new Date(date)
                startDateTime.setHours(Number(value.split(':')[0]))
                startDateTime.setMinutes(Number(value.split(':')[1]))
                startDateTime.setSeconds(0);
                startDateTime.setMilliseconds(0);
                const endTime = getAvailableEndTime(startDateTime)
                setWorkingHours({
                    ...workingHours,
                    start_date_time: startDateTime,
                    end_date_time: endTime

                })
                break;
            case 'endTime':
                const endDateTime = new Date(date)
                endDateTime.setHours(Number(value.split(':')[0]))
                endDateTime.setMinutes(Number(value.split(':')[1]))
                endDateTime.setSeconds(0);
                endDateTime.setMilliseconds(0)
                setWorkingHours({
                    ...workingHours,
                    end_date_time: endDateTime
                })
                break;
            default:
                setWorkingHours({
                    ...workingHours,
                    [name]: value
                })
                break;
        }
    }

    const getDoctors = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/doctors`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
        })
        .then(res => {   
            setDoctors(res.data); 
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    
    const getAllTimes = (date) => {
        const startTime = new Date(date);
        startTime.setHours(8);
        startTime.setMinutes(0);
        startTime.setSeconds(0);
        startTime.setMilliseconds(0);
        const endTime = new Date(startTime);
        endTime.setHours(21);
        endTime.setMinutes(30);
        const allTimes = [];
        while (startTime.getTime() < endTime.getTime()) {
            allTimes.push(new Date(startTime));
            startTime.setMinutes(startTime.getMinutes() + 30);
        }
        return allTimes;
    }

    const getAvailableStartTimes = (date) => {
        if (selectedWH && selectedWH?.id) {
            const startFreeIndex = busyStartTimes.findIndex(t => t.getTime() === new Date(selectedWH.start_date_time).getTime())
            
            const selectedWHTimes = busyStartTimes.filter(t => t.getTime() >= new Date(selectedWH.start_date_time).getTime() && t.getTime() < new Date(selectedWH.end_date_time).getTime())
            
            busyStartTimes.splice(startFreeIndex, selectedWHTimes.length)
        }
        const allTimes = getAllTimes(date);
        const freeTimes = [];
        for (let i = 0; i < allTimes.length; i++) {
            const busyIndex = busyStartTimes.findIndex(t => t.getTime() === allTimes[i].getTime())
            
            if (busyIndex === -1) {
                freeTimes.push(allTimes[i])
            }
            
        }
        return freeTimes;
    }

    const getAvailableEndTime = (startTime) => {
        const newBusyArray = [...busyArray]
        if (selectedWH && selectedWH?.id) {
            const freeIndex = busyArray.findIndex(t => t.id === selectedWH.id);
            
            newBusyArray.splice(freeIndex, 1)
        }
        const nextBusyWH = newBusyArray.find(t => new Date(t.start_date_time).getTime() > startTime.getTime());
        if (!nextBusyWH) {
            const dateToReturn = new Date(startTime);
            dateToReturn.setHours(21);
            dateToReturn.setMinutes(0);
            setMaxTime(dateToReturn)
            return dateToReturn;
        }
        setMaxTime(new Date(nextBusyWH.start_date_time))
        return new Date(nextBusyWH.start_date_time)
    }

    const handleRemoveWH = () => {
        setLoading(true);
        
        axios({
            method: 'delete',
            url: `${config.SERVER_URL}/working-hours/${workingHours.id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
        })
        .then(res => {   
            NotificationManager.success('Успешно избришан термин')
            getWorkingHours(date)
            handleClose();
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
            
        })
        .finally(() => {
            setLoading(false)
        });
    }

    return (
    <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit} >
        <Modal.Header closeButton>
            <Modal.Title>Распоред - Ординација {room_number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {workingHours ? 
             <>
             <div className="mb-3">
                <label htmlFor="date" className="form-label">Датум</label>
                <CustomDatePicker type="text" startDate={date} disabled={true} setStartDate={handleInputChange}/>
            </div>
            <div className="mb-3">
                <label htmlFor="doctor_id" className="form-label">Доктор</label>
                <select className="form-control" name='doctor_id' value={workingHours.doctor_id} onChange={handleInputChange} >
                    {doctors.map((item, i) => {
                        return <option key={item.id} value={item.id} >{item.name_cyrilic}</option>
                    })}

                </select>
            </div>
            <div className="mb-3">
                <label htmlFor="startTime" className="form-label">Почеток</label>
                <select className="form-control" name='startTime' value={`${workingHours.start_date_time?.getHours()}:${workingHours.start_date_time?.getMinutes()}`} onChange={handleInputChange}>
                    {getAllTimes(date).map((item, i) => {
                        // const availableTimes = [];
                        const hours = item.getHours();
                        const minutes = item.getMinutes();
                        const busyTime = busyStartTimes.findIndex(t => t.getTime() === item.getTime());
                     
                        if (busyTime === -1) {
                            return <option key={`${hours}-${minutes}`} value={`${hours}:${minutes}`}>{hours < 10 ? '0' + hours : hours}:{minutes < 10 ? '0' + minutes : minutes}</option>
                        }
                        return <option disabled key={`${hours}-${minutes}`} style={{backgroundColor: '#ddd'}} value={`${hours}:${minutes}`} >{hours < 10 ? '0' + hours : hours}:{minutes < 10 ? '0' + minutes : minutes}</option>
                        
                        
                    })}

                </select>
            </div>   
            <div className="mb-3">
                <label htmlFor="endTime" className="form-label">Крај</label>
                <select className="form-control" name='endTime' value={`${workingHours.end_date_time?.getHours()}:${workingHours.end_date_time?.getMinutes()}`} onChange={handleInputChange}>
                    {getAllTimes(date).map((item, i) => {
                        const hours = item.getHours();
                        const minutes = item.getMinutes();
                        const busyTime = busyStartTimes.findIndex(t => t.getTime() === item.getTime());
                        
                        if ((item.getTime() === maxTime.getTime()) 
                        || (busyTime === -1 && item.getTime() > workingHours.start_date_time.getTime() && item.getTime() <= maxTime.getTime())) 
                        {
                            return <option key={`${hours}-${minutes}`} value={`${hours}:${minutes}`}>{hours < 10 ? '0' + hours : hours}:{minutes < 10 ? '0' + minutes : minutes}</option>
                        }
                        return <option disabled key={`${hours}-${minutes}`} style={{backgroundColor: '#ddd'}} value={`${hours}:${minutes}`} >{hours < 10 ? '0' + hours : hours}:{minutes < 10 ? '0' + minutes : minutes}</option>
                        
                       
                    })}

                </select>
            </div>
           
            </> : null
            }
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'space-between'}}>
            <div className='text-left'>
                <Button className='me-3' variant="success" type='submit'>
                    Зачувај
                </Button>
                <Button variant="danger" type='button' onClick={handleRemoveWH}>
                    Избриши
                </Button>
                {/* {workingHours.id && workingHours.id > 0 ? 
                <Button variant="danger" type='button' onClick={handleRemoveWH}>
                    Избриши
                </Button>
                : null} */}
            </div>
            <Button variant="secondary" type='button' onClick={handleClose}>
                Откажи
            </Button>
            
        </Modal.Footer>
       
        </form>
    </Modal>
  )
}
