import React, { useEffect, useState } from 'react'
import Card from '../components/SharedComponents/Card';
import config from '../config.json';

const Home = () => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = () => {
    fetch(config.SERVER_URL, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
        }
    })
    .then(response => response.json())
    .then(res => {
        setUsers(res);    
    })
}

  return (
    <div>
      
    </div>
  )
}

export default Home
