import React from 'react'

const Card = ({title, children}) => {
  return (
    <div className="card" style={{width: "100%", borderRadius: '10px', margin: "10px 0"}}>
        <div className="card-body">
            <h6 className="card-title" style={{color: '#39828f', fontWeight: 'bold', fontSize: '1.3rem'}}>{title}</h6>
            {children}
        </div>
    </div>
  )
}

export default Card;
