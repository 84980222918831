import React, { useEffect, useState} from 'react'
import {NotificationManager} from 'react-notifications'
import logo from '../../../assets/images/logo_memorandum.jpg'
import Spinner from '../../SharedComponents/Spinner';
import axios from 'axios';
import config from '../../../config.json';
import { getTokenPayload } from '../../../helpers/auth'
import { useNavigate } from 'react-router-dom';

export default function PaymentPdf({services, payment}) {
    const [loading, setLoading] = useState(false);
    const [patient, setPatient] = useState({})
    const date = new Date(payment.date_of_service);
    const navigate = useNavigate();

    useEffect(() => {
        getPatient();
    }, [])

    const getPatient = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/patients/${payment.patient_id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            const patient = res.data;
            setPatient(patient)
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }
  return (
    <div className='d-flex justify-content-center'>
        {loading ? <Spinner /> : null }
        <div className='background-wrapper_print justify-content-center' style={{width: '950px', height: 'auto',  backgroundColor: 'inherit', marginBottom: '30px'}}>
            <div className='background-form-wrapper_print w-100'>
                <div className='d-flex flex-column w-100 mt-4'>
                    <img src={logo} alt='logo' style={{width: '60%'}}/>
                    <h1 className='my-5 py-4 text-center border-0 fw-bold'>
                        {payment.revert_id && payment.payment_amount < 0 ?
                        `Поврат на готовина за сметка бр. ${payment.invoice_number}/${date.getFullYear()}` :
                        `Фактура - Готовина бр. ${payment.invoice_number}/${date.getFullYear()}`
                        }
                    </h1>
                </div>
                <div className="d-flex w-100 justify-content-between align-items-center mb-4" >
                    <div>Име и презиме: <b className='ms-2'>{patient.name_cyrilic}</b></div>
                    <div>Датум: <b className='ms-2'>{`${date.getDate()}/${date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1) }/${date.getFullYear()}`}</b></div>
                </div>
                <div className="d-flex w-100  align-items-center fw-bold" style={{borderBottom: '2px solid #000'}} >
                    <div style={{width: '5%'}}>#</div>
                    <div style={{width: '53%'}}>Услуга</div>
                    <div style={{width: '10%', textAlign: 'center'}}>Цена</div>
                    <div style={{width: '10%', textAlign: 'center'}}>Кол.</div>
                    <div style={{width: '12%', textAlign: 'center'}}>Попуст %</div>
                    <div style={{width: '10%', textAlign: 'center'}}>Износ</div>
                </div>
                {services.map((service, index) => {
                        return (
                            <div className="d-flex w-100  align-items-center" 
                            style={{
                                borderBottom: index === services.length - 1 ? '2px solid #000' : '1px solid #000',
                                padding: '5px 0'
                            }} 
                            key={service.id} >
                                <div style={{width: '5%', fontWeight: 'bold'}}>{index + 1}</div>
                                <div style={{width: '53%'}}>{service.price.service }</div>
                                <div style={{width: '10%', textAlign: 'center'}}>{service.price.value }</div>
                                <div style={{width: '10%', textAlign: 'center'}}>{service.volume }</div>
                                <div style={{width: '12%', textAlign: 'center'}}>{service.discount }</div>
                                <div style={{width: '10%', textAlign: 'center'}}>{service.amount }</div>
                            </div>
                        )
                    })}
                <div className="d-flex w-100 justify-content-end align-items-center mb-5" >
                    <div><b>Вкупно:</b></div>
                    <div style={{width: '10%', textAlign: 'center'}}><b>{payment.payment_amount}</b></div>
                </div>

                <div className="d-flex flex-column w-100 justify-content-center" >
                    <div><b>Наплатен износ:</b></div>
                    <div>Во готово: <b className='ms-2'>{payment.payment_cash  ? payment.payment_cash : 0} МКД</b></div>
                    <div>Со картичка: <b className='ms-2'>{payment.payment_card ? payment.payment_card : 0} МКД</b></div>
                </div>
                
                
                <div className='w-100 d-flex justify-content-end doctor-sign'  style={{bottom: '200px'}}>
                    <div style={{borderBottom: '1px solid #212529', width: '200px', paddingBottom: '0' }}>
                        <p className='m-0 mb-2'>Изработил:</p>
                        <p className='m-0'>{getTokenPayload().name}</p>
                    </div>
                </div>
                <div className='d-flex justify-content-center align-items-center doctor-sign'  
                    style={{bottom: '65px', right: '0px', color: '#878180', width: '100%', height: '40px'}}>
                    Бул.Христијан Тодоровски Карпош 102А, 1000 Скопје Т. +389 2 61 44 500, М. +389 70 370 900
                </div>
                <div className='d-flex justify-content-center doctor-sign align-items-center'  
                    style={{bottom: '30px', right: '0px', backgroundColor: '#39828f', color: '#fff', width: '100%', height: '40px', fontSize: '24px'}}>
                    www.cardiovita.mk
                </div>
                
            </div>
        </div>
    </div>
  )
}
