import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import CustomRoute from './components/Helpers/CustomRoute';
import Signin from './pages/Signin';
import Register from './components/Users/Register';
import Main from './components/Layouts/Main';
import Home from './pages/Home'
import Appointments from './pages/Appointments';
import AppointmentsLayout from './components/Appointments/AppointmentsLayout';
import React from 'react';
import Patients from './pages/Patients';
import Finance from './pages/Finance';
import Users from './pages/Users';
import Doctors from './pages/Doctors';
import UserList from './components/Users/UserList';
import EditUser from './components/Users/EditUser';
import DoctorList from './components/Doctors/DoctorList';
import AddDoctor from './components/Doctors/AddDoctor';
import EditDoctor from './components/Doctors/EditDoctor';
import PatientsList from './components/Patients/PatientsList';
import AddPatient from './components/Patients/Profile/AddPatient';
import EditPatient from './components/Patients/Profile/EditPatient';
import ExportPatientPdf from './components/Patients/ExportPatientPdf';
import PatientProfile from './components/Patients/PatientProfile';
import PaymentsList from './components/Patients/Payments/PaymentsList';
import Exams from './components/Patients/Exams/Exams';
import AddCardioExam from './components/Patients/Exams/CardioExams/AddCardioExam';
import EditCardioExam from './components/Patients/Exams/CardioExams/EditCardioExam';
import AddSpecReview from './components/Patients/Exams/SpecReviews/AddSpecReview';
import EditSpecReview from './components/Patients/Exams/SpecReviews/EditSpecReview';
import AddConsultation from './components/Patients/Exams/Conslultations/AddConsultation';
import EditConsultation from './components/Patients/Exams/Conslultations/EditConsultation';
import AddPayment from './components/Patients/Payments/AddPayment';
import EditPayment from './components/Patients/Payments/EditPayment';
import Prices from './pages/Prices';
import AddOtherExam from './components/Patients/Exams/OtherExams/AddOtherExam';
import EditOtherExam from './components/Patients/Exams/OtherExams/EditOtherExam';
import Schedule from './pages/Schedule';
import ScheduleTable from './components/Shedule/ScheduleTable';
import FinancePaymentList from './components/Finance/FinancePaymentList';
import FinanceTab from './components/Finance/FinanceTab';
import StatisticsTab from './components/Finance/StatisticsTab';
import MonthlyReportTab from './components/Finance/MonthlyReportTab';
import InOutReportsTab from './components/Finance/InOutReportsTab';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/register' element={<CustomRoute isPrivate={false}/>}>
          <Route exact path='/register' element={<Register/>}/>
        </Route>
        <Route exact path='/signin' element={<CustomRoute isPrivate={false}/>}>
          <Route exact path='/signin' element={<Signin/>}/>
        </Route>
        <Route exact path='/' element={<CustomRoute isPrivate={true}/>}>
          <Route exact path='/' element={<Main/>}>
          <Route exact path='' element={<Home/>} />
            <Route exact path='home' element={<Home/>} />
            <Route exact path='appointments' >
              <Route exact path='' element={<AppointmentsLayout />} />
              <Route exact path=':date' element={<AppointmentsLayout/>} />
            </Route>
            <Route exact path='patients' element={<Patients/>}>
              <Route path="" element={<PatientsList />} />
              <Route path="add" element={<AddPatient />} />
              <Route path=":id" element={<PatientProfile />} >
                <Route path="" element={<EditPatient />} />
                <Route path="exams" element={<Exams />} />
                <Route path="exams/cardio/add" element={<AddCardioExam />} />
                <Route path="exams/cardio/:examId" element={<EditCardioExam />} />
                <Route path="exams/spec/add" element={<AddSpecReview />} />
                <Route path="exams/spec/:examId" element={<EditSpecReview />} />
                <Route path="exams/consultations/add" element={<AddConsultation />} />
                <Route path="exams/consultations/:examId" element={<EditConsultation />} />
                <Route path="exams/other-exams/add" element={<AddOtherExam />} />
                <Route path="exams/other-exams/:examId" element={<EditOtherExam />} />
                <Route path="payments" element={<PaymentsList />} />
                <Route path="payments/add" element={<AddPayment />} />
                <Route path="payments/:paymentId" element={<EditPayment />} />
                <Route path="*" element={<Navigate to="/patients" />} />
              </Route>
            </Route>
            <Route exact path='schedule' element={<Schedule/>}>
            <Route path="" element={<ScheduleTable />} />
              <Route exact path=':date' element={<ScheduleTable/>} />
              <Route path="*" element={<Navigate to="/schedule" />} />
            </Route>
            <Route exact path='doctors' element={<Doctors/>}>
              <Route path="" element={<DoctorList />} />
              <Route path="add" element={<AddDoctor />} />
              <Route path=":id" element={<EditDoctor />} />
              <Route path="*" element={<Navigate to="/doctors" />} />
            </Route>
            <Route exact path='users' element={<Users/>}>
              <Route path="" element={<UserList />} />
              <Route path="add" element={<Register />} />
              <Route path=":id" element={<EditUser />} />
              <Route path="*" element={<Navigate to="/users" />} />
            </Route>
            <Route exact path='prices' element={<Prices/>} />
            <Route exact path='finance' element={<Finance/>}>
              <Route path="" element={<FinancePaymentList />} >
                <Route path="payments" element={<FinanceTab />} />
                <Route path="services" element={<StatisticsTab />} />
                <Route path="reports" element={<MonthlyReportTab />} />
                <Route path="input-output" element={<InOutReportsTab />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter> 
  );
}

export default App;
