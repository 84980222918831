import React, { useEffect, useState } from 'react'
import Spinner from '../SharedComponents/Spinner';
import {useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'
import config from '../../config.json';
import { NotificationManager} from 'react-notifications';
import CustomDatePicker from '../SharedComponents/CustomDatePicker';
import { getTokenPayload } from '../../helpers/auth';

export default function FinanceTab() {
    const {fromDate, toDate} = useParams();
    const [loading, setLoading] = useState(false)
    const [payments, setPayments] = useState([]);
    const [paymentsBySystem, setPaymentsBySystem] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctorId, setSelectedDoctorId] = useState(0);
    const [startDate, setStartDate] = useState(fromDate ? new Date(fromDate) : new Date());
    const [endDate, setEndDate] = useState(toDate ? new Date(toDate) : new Date());
    const navigate = useNavigate();
    const [activeTable, setActiveTable] = useState('days')
    const [selectedSystem, setSelectedSystem] = useState('1');
    const days = ['Недела', 'Понеделник', 'Вторник', 'Среда', 'Четврток', 'Петок', 'Сабота'];
    const [userRoles, setUserRoles] = useState([]);
    

    useEffect(() => {
        getDoctors();
        const currentUser = getTokenPayload();
        setUserRoles(currentUser.role.split(','))
    }, [])


    const getDoctors = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/doctors`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            setDoctors(res.data); 
            getPayments(startDate, endDate, selectedDoctorId);
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));

    }

    const getPayments = (startDate, endDate, doctorId) => {
        setLoading(true);
        const from = `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`;
        const to = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`;
        axios({
            method: 'post',
            url: `${config.SERVER_URL}/finance/payments`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                filter: {
                    startDateString: from,
                    endDateString: to,
                    doctorId: doctorId
                }
            }
        })
        .then(res => {   
            if (typeof(res.data) === "string") {
                NotificationManager.info(res.data)
            } else {
                setPayments(res.data); 
                filterBySystem(res.data, selectedSystem);
                
            }
            
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const filterBySystem = (data, filter) => {
         
        if (filter === 'all') {
            setPaymentsBySystem(data);
        } else {
            let filterValue = false;
            if (filter === '2') {
                filterValue = true;
            }
            setPaymentsBySystem(data.filter(p => p.is_secondary == filterValue))
        }
    }

    const handleStartDateChange = (date) => {
        setStartDate(date);
        // navigateToDate(date);
        getPayments(date, endDate, selectedDoctorId);
    }

    const handleEndDateChange = (date) => {
        setEndDate(date);
        // navigateToDate(date);
        getPayments(startDate, date, selectedDoctorId);
    }

    const currencyFormat = (num) => {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' МКД'
     }

    const onPaymentClick = (id, patientId) => {
        navigate(`/patients/${patientId}/payments/${id}`)
    }

    const handleInputChange = (e) => {
        setSelectedSystem(e.target.value);
        filterBySystem(payments, e.target.value)
    }

    const groupByKey = (list, key, {omitKey=false}) => list.reduce((hash, {[key]:value, ...rest}) => ({...hash, [value]:( hash[value] || [] ).concat(omitKey ? {...rest} : {[key]:value, ...rest})} ), {})

    return (
        <>
        
        <div className='background-wrapper_patient' style={{width: 'auto', height: 'auto',  backgroundColor: '#fff'}}>
            {loading ? <Spinner /> : null}
            {doctors.length > 0 && 
            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap'}}>
                    <div style={{display: 'flex', flexDirection: 'column', width: '30%', flexWrap: 'wrap'}}>
                        <p style={{width: '100%', margin: '0px 10px'}}>Извештај за период:</p>
                        <div style={{display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap'}}>
                            {userRoles.includes('Администратор') || userRoles.includes('Сопственик') ? 
                            <>
                                <div className="input-group d-flex" style={{width: 'auto', flexWrap: 'nowrap', margin: '10px'}}>
                                    <CustomDatePicker type="text" startDate={startDate} setStartDate={handleStartDateChange}/>
                                </div>
                                <div className="input-group d-flex" style={{width: 'auto', flexWrap: 'nowrap', margin: '10px'}}>
                                    <CustomDatePicker type="text" startDate={endDate} setStartDate={handleEndDateChange}/>
                                </div>
                            </>
                            : 
                            <p style={{color: '#39828f', fontWeight: 'bold', fontSize: '1.3rem', marginLeft: '10px'}}>
                                {`${startDate.getDate()}/${startDate.getMonth() + 1 < 10 ? '0' + (startDate.getMonth() + 1) : (startDate.getMonth() + 1) }/${startDate.getFullYear()}`}    
                            </p>}
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', width: '30%', flexWrap: 'wrap'}}>
                        <p style={{width: '100%', margin: '0px 10px'}}>Филтрирај по системи:</p>
                        <div style={{display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap'}}>
                            <div className="input-group d-flex" style={{width: 'auto', flexWrap: 'nowrap', margin: '10px'}}>
                                <select name="system_filter" onChange={handleInputChange} style={{padding: '3px', width: '290px'}}>
                                    <option  value='1'>Систем 1</option>
                                    <option  value='2'>Систем 2</option>
                                    <option  value='all'>Систем 1 + Систем 2</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div style={{display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap'}}>
                    <div className="card" style={{borderRadius: '10px', margin: "10px 10px", minWidth: '15%'}}>
                        <div className="card-body">
                            <h6 className="card-title" style={{color: '#7c7c7c', fontWeight: 'bold', fontSize: '1.3rem'}}>Вкупен промет</h6>
                            <p style={{color: '#39828f', fontWeight: 'bold', fontSize: '1.3rem'}}>
                            {currencyFormat(paymentsBySystem.reduce((acc, curr) => {
                                return acc + curr.payment_amount;
                            }, 0))}
                            </p>
                        </div>
                    </div>
                    <div className="card" style={{borderRadius: '10px', margin: "10px 10px", minWidth: '15%'}}>
                        <div className="card-body">
                            <h6 className="card-title" style={{color: '#7c7c7c', fontWeight: 'bold', fontSize: '1.3rem'}}>Готовина</h6>
                            <p style={{color: '#39828f', fontWeight: 'bold', fontSize: '1.3rem'}}>
                            {currencyFormat(paymentsBySystem.reduce((acc, curr) => {
                                return acc + curr.payment_cash;
                            }, 0))}
                            </p>
                        </div>
                    </div>
                    <div className="card" style={{borderRadius: '10px', margin: "10px 10px", minWidth: '15%'}}>
                        <div className="card-body">
                            <h6 className="card-title" style={{color: '#7c7c7c', fontWeight: 'bold', fontSize: '1.3rem'}}>Картичка</h6>
                            <p style={{color: '#39828f', fontWeight: 'bold', fontSize: '1.3rem'}}>
                            {currencyFormat(paymentsBySystem.reduce((acc, curr) => {
                                return acc + curr.payment_card;
                            }, 0))}
                            </p>
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row-reverse'}}>
                    <p onClick={() => setActiveTable('bills')} style={activeTable === 'bills' ? {margin: '0px 15px', color: '#39828f', fontWeight: 'bold', cursor: 'pointer'} : {margin: '0px 15px', cursor: 'pointer'}}>По сметки</p>
                    <p onClick={() => setActiveTable('doctors')} style={activeTable === 'doctors' ? {margin: '0px 15px', color: '#39828f', fontWeight: 'bold', cursor: 'pointer'} : {margin: '0px 15px', cursor: 'pointer'}}>По доктори</p>
                    <p onClick={() => setActiveTable('days')} style={activeTable === 'days' ? {margin: '0px 15px', color: '#39828f', fontWeight: 'bold', cursor: 'pointer'} : {margin: '0px 15px', cursor: 'pointer'}}>По денови</p>
                </div>
                <hr/>
                {activeTable ==='bills' && 
                <div>
                    <table className="table" style={{width: '100%'}}>
                        <thead>
                            <tr>
                                <th scope="col">Датум на услуга</th>
                                <th scope="col">Услуга</th>
                                <th scope="col">Доктор</th>
                                <th scope="col">Наплатен износ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentsBySystem.map((payment, index) => {
                                const doctor = doctors.find(d => d.id == payment.doctor_id);
                                const date = new Date(payment.date_of_service);
                                return (
                                    <tr key={payment.id + '_' + payment.doctor_id + "_" + index} onClick={() => onPaymentClick(payment.payment_id, payment.patient_id)} style={{cursor: 'pointer'}}>
                                        <td>{`${date.getDate() < 10 ? '0' + (date.getDate()) : (date.getDate())}/${date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1) }/${date.getFullYear()}`}</td>
                                        <td style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden'}}>{payment.services.map(s => <span>{s.service}<br/></span>) }</td>
                                        <td>{doctor.name_cyrilic}</td>
                                        <td style={{textAlign: 'center'}}><span className={payment.payment_rest < 1 ? '' : 'text-danger'}>{payment.payment_amount }</span></td>
                                        
                                    </tr>
                                )
                            })}
                            
                        </tbody>
                    </table>
                </div>}

                {activeTable === 'doctors' &&
                <div>
                    <table className="table" style={{width: '100%'}}>
                        <thead>
                            <tr>
                                <th scope="col">Доктор</th>
                                <th scope="col">Вкупен промет</th>
                                <th scope="col">Готовина</th>
                                <th scope="col">Картичка</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {Object.entries(groupByKey(paymentsBySystem, 'doctor_id', {omitKey:true})).map((payment, index) => {
                                
                                const doctor = doctors.find(d => d.id == payment[0]);
                               const totalPayment = currencyFormat(payment[1].reduce((acc, curr) => {
                                    return acc + curr.payment_amount;
                                }, 0))
                                const cashPayment = currencyFormat(payment[1].reduce((acc, curr) => {
                                    return acc + curr.payment_cash;
                                }, 0))
                                const cardPayment = currencyFormat(payment[1].reduce((acc, curr) => {
                                    return acc + curr.payment_card;
                                }, 0))
                                return (
                                    <tr key={payment[0] + "_" + index}>
                                        <td>{doctor.name_cyrilic}</td>
                                        <td style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden'}}>{totalPayment}</td>
                                        <td style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden'}}>{cashPayment}</td>
                                        <td style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden'}}>{cardPayment}</td>
                                    </tr>
                                )
                            })}
                            
                        </tbody>
                    </table>
                </div>
                }

                {activeTable === 'days' &&
                    <table className="table" style={{width: '100%'}}>
                        <thead>
                            <tr>
                                <th scope="col">Датум</th>
                                <th scope="col">Вкупен промет</th>
                                <th scope="col">Готовина</th>
                                <th scope="col">Картичка</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {Object.entries(groupByKey(paymentsBySystem, 'date_of_service', {omitKey:true})).map((payment, index) => {
                                
                                const date = new Date(payment[0])
                                const totalPayment = currencyFormat(payment[1].reduce((acc, curr) => {
                                    return acc + curr.payment_amount;
                                }, 0))
                                const cashPayment = currencyFormat(payment[1].reduce((acc, curr) => {
                                    return acc + curr.payment_cash;
                                }, 0))
                                const cardPayment = currencyFormat(payment[1].reduce((acc, curr) => {
                                    return acc + curr.payment_card;
                                }, 0))
                                return (
                                    <tr key={payment[0] + "_" + index}>
                                        <td>{`${date.getDate() < 10 ? '0' + (date.getDate()) : (date.getDate())}/${date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1) }/${date.getFullYear()}, ${days[date.getDay()]} `}</td>
                                        <td style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden'}}>{totalPayment}</td>
                                        <td style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden'}}>{cashPayment}</td>
                                        <td style={{maxWidth: '900px', maxHeight: '47px', overflow: 'hidden'}}>{cardPayment}</td>
                                    </tr>
                                )
                            })}
                            
                        </tbody>
                    </table>
                }
                
            </div>}
                
        </div>
        
        
        </>
    );
}
