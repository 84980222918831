import React, { useState } from 'react'
import { NotificationManager } from 'react-notifications';
import config from '../../config.json'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function EditPrice({setLoading, getPrices, price, handleEditPrice}) {
    const [code, setCode] = useState(price.code);
    const [service, setService] = useState(price.service);
    const [value, setValue] = useState(price.value);
    const navigate = useNavigate;
    
    const editPrice = (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            NotificationManager.error('Невалидни податоци');
            return;
        }
        setLoading(true)
        axios({
            method: 'put',
            url: `${config.SERVER_URL}/prices/${price.id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                ...price,
                code: code,
                service: service,
                value: value
            }
        }).then(res => {
            NotificationManager.success('Успешно променета услуга')
            resetForm();
            getPrices();
            handleEditPrice(0);
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const deletePrice = (e) => {
        e.preventDefault();
        setLoading(true)
        axios({
            method: 'delete',
            url: `${config.SERVER_URL}/prices/${price.id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        }).then(res => {
            NotificationManager.success('Успешно избришана услуга')
            resetForm();
            getPrices();
            handleEditPrice(0);
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const isFormValid = () => {
        return code.toString().length > 0 && service.length > 0 && value.toString().length > 0;
    }

    const handleInputChange = (e) => {
        const { name, value} = e.target;
        switch (name) {
            case 'code':
                setCode(value); 
                break;
            case 'service':
                setService(value);
                break;
            case 'value':
                setValue(value);
                break;
            default:
                break;
        }
    }

    const resetForm = () => {
        setCode('');
        setService('');
        setValue('');
    }

  return (
    <div className='background-form-wrapper p-2' style={{boxShadow: 'none'}}>
        <h1>Промени услуга</h1>
        <form onSubmit={editPrice}>
            <div className='input-wrapper' style={{width: '100%', marginTop: '30px'}}>
                <label htmlFor="code">Шифра</label>
                <input type='text' name='code' value={code} onChange={handleInputChange} style={{width: '100%'}}/>
                {/* <span className='error text-danger'>{cyrNameError}</span> */}
            </div>
            <div className='input-wrapper' style={{width: '100%', marginTop: '30px'}}>
                <label htmlFor="service">Услуга</label>
                <input type='text' name='service' value={service} onChange={handleInputChange} style={{width: '100%'}}/>
                {/* <span className='error text-danger'>{latNameError}</span> */}
            </div>
            <div className='input-wrapper' style={{width: '100%', marginTop: '30px'}}>
                <label htmlFor="value">Цена</label>
                <input type='text' name='value' value={value} onChange={handleInputChange} style={{width: '100%'}}/>
                {/* <span className='error text-danger'>{latNameError}</span> */}
            </div>
            <div className='button-wrapper d-flex justify-content-between'>
                <div>
                    <button className='submit me-2' type='submit' >Зачувај</button>
                    <button className='cancel' type='button' onClick={() => handleEditPrice(0)}>Откажи</button>
                </div>
                <div className='m-0'>
                    <button className='delete p-2' type='button' onClick={deletePrice}>Избриши</button>
                </div>
            </div>
        </form>
    </div>
  )
}
