import React from 'react'
import CardioExamsList from './CardioExams/CardioExamsList'
import ConsultationsList from './Conslultations/ConsultationsList'
import SpecReviewsList from './SpecReviews/SpecReviewsList'
import OtherExamsList from './OtherExams/OtherExamsList'

export default function Exams() {
  return (
    <div>
      <CardioExamsList />
      <OtherExamsList />
      <SpecReviewsList />
      <ConsultationsList />
    </div>
  )
}
