import React, { useEffect, useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import { getTokenPayload } from '../../../helpers/auth'
import logo from '../../../assets/images/cardiovita_logo.jpg'


const TopNavbar = () => {
  const [user, setUser] = useState({})

  useEffect(() => {
    const userData = getTokenPayload();
    setUser(userData);
  }, [])

  return (
    <div>
      <Navbar fixed="top"  className='ps-3 pe-3' style={{height: '70px', backgroundColor: '#fff', boxShadow: '1px 3px 5px 0px rgba(0,0,0,0.49)'}}>
        <Navbar.Brand href="/">
          <img alt='logo' src={logo} style={{height: '70px', marginLeft: '-18px'}} />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
            <p style={{margin: '0'}} href="#login">{user.name}</p>
            <p style={{margin: '0', fontSize: '12px'}}>{user.role}</p>
        </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default TopNavbar
