import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager} from 'react-notifications';
import config from '../../../config.json';
import { getUsernameError, getNameError, getPasswordError } from '../../../helpers/validators';
import Spinner from '../../SharedComponents/Spinner';
import ExportPatientPdf from '../ExportPatientPdf';
import CustomDatePicker from '../../SharedComponents/CustomDatePicker';

export default function EditPatient() {
  const navigate = useNavigate();
  const location = useLocation();
    const [name_cyrilic, setName_cyrilic] = useState('');
    const [name_latin, setName_latin] = useState('');
    const [address_cyrilic, setAddress_cyrilic] = useState('');
    const [address_latin, setAddress_latin] = useState('');
    const [city_cyrilic, setCity_cyrilic] = useState('');
    const [city_latin, setCity_latin] = useState('');
    const [country_cyrilic, setCountry_cyrilic] = useState('');
    const [country_latin, setCountry_latin] = useState('');
    const [gender, setGender] = useState(""); 
    const [date_of_birth, setDate_of_birth] = useState(""); 
    const [embg, setEmbg] = useState(""); 
    const [personal_doc_num, setPersonal_doc_num] = useState('');
    const [phone, setPhone] = useState(""); 
    const [email, setEmail] = useState(""); 
    const [loading, setLoading] = useState(false);
    const {id} = useParams();

    useEffect(() => {
        if (location.state && location.state.patient) {
            setPatientData(location.state.patient)
        } else {
            getPatient(id);
        }
  },[id])

  const getPatient = (id) => {
    setLoading(true);
    axios({
        method: 'get',
        url: `${config.SERVER_URL}/patients/${id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
        }
    })
    .then(res => {   
        const patient = res.data;
        setPatientData(patient)
    })
    .catch(err => {
        if (err.response.status === 401) {
            navigate('/signin')
        }
        NotificationManager.error(err.response.data)
        navigate('..')
    })
    .finally(() => setLoading(false));
    }

    const setPatientData = (patient) => {
        setName_cyrilic(patient.name_cyrilic); 
        setName_latin(patient.name_latin); 
        setAddress_cyrilic(patient.address_cyrilic);
        setAddress_latin(patient.address_latin);
        setCity_cyrilic(patient.city_cyrilic);
        setCity_latin(patient.city_latin);
        setCountry_cyrilic(patient.country_cyrilic);
        setCountry_latin(patient.country_latin);
        setGender(patient.gender);
        setDate_of_birth(patient.date_of_birth ? new Date(patient.date_of_birth) : null);
        setEmbg(patient.embg);
        setPersonal_doc_num(patient.personal_doc_num);
        setPhone(patient.phone);
        setEmail(patient.email);
    }

    const editPatient = (e) => {
        e.preventDefault();
        // if (!isFormValid()) {
        //     return;
        // }
        setLoading(true)
        axios({
            method: 'put',
            url: `${config.SERVER_URL}/patients/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                name_cyrilic,
                name_latin,
                address_cyrilic,
                address_latin,
                city_cyrilic,
                city_latin,
                country_cyrilic,
                country_latin,
                gender,
                date_of_birth,
                embg,
                personal_doc_num,
                phone,
                email
            }
        }).then(res => {
            NotificationManager.success('Успешна промена')
            navigate('..');
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));
    }

    const handleInputChange = (e) => {
        resetErrors();
        const { name, value} = e.target;
        switch (name) {
            case 'name_cyrilic':
                setName_cyrilic(value); 
                break;
            case 'name_latin':
                setName_latin(value);
                break;
            case 'address_cyrilic':
                setAddress_cyrilic(value);
                break;
            case 'address_latin':
                setAddress_latin(value);
                break;
            case 'city_cyrilic':
                setCity_cyrilic(value); 
                break;
            case 'city_latin':
                setCity_latin(value);
                break;
            case 'country_cyrilic':
                setCountry_cyrilic(value);
                break;
            case 'country_latin':
                setCountry_latin(value);
                break;
            case 'gender':
                setGender(value); 
                break;
            case 'embg':
                setEmbg(value);
                break;
            case 'personal_doc_num':
                setPersonal_doc_num(value);
                break;
            case 'phone':
                setPhone(value); 
                break;
            case 'email':
                setEmail(value);
                break;
            default:
                break;
        }
    }

    // const handleSelectChange = (e) => {
    //     setRole(e.target.value);
    // }

    const cancelRegister = () => {
        resetErrors();
        navigate('/patients');
    }

    // const isFormValid = () => {
    //     const nameErrorMessage = getNameError(name);
    //     const usernameErrorMessage = getUsernameError(username);
    //     const passwordErrorMessage = getPasswordError(password);

    //     if(nameErrorMessage) {
    //         setNameError(nameErrorMessage);
    //     } 

    //     if(usernameErrorMessage) {
    //         setUsernameError(usernameErrorMessage);
    //     } 

    //     if(passwordErrorMessage) {
    //         setPasswordError(passwordErrorMessage);
    //     } 

    //     return (nameErrorMessage || usernameErrorMessage || passwordErrorMessage) ? false : true;
    // }

    const resetErrors = () => {
        
        // setNameError('');
        // setUsernameError('');
        // setPasswordError('');
    }

    return (
        <>
        {/* <Link to='/patients' type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}}>Назад</Link> */}
        <div className='background-wrapper_patient' style={{width: 'auto',  backgroundColor: 'inherit'}}>
            {loading ? <Spinner /> : null}
            
                <form style={{width: '100%'}} onSubmit={editPatient}>
                    <div className='background-form-wrapper_patient'>
                        {/* <h1>Креирај нов пациент</h1> */}
                        <div className='input-wrapper'>
                            <label htmlFor="name_cyrilic">Име и презиме ( кирилица )</label>
                            <input type='text' name='name_cyrilic' value={name_cyrilic}  onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="name_latin">Име и презиме ( латиница )</label>
                            <input type='text' name='name_latin' value={name_latin} onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="address_cyrilic">Адреса ( кирилица )</label>
                            <input type='text' name='address_cyrilic' value={address_cyrilic} onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="address_latin">Адреса ( латиница )</label>
                            <input type='text' name='address_latin' value={address_latin} onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="city_cyrilic">Град ( кирилица )</label>
                            <input type='text' name='city_cyrilic' value={city_cyrilic} onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="city_latin">Град ( латиница )</label>
                            <input type='text' name='city_latin' value={city_latin} onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="country_cyrilic">Држава ( кирилица )</label>
                            <input type='text' name='country_cyrilic' value={country_cyrilic} onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="country_latin">Држава ( латиница )</label>
                            <input type='text' name='country_latin' value={country_latin} onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                          <label htmlFor="gender">Пол</label>
                          <select className='select_dropdown' name="gender" value={gender} onChange={handleInputChange}>
                              <option value=''></option>
                              <option value='маж'>Маж</option>
                              <option value='жена'>Жена</option>
                          </select>
                      </div>
                        <div className='input-wrapper'>
                            <label htmlFor="date_of_birth">Датум на раѓање</label>
                            <CustomDatePicker name='date_of_birth' startDate={date_of_birth} setStartDate={setDate_of_birth}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="embg">Матичен број</label>
                            <input type='text' name='embg' value={embg} onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="personal_doc_num">Број на документ за идентификација</label>
                            <input type='text' name='personal_doc_num' value={personal_doc_num} onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="phone">Телефон</label>
                            <input type='text' name='phone' value={phone} onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="email">Е-пошта</label>
                            <input type='text' name='email' value={email} onChange={handleInputChange}/>
                            {/* <span className='error text-danger'>{nameError}</span> */}
                        </div>
                        <div className='button-wrapper'>
                        <button className='submit' type='submit'>Промени</button>
                    </div>
                    </div>
                </form>
            {/* <div className='background-form-wrapper'>
                <h1>Додади нов корисник</h1>
                <form onSubmit={handleRegister}>
                    <div className='input-wrapper'>
                        <label htmlFor="name">Додади пациент</label>
                        <input type='text' name='name' onChange={handleInputChange}/>
                        <span className='error text-danger'>{nameError}</span>
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="username">Корисничко име</label>
                        <input type='text' name='username' onChange={handleInputChange}/>
                        <span className='error text-danger'>{usernameError}</span>
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="password">Лозинка</label>
                        <input type='password' name='password' onChange={handleInputChange}/>
                        <span className='error text-danger'>{passwordError}</span>
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="password">Улога</label>
                        <select name="role" onChange={handleSelectChange}>
                            <option value='Администратор'>Администратор</option>
                            <option value='Сопственик'>Сопственик</option>
                            <option value='Доктор'>Доктор</option>
                            <option value='Вработен'>Вработен</option>
                        </select>
                    </div>
                    <div className='button-wrapper'>
                        <button className='submit' type='submit'>Зачувај</button>
                        <button className='cancel' type='button' onClick={cancelRegister}>Откажи</button>
                    </div>
                </form>
            </div> */}
        </div>
        </>
    );
}
