export const template1 = {
    anamnestic_data: "Прв кардиолошки преглед. Се жали на неспецифична болка на левата страна на граден кош, замор, гушење, срцебиење, неправилна срцева работа.",
    objective_find: `Кардијално компензиран, ритмички стабилен. Аускултаторен наод на бели дробови: везикуларно дишење. Наод на срце: акција ритмична, тонови добро чујни, шумови нема.
ЕКГ: синус ритам, сф 75/мин, уреден ст сегмент.`,
    conclusion: "Уредни димензии на лева комора со глобална сочувана контрактилност. Нема испади во кинетиката. Уредни димензии на двете преткомори. Лесна митрална инсуфициенција. Останатиот валвуларен апарат уреден.",
    further_investigation: "Каротидни артерии уредни без плаки и стенози. Тироидна жлезда со нормална морфологија без особености. Доплер на периферните артерии на долните екстремитети уредни.",
    diagnose: "Уреден ехокардиографски наод. Артериска хипертензија. Хиперлипидемија. Дилатативна кардиомиопатија.",
    recommendation: "Индициран е коронарен стрес тест. Редовна кардиолошка контрола по 6 месеци со претходно закажување на телефон 070/370900 и 02/61 44 500.",
    anamnestic_data_al:
        "Vjen në vizitë kardiologjike. Me  04.2019 është kryer MSKT koronarografija pa stenoza signifikante na arteriet koronare por me prezenc te plakave aterosklerotike .  Ankohet në dhimbje jospecifike te gjoksit.Variacion i tensionit arterial.",
    objective_find_al: "Në auskultim frymarje vezikulare, aksioni ritmik i zemrës, nuk ndegjohet zhurmë patologjike",
    conclusion_al: "Ventrikuli i majtë me dimenzione normale, kontraktilitet të ruajtur Valvula mitrale, aortale, trikuspidale dhe pulmonale në normë. Perikardi pa vecori",
    further_investigation_al: "Arteriet karotide pa stenoza signifikante. Gjëndra tiroide pa vecori, dopler i arterieve periferike në normë",
    diagnose_al: "Hipertnsion arterial. Hiperlipidemi.",
    recommendation_al:
        "Realizohet Stres testi koronar. I njejti vleresohet negativ(NORMAL).  Vizitë e regullt kardiologjike mbas 6 muajve duke caktuar paraprakishtë  terminin në telefonin  070/370 900 ose 02/61 44 500. Kontrol te regult te gastroenterologu",
    valvula_mitrale: "Уредна функција",
    valvula_mitrale_al: "Pa veçori.",
    valvula_aortale: "Уредна функција",
    valvula_aortale_al: "Pa veçori.",
    valvula_trikuspidale: "Уредна функција",
    valvula_trikuspidale_al: "Pa veçori.",
    valvula_pulmonale: "Уредна функција",
    valvula_pulmonale_al: "Pa veçori.",
};

export const templateDermatology = {
    anamnestic_data: "Пациентката  е на 75 годишна возраст. Се жали на отоци на нозете.",
    objective_find: "Оток на потколениците од умерен степен.",
    investigation: "1. Артериски проток : \n\n 2. Венски проток : \n -длабоки вени: \n -устија: \n -површни вени:",
    diagnose: "Хронична венска инсуфициенција.",
    recommendation: "Контрола за 6 месеци со претходно закажување на телефон 070 370 900.",
};

export const templateEndocrinology = {
    anamnestic_data: "Упатена за тироидно иследување. Пациентка со хипотиреоза на терапија со еутирокс.",
    objective_find: "TSH 14.5 \n FT3 2,30 пмол/Л  \n FT4 8.10 пмол \n Глукоза 5.10 ммол/л",
    investigation: "Васкуларен доплер на вратни артерии и органи : \n\n Каротидни артерии: Уреден проток на двете каротиди без плаки  и стенози. \n\n Тироидна жлезда :  Хипоехогена хетерогена структура со нормални димензии.",
    diagnose: "Hypothyrеosis primaria",
    recommendation: "Контрола по 4-6 месеци со новa лабораторија (ТСХ, ФТ4, Анти-тпо)",
};

export const templateNeurology = {
    anamnestic_data: "Пациент на 70 годишна возраст. Се жали на главоболка и  зуење во ушите, состојба која ја има подолго време. ",
    objective_find: "Невролошки статус: без латерализација. ",
    investigation: "Доплер на каротидни и церебрални артерии:  \n\n Уреден проток на двете каротидни артерии ; дифузно задебелена интима билатерално, без плаки и стенози. \n Во прилог МР на мозок: без свежи промени.",
    diagnose: "Cephalea prolongata obs.",
    recommendation: "Контролен преглед по 2 месеци со претходно закажување на телефон 070/370900 и 02/61 44 500.",
};
