import React, { useEffect, useState } from 'react'
import Card from '../SharedComponents/Card';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../config.json';
import {NotificationManager} from 'react-notifications';
import Spinner from "../../components/SharedComponents/Spinner";
import CustomMonthPicker from '../SharedComponents/CustomMonthPicker';

export default function ScheduleTable() {
    const navigate = useNavigate();
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false)
    const {date} = useParams();
    const [selectedDate, setSelectedDate] = useState(date ? new Date(date) : new Date());
    const [datesToRender, setDatesToRender] = useState([]);
    const [allDates, setAllDates] = useState([]);
    const [values, setValues] = useState({});
    

    useEffect(() => {
        getEmployees();
        setDatesToRender(getAllDaysInMonth(selectedDate.getMonth() + 1, selectedDate.getFullYear()).map(x => x.toLocaleDateString([], { day: "numeric" })))
    }, [])

    const getEmployees = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/users`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {   
            const usersByRole = res.data.map(u => {
                const userWithRoleIndex = u.roles.split(',').findIndex(r => r === "Доктор" || r === "Вработен");
                if (userWithRoleIndex !== -1 || u.name.includes('Шпенд Идризи') ) {
                    return u;
                }
            })
            setEmployees(usersByRole.filter(u => !!u).sort(compare)); 
            
            getSchedule(selectedDate);
        })
        .catch(err => {
            if (err.response.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response.data)
        })
        .finally(() => setLoading(false));

    }

    const compare = ( a, b ) => {
        if ( a.roles < b.roles ){
          return -1;
        }
        if ( a.roles > b.roles ){
          return 1;
        }
        return 0;
      }

    const getSchedule = (date) => {
        setLoading(true);
       
        axios({
            method: 'get',
            url: `${config.SERVER_URL}/montly-schedule/${date.getFullYear()}-${date.getMonth() + 1}-${1}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        })
        .then(res => {  
            let scheduleValues = {}; 
            
            if (typeof res.data == 'object') {
                res.data.map(item => {
                    scheduleValues = JSON.parse(item.value);
                    return item;
                })
            } 
            setValues(scheduleValues);
            
        })
        .catch(err => {
            if (err.response?.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response?.data)
        })
        .finally(() => setLoading(false));

    }

    const addSchedule = () => {
        setLoading(true);
       const date = new Date(selectedDate);
        axios({
            method: 'post',
            url: `${config.SERVER_URL}/montly-schedule/${date.getFullYear()}-${date.getMonth() + 1}-${1}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: {
                value: JSON.stringify(values)
            }
        })
        .then(res => {  

            console.log(res)
            
        })
        .catch(err => {
            if (err.response?.status === 401) {
                navigate('/signin')
            }
            NotificationManager.error(err.response?.data)
        })
        .finally(() => setLoading(false));

    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
        navigateToDate(date);
        getSchedule(date);
        // getWorkingHours(date);
    }

    const navigateToDate = (date) => {
        setDatesToRender(getAllDaysInMonth(date.getMonth() + 1, date.getFullYear()).map(x => x.toLocaleDateString([], { day: "numeric" })))
        navigate(`../${date.getFullYear()}-${date.getMonth() + 1}-${1}`)
    }

    const goToNextDate = (e) => {
        e.preventDefault();
        const date = new Date(selectedDate);
        date.setMonth(date.getMonth() + 1);
        setSelectedDate(date);
        getSchedule(date);
        setDatesToRender(getAllDaysInMonth(date.getMonth() + 1, date.getFullYear()).map(x => x.toLocaleDateString([], { day: "numeric" })))
        // getWorkingHours(date);
        navigate(`../${date.getFullYear()}-${date.getMonth() + 1}-${1}`)
    }

    const goToPrevDate = (e) => {
        e.preventDefault();
        const date = new Date(selectedDate);
        date.setMonth(date.getMonth() - 1);
        setSelectedDate(date);
        getSchedule(date);
        setDatesToRender(getAllDaysInMonth(date.getMonth() + 1, date.getFullYear()).map(x => x.toLocaleDateString([], { day: "numeric" })))
        // getWorkingHours(date);
        navigate(`../${date.getFullYear()}-${date.getMonth() + 1}-${1}`)
    }

    const getAllDaysInMonth = (month, year) => {
        const allDates = Array.from(
            {length: new Date(year, month, 0).getDate()}, // get next month, zeroth's (previous) day
            (_, i) => new Date(year, month - 1, i + 1)    // get current month (0 based index)
        );
        setAllDates(allDates);
        return allDates;
    }

    const handleValueChange = (e) => {
        const { name, value} = e.target;
        setValues({
            ...values,
            [name]: value,
          });
    }
        


    return (
        <>
        {loading ? <Spinner /> : null}
        <div className='d-flex justify-content-between'>
            <button type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}} onClick={goToPrevDate}>{'<<< Претходен ден'}</button>
            <div className="input-group d-flex" style={{width: 'auto', minWidth: '250px' }}>
                <CustomMonthPicker type="text" startDate={selectedDate} setStartDate={handleDateChange}/>
            </div>
            
            <button type="button" className="btn align-self-end" style={{backgroundColor: "#39828f", color: "#fff"}} onClick={goToNextDate}>{'Нареден ден >>>'}</button>
        </div>
        
        <Card>
            <div className='text-center mb-3 fs-5'>
                <b>Месечен распоред</b>
            </div>
            <table className="table table-bordered" style={{tableLayout: 'fixed'}}>
                <thead>
                    
                    <tr>
                    
                    <th scope="col" className='col-4'>Доктор</th>
                    { datesToRender.map((date, index) => {
                        return (
                            <th key={index} scope='col' className='col-1' style={{backgroundColor: allDates[date-1].getDay() == 0 ?  'gray' : allDates[date-1].getDay() == 6 ?  '#b5b5b5' : 'inherit'}}>{date}</th>
                        )
                    })}
                    
                    {/* <th scope="col">Специјалност</th>
                    <th scope="col"></th> */}
                    </tr>
                </thead>
                <tbody>
                    {employees.map((doctor, index) => {
                        return (
                            <tr key={doctor.id}>
                                
                                <td className='col-4'>{doctor.name}</td>

                                { datesToRender.map((date, index) => {
                                return (
                                    <td key={doctor.id + '_' + date} className='col-1 p-1' style={{backgroundColor: allDates[date-1].getDay() == 0 ?  'gray' : allDates[date-1].getDay() == 6 ?  '#b5b5b5' : 'inherit'}}>
                                        { allDates[date-1].getDay() != 0 ?  <input style={{width: '100%', color: '#39828f', fontWeight: 700}} name={doctor.id + '_' + date} value={values[doctor.id + '_' + date] ? values[doctor.id + '_' + date] : ''} onChange={handleValueChange} /> : ''}
                                       
                                    </td>
                                )
                            })}
                                
                                {/* <td>{doctor.speciality_cyrilic}</td>
                                <td style={{width: '50px', paddingTop: '0'}}>
                                    <Link to={`${doctor.id}`} type="button" className="btn">
                                        <FaUserEdit style={{color: '#39828f'}} />
                                    </Link>
                                </td> */}
                            </tr>
                        )
                    })}
                    
                </tbody>
            </table>
            <div style={{fontWeight: '700'}}>
                <p style={{textDecoration: 'underline', color: 'rgb(57, 130, 143)'}}>1A = 08h - 15h</p>
                <p style={{textDecoration: 'underline', color: 'rgb(57, 130, 143)'}}>1B = 10h - 17h</p>
                <p style={{textDecoration: 'underline', color: 'rgb(57, 130, 143)'}}>2A = 13h - 20h</p>
                <p style={{textDecoration: 'underline', color: 'rgb(57, 130, 143)'}}>2B = 16h - 20h</p>
            </div>
            <div className='button-wrapper'>
                <button className='submit' type='button' onClick={addSchedule}>Зачувај</button>
            </div>
        </Card>
        </>
    )
}
