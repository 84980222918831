import React from "react";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

export default function AppointmentsTable({ appointments, handleShow, workTime }) {
    return (
        <table className="table table-hover table-borderless align-middle mb-0 table-sm">
            <thead>
                <tr>
                    <th scope="col" style={{ textAlign: "left" }}>
                        Време
                    </th>
                    <th scope="col">Име и презиме</th>
                    <th scope="col">Услуга</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {appointments.map((item, index) => {
                    const startDateTime = new Date(item.start_date_time);

                    const endDate = new Date(startDateTime);
                    const endDateTime = new Date(endDate.setMinutes(endDate.getMinutes() + item.duration));
                    return (
                        <tr
                            key={item.id + "-" + index}
                            style={{
                                cursor: "pointer",
                                fontSize: "14px",
                                boxSizing: "border-box",
                                height: item.duration * 2 + "px",
                                lineHeight: "20px",
                                backgroundColor: item.patient_name ? item.status : "#fff",
                                overflow: "hidden",
                                borderBottom: "1px solid #fff",
                            }}
                            onClick={item.patient_name ? null : () => handleShow(item.id, item, appointments, workTime)}
                        >
                            {item.duration >= 30 ? (
                                <td className="py-0" style={{ width: "15%" }}>
                                    <div className="d-flex flex-column justify-content-between py-1" style={{ height: item.duration * 2 + "px" }}>
                                        <span style={{ lineHeight: "14px", textAlign: "left" }}>{`${startDateTime.getHours()}:${startDateTime.getMinutes() < 10 ? "0" + startDateTime.getMinutes() : startDateTime.getMinutes()}`}</span>
                                        <span style={{ height: item.duration * 2 - 40 + "px", borderLeft: "1px dashed #000", marginLeft: "15px" }}></span>
                                        <span style={{ lineHeight: "14px", textAlign: "left" }}>{`${endDateTime.getHours()}:${endDateTime.getMinutes() < 10 ? "0" + endDateTime.getMinutes() : endDateTime.getMinutes()}`}</span>
                                    </div>
                                </td>
                            ) : (
                                <td className="py-0" style={{ width: "15%", textAlign: "left" }}>
                                    {`${startDateTime.getHours()}:${startDateTime.getMinutes() < 10 ? "0" + startDateTime.getMinutes() : startDateTime.getMinutes()}`} -{" "}
                                    {`${endDateTime.getHours()}:${endDateTime.getMinutes() < 10 ? "0" + endDateTime.getMinutes() : endDateTime.getMinutes()}`}
                                </td>
                            )}
                            <td className="py-0" style={{ width: "40%" }}>
                                <div style={{ maxHeight: item.duration * 2 + "px", overflow: "hidden" }}>
                                    {item.patient_id > 0 ? (
                                        <Link to={`/patients/${item.patient_id}`} style={{ color: "#39828f" }}>
                                            <b>{item.patient_name}</b>
                                        </Link>
                                    ) : (
                                        <b style={{ color: "#39828f" }}>{item.patient_name}</b>
                                    )}
                                </div>
                            </td>
                            <td className="py-0" style={{ width: "40%" }}>
                                <div style={{ maxHeight: item.duration * 2 + "px", overflow: "hidden" }}>{item.service}</div>
                            </td>
                            <td className="py-0" style={{ width: "5%" }}>
                                {item.patient_name ? (
                                    <div style={{ maxHeight: item.duration * 2 + "px", overflow: "hidden" }}>
                                        <FaEdit style={{ color: "#39828f", fontSize: "18px" }} onClick={() => handleShow(item.id, item, appointments, workTime)} />
                                    </div>
                                ) : null}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
